import { Component, Input, Output, EventEmitter, inject, PLATFORM_ID, afterNextRender, ViewChild, ElementRef, ViewEncapsulation, SimpleChanges, Renderer2 } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialog } from '@angular/material/dialog';
import { AssistantForm, ContentOutline } from '../chat-bot/models/brief-assistant-model';
import { EditorModule, TINYMCE_SCRIPT_SRC  } from '@tinymce/tinymce-angular'
import {TooltipPosition, MatTooltipModule} from '@angular/material/tooltip';
import { Document, Packer, Paragraph, TextRun, HeadingLevel, UnderlineType, ThematicBreak, IParagraphPropertiesOptions, IRunPropertiesOptions } from 'docx';
import { saveAs } from 'file-saver';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { MatMenuModule } from '@angular/material/menu';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


import { SharedDataChatService } from '../chat-bot/services/shared-data-chat.service';
import { AssistantManagerService } from '../chat-bot/services/assistant-manager-service.service';
import { environment } from 'src/environments/environment';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { DataObject, VersionControlData } from '../chat-bot/models/version-control-model';
import { SaveVersionDialogComponent } from '../save-version-dialog/save-version-dialog.component';
import Swal from 'sweetalert2';
//import pdfMake from 'pdfmake/build/pdfmake';
//import pdfFonts from 'pdfmake/build/vfs_fonts';
//import htmlToPdfmake from 'html-to-pdfmake';

//pdfMake.vfs = pdfFonts.pdfMake.vfs;
declare var tinymce: any;



//test mat-chip
import {MatChipEditedEvent, MatChipInputEvent, MatChipsModule} from '@angular/material/chips';
import { AssistantPreSelection } from '../chat-bot/models/assistant-pre-selection-model';
import { Suggestion } from '../chat-bot/models/scoring';


let env = environment


@Component({
  selector: 'app-brief-creator',
  standalone: true,
  providers: [SharedDataChatService, AssistantManagerService, { provide: TINYMCE_SCRIPT_SRC, useValue: env.frontendURL+'/tinymce/tinymce.min.js' } ],
  imports: [
    CommonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    FormsModule,
    EditorModule,
    MatChipsModule,
    MatExpansionModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatMenuModule,
  ],
  templateUrl: './brief-creator.component.html',
  styleUrl: './brief-creator.component.sass',
  encapsulation: ViewEncapsulation.None,
})
export class BriefCreatorComponent {
  @Input() proposedTitles: string[] = [];
  @Input() isPanelOpen: boolean = false;
  @Input() selectedOutlineId: string | null = null;
  @Input() suggestions: Suggestion[] = [];
  @Input() versionData: VersionControlData[];
  @Input() sessionId: string;
  @Output() titleUpdated: EventEmitter<string> = new EventEmitter();
  @Output() primaryKeywordUpdated: EventEmitter<string> = new EventEmitter();
  @Output() metaDescriptionUpdated: EventEmitter<string> = new EventEmitter();
  @Output() titleSelected: EventEmitter<string> = new EventEmitter();
  @Output() generateOutlineRequested = new EventEmitter<void>();
  @Output() showTitleSuggestions: EventEmitter<boolean> = new EventEmitter();
  @Output() showMainKeywordSuggestions: EventEmitter<boolean> = new EventEmitter();
  @Output() showMetaDescriptionSuggestions: EventEmitter<boolean> = new EventEmitter();
  @Output() showSecondaryKeywordSuggestions: EventEmitter<boolean> = new EventEmitter();
  @Output() toggleSuggestionsPanel = new EventEmitter<void>();
  @Output() sectionSelected = new EventEmitter<string>();
  @Output() focusElementRequested: EventEmitter<string> = new EventEmitter<string>();
  @Output() updateCompleted = new EventEmitter<string>();
  @ViewChild('editableContainer') editableContainer: ElementRef;
  @ViewChild('scrollTarget') private scrollTarget: ElementRef;
  sharedDataService = inject(SharedDataChatService);
  assistantManagerService = inject(AssistantManagerService);
  platformId = inject(PLATFORM_ID);

  newTitle: string = '';
  selectedTitle: string = '';
  selectedPrimaryKeywords: string = '';
  selectedSecondaryKeyword:  string[] = [];
  selectedMetaDescription: string = '';
  selectedWordCount: string = '';
  selectedToneOfVoice: string = '';
  selectedContentGoal: string = '';
  selectedPointOfView: string = '';
  selectedBusinessObjective: string = '';
  selectedCTA: string = '';
  selectedInternalLinks:  string[] = [];
  selectedKeyPoints:  string[] = [];
  selectedThingsToAvoid:  string[] = [];
  selectedAudienceInsights: string = '';
  selectedSpecialInstructions: string[] = [];
  selectedSerpAnalysis: string = '';
  selectedDifOpportunities: string[] = [];
  selectedOutlineContent: string = '';
  public saveIndicator = {};
  outlineContent: string = '';
  env = environment
  suggestionTitlesOpen = false;
  primarySuggestionsOpen = false;
  metaDesciptionOpen = false;
  secondarySuggestionsOpen = false;
  showRewriteButton = false; 
  currentHoverElement: HTMLElement | null = null;
  selectedSectionId: string | null = null;
  selectedSectionElement: HTMLElement | null = null;
  private subscriptions = new Subscription();
  public safeOutlineContent: SafeHtml = '';
  sectionsWithSuggestions: Set<string> = new Set();
  public maintainFocusStyle: boolean = false;
  dynamicFields: Array<{id: string, title: string, content: string}> = [];
  newVersionName: string;
  sessionHistory: any[] = [];
  currentSessionIndex: number = 0;
  
  
  // pre selection variables
  field: string | null = null;
  brand: string | null = null;
  publishUrl: string | null = null;
  contentType: string | null = null;
  vertical: string | null = null;
  

  // tinymce
  // editorConfig = {
  //   base_url: this.env.frontendURL+'/tinymce',
  //   suffix: '.min',
  //   plugins: 'lists link image table wordcount',
  //   menubar: false,
  //   height: '200px',
  //   branding: false,
  //   placeholder: "Outline content",
  //   formats: {
  //     preformatted: { block: 'pre', styles: { 'white-space': 'pre-wrap' } }
  //   },
  //   toolbar: 'undo redo | bold italic underline | bullist numlist | link image giphy emojis | code | formatselect',
  // };

  editorConfig = {
    base_url: this.env.frontendURL+'/tinymce',
    selector: '.inline-editor',
    inline: true,
    suffix: '.min',
    plugins: 'lists link image table wordcount',
    menubar: false,
    branding: false,
    placeholder: "Form details",
    setup: (editor) => {
      editor.on('blur', () => {
       // console.log("Editor Config Change");
        this.handleEditorBlur(editor);
      });
    }
  };

  constructor(private cdr: ChangeDetectorRef, private snackBar: MatSnackBar,  private renderer: Renderer2, private sanitizer: DomSanitizer, public dialog: MatDialog) {
    //mock data for test/dev - Julia - for testing
     /* this.selectedTitle = "Where fish in Vancouver Islands";
      this.selectedPrimaryKeywords = "Main keywords";
      this.selectedSecondaryKeyword = ['Keyword 1', 'Keyword 2', 'Keyword 3'];
      this.selectedMetaDescription = "Meta descriptions is a big text Meta descriptions is a big textMeta descriptions is a big text";
      this.selectedWordCount = "word count";
      this.selectedToneOfVoice = "Tone of Voice";
      this.selectedContentGoal = "Content Goal";
      this.selectedPointOfView = "Point of view";
      this.selectedBusinessObjective = "selectedBusinessObjective";
      this.selectedCTA = "CTA";
      this.selectedInternalLinks =  ['InternalLink 1', 'InternalLink 2', 'InternalLink 3'];
      this.selectedAudienceInsights = ['Audience 1', 'Audience 2', 'Audience 3'];
      this.selectedSpecialInstructions = ['Keyword 1', 'Keyword 2', 'Keyword 3'];
      this.selectedSerpAnalysis = "selectedSerpAnalysis";
      this.selectedDifOpportunities = ['Keyword 1', 'Keyword 2', 'Keyword 3'];
      this.selectedOutlineContent = `
      <h4>Title:</h4> The Angler's Guide to Vancouver Island: Where to Find the Best Fish\n\n<h4>Introduction:</h4> Engage the reader with an overview of fishing on Vancouver Island, highlighting salmon and trout fishing opportunities.\n\n<h4>The Best Spots for Salmon Fishing:</h4> Detail the top locations for salmon fishing, incorporating personal anecdotes from local fishermen.\n\n<h4>Trout Fishing Havens:</h4> Explore the key spots for trout fishing, enriched with in-depth analysis and personal stories.\n\n<h4>Insider Tips and Techniques:</h4> Offer valuable advice from locals for successful fishing on Vancouver Island.\n\n<h4>Interactive Map of Fishing Locations:</h4> Include an interactive map pinpointing the best fishing spots discussed in the article.\n\n<h4>Call to Action (CTA):</h4> Encourage readers to book their next fishing adventure with a link to relevant page.\n\n<h4>Conclusion:</h4> Summarize the joys and opportunities of fishing on Vancouver Island, beckoning readers to explore these waters.\n\n<h4>Special Instructions:</h4> Ensure the inclusion of unique data or research to enrich the content's value to the reader.`;
      this.safeOutlineContent = this.sanitizer.bypassSecurityTrustHtml(this.selectedOutlineContent);*/
    afterNextRender(() => {
      console.log(
        'constructor',
        this.platformId
      );
    });
  }

  ngOnInit() {
    document.addEventListener('click', this.handleDocumentClick.bind(this));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedOutlineId'] && changes['selectedOutlineId'].currentValue) {
      this.focusOnSection(changes['selectedOutlineId'].currentValue);
    }
    if (changes['suggestions'] && changes['suggestions'].currentValue) {
      this.updateSectionsWithSuggestions();
    }
  }


  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.initializeTinyMCE();
    }
  }
  
  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId) && tinymce) {
      tinymce.remove('.inline-editor');
    }
    this.subscriptions.unsubscribe();
  }

  handleEditorBlur(editor: any): void {
    const content = editor.getContent();
    this.updateModel(editor.id, content);
  }

  handleDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (!this.editableContainer.nativeElement.contains(target)) {
      this.removeFocusFromAllEditableElements();
    }
  }

  removeFocusFromAllEditableElements(): void {
    const editables = this.editableContainer.nativeElement.querySelectorAll('[contenteditable=true]');
    editables.forEach((element: HTMLElement) => {
      element.classList.remove('focused');
      element.style.boxShadow = '';
      element.style.outline = '';
      element.style.border = '';
      element.style.borderRadius = '';
    });
  }
  

  private loadInitialContent(editor) {
    switch (editor.id) {
      case 'title':
        editor.setContent(`<h1>${this.selectedTitle}</h1>`);
        break;
      case 'brand':
        editor.setContent(`<span>${this.brand}</span>`);
        break;
      case 'publish-url':
        editor.setContent(`<span>${this.publishUrl}</span>`);
        break;
      case 'content-type':
        editor.setContent(`<span>${this.contentType}</span>`);
        break;
      case 'vertical':
        editor.setContent(`<span>${this.vertical}</span>`);
        break;
      case 'primary-keyword':
        editor.setContent(`<span>${this.selectedPrimaryKeywords}</span>`);
        break;
      case 'secondary-keywords':
        editor.setContent(this.selectedSecondaryKeyword.map(kw => `<li>${kw}</li>`).join(''));
        break;
      case 'word-count':
        editor.setContent(`<span>${this.selectedWordCount}</span>`);
        break;
      case 'audience-insights':
        editor.setContent(`<span>${this.selectedAudienceInsights}</span>`);
        break;
      case 'tone-of-voice':
        editor.setContent(`<span>${this.selectedToneOfVoice}</span>`);
        break;
      case 'content-goal':
        editor.setContent(`<span>${this.selectedContentGoal}</span>`);
        break;
      case 'business-objective':
        editor.setContent(`<span>${this.selectedBusinessObjective}</span>`);
        break;
      case 'cta':
        editor.setContent(`<span>${this.selectedCTA}</span>`);
        break;
      case 'internal-links':
        editor.setContent(this.selectedInternalLinks.map(link => `<li>${link}</li>`).join(''));
        break;
      case 'key-points':
        editor.setContent(this.selectedKeyPoints.map(kp => `<li>${kp}</li>`).join(''));
        break;
      case 'things-to-avoid':
        editor.setContent(this.selectedThingsToAvoid.map(ta => `<li>${ta}</li>`).join(''));
        break;
      case 'point-of-view':
        editor.setContent(`<span>${this.selectedPointOfView}</span>`);
        break;
      case 'special-instructions':
        editor.setContent(this.selectedSpecialInstructions.map(si => `<li>${si}</li>`).join(''));
        break;
      case 'serp-analysis':
        editor.setContent(`<span>${this.selectedSerpAnalysis}</span>`);
        break;
        case 'differentiation-opportunities':
          editor.setContent(this.selectedDifOpportunities.map(di => `<li>${di}</li>`).join(''));
          break;
        case 'meta-description':
          editor.setContent(`<span>${this.selectedMetaDescription}</span>`);
          break;
        case 'content-outline':
          editor.setContent(`<span>${this.selectedOutlineContent}</span>`);
          break;
    }
  }

  handleBlur(event: any, id: string): void {
    const content = event.target.innerHTML;
    this.updateModel(id, content);
    this.removeFocusStyle(event.target);
  }

  removeFocusStyle(element: HTMLElement): void {
      element.classList.remove('focused');
  }

  private initializeTinyMCE() {
    tinymce.remove('.inline-editor');
    import('tinymce').then(() => {
      tinymce.init({
        selector: '.inline-editor',
        toolbar: 'undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist | link',
        toolbar_mode: 'floating',
        setup: (editor) => {
          editor.on('init', () => {
            //console.log(`Editor ${editor.id} initialized.`);
            this.loadInitialContent(editor);
          });
  
          editor.on('change', () => {
            const content = editor.getContent();
            const id = editor.id;
            //console.log(`Content on change from ${id}:`, content);
            this.updateModel(id, content);
          });
  
          editor.on('blur', () => {
            const content = editor.getContent();
            const id = editor.id;
           // console.log(`Content on blur from ${id}:`, content);
            this.updateModel(id, content);
          });
        }
      });
    });
  }
  
   reciveAssistantPreSelection(preSelectionData: AssistantPreSelection) {
    this.brand = preSelectionData.brand;
    this.publishUrl = preSelectionData.publishUrl;
    this.contentType = preSelectionData.contentType;
    this.vertical = preSelectionData.vertical;
   } 
  

   updateFormData(formData: AssistantForm): void {
    this.selectedTitle = (formData.title !== null && formData.title !== "") ? formData.title : this.selectedTitle;
    this.selectedPrimaryKeywords = (formData.primary_keyword !== null && formData.primary_keyword !== "") ? formData.primary_keyword : this.selectedPrimaryKeywords;
    this.selectedSecondaryKeyword = (formData.secondary_keywords && formData.secondary_keywords.length > 0) ? formData.secondary_keywords : this.selectedSecondaryKeyword;
    this.selectedMetaDescription = formData.meta_description || this.selectedMetaDescription ? formData.meta_description || '' : this.selectedMetaDescription;
    this.selectedWordCount = formData.word_count || this.selectedWordCount ? formData.word_count || '' : this.selectedWordCount;
    this.selectedToneOfVoice = formData.voice_and_tone || this.selectedToneOfVoice ? formData.voice_and_tone || '' : this.selectedToneOfVoice;
    this.selectedContentGoal = formData.content_goal || this.selectedContentGoal ? formData.content_goal || '' : this.selectedContentGoal;
    this.selectedPointOfView = formData.pov || this.selectedPointOfView ? formData.pov || '' : this.selectedPointOfView;
    this.selectedBusinessObjective = formData.business_objective || this.selectedBusinessObjective ? formData.business_objective || '' : this.selectedBusinessObjective;
    this.selectedCTA = formData.call_to_action || this.selectedCTA ? formData.call_to_action || '' : this.selectedCTA;
    this.selectedInternalLinks = (formData.internal_links && formData.internal_links.length > 0) ? formData.internal_links : this.selectedInternalLinks;
    this.selectedKeyPoints = (formData.key_points_to_emphasize && formData.key_points_to_emphasize.length > 0) ? formData.key_points_to_emphasize : this.selectedKeyPoints;
    this.selectedThingsToAvoid = (formData.things_to_avoid && formData.things_to_avoid.length > 0) ? formData.things_to_avoid : this.selectedThingsToAvoid;
    this.selectedAudienceInsights = (formData.target_audience !== null && formData.target_audience !== "") ? formData.target_audience : this.selectedAudienceInsights;
    this.selectedSpecialInstructions = formData.special_instructions || this.selectedSpecialInstructions.length > 0 ? formData.special_instructions || [] : this.selectedSpecialInstructions;
    this.selectedSerpAnalysis = formData.serp_analysis || this.selectedSerpAnalysis ? formData.serp_analysis || '' : this.selectedSerpAnalysis;
    this.selectedDifOpportunities = formData.differentiation_opportunities || this.selectedDifOpportunities.length > 0 ? formData.differentiation_opportunities || [] : this.selectedDifOpportunities;
    //this.selectedOutlineContent = formData.content_outline || this.selectedOutlineContent ? formData.content_outline || '' : this.selectedOutlineContent;
    //this.outlineContent = formData.content_outline || this.outlineContent ? formData.content_outline || '' : this.outlineContent;
    
    // converting OutlineContent value from Markdown to HTML
   // this.outlineContent = this.convertMarkdownToHtml(this.outlineContent);
    
    // Forcing the component to update with the new values
    this.cdr.detectChanges();
    
    // Reinitialize TinyMCE to reflect the changes
    this.initializeTinyMCE();
  }
  
  private updateModel(id: string, content: string) {
    let arrayFieldIds = ['secondary-keywords', 'internal-links', 'special-instructions', 'differentiation-opportunities'];
    let processedContent: any; 

    if (arrayFieldIds.includes(id)) {
        processedContent = this.extractListItems(content);
    } else {
        processedContent = this.stripHtml(content, id);
    }

    switch (id) {
        case 'title':
            this.selectedTitle = processedContent;
            break;
        case 'primary-keyword':
            this.selectedPrimaryKeywords = processedContent;
            break;
        case 'secondary-keywords':
            this.selectedSecondaryKeyword = processedContent; 
            break;
        case 'meta-description':
            this.selectedMetaDescription = processedContent;
            break;
        case 'word-count':
            this.selectedWordCount = processedContent;
            break;
        case 'tone-of-voice':
            this.selectedToneOfVoice = processedContent;
            break;
        case 'content-goal':
            this.selectedContentGoal = processedContent;
            break;
        case 'point-of-view':
            this.selectedPointOfView = processedContent;
            break;
        case 'business-objective':
            this.selectedBusinessObjective = processedContent;
            break;
        case 'cta':
        case 'call_to_action':
            this.selectedCTA = processedContent;
            break;
        case 'internal-links':
            this.selectedInternalLinks = processedContent; 
            break;
        case 'key-points':
            this.selectedKeyPoints = processedContent; 
            break;
        case 'things-to-avoid':
            this.selectedThingsToAvoid = processedContent; 
            break;
        case 'audience-insights':
            this.selectedAudienceInsights = processedContent;
            break;
        case 'special-instructions':
            this.selectedSpecialInstructions = processedContent;
            break;
        case 'serp-analysis':
            this.selectedSerpAnalysis = processedContent;
            break;
        case 'differentiation-opportunities':
            this.selectedDifOpportunities = processedContent;
            break;
        default:
            console.warn(`No handler for field: ${id}`);
    }
    this.autoSaveVersion();
    this.cdr.detectChanges();
}


  private showSaveIndicator(id: string) {
      this.saveIndicator[id] = true;
      setTimeout(() => {
          this.saveIndicator[id] = false;
      }, 3000);
  }

  private extractListItems(htmlContent: string): string[] {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const listItems = doc.querySelectorAll('li');
    return Array.from(listItems).map(li => li.textContent.trim());
}

  private stripHtml(html: string, id: string): string {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    let text = doc.body.textContent || '';
    text = text.replace(/\n+/g, ' ').trim();

    if (id !== 'title') {
        text = text.replace(/^[^:]*:\s*/, '');
    }

    return text;
  }

  public updateValue(type: string, value: any, updateType: string): void {
    //console.log("updateValue - Type",type,"value","updateType",updateType);
    let message = ''; 
    switch (type) {
      case 'title':
        this.selectedTitle = value;
        message = updateType === 'refresh_options' ? 'Generating new title options, you will be notified when completed' : 'Title updated';
        this.updateTinyMCEContent('title', value);
        break;
      case 'primaryKeyword':
        this.selectedPrimaryKeywords = value;
        message = updateType === 'refresh_options' ? 'Generating new primary keyword options, you will be notified when completed' : 'Primary Keyword updated';
        this.updateTinyMCEContent('primary-keyword', value);
        break;
      case 'secondaryKeywords':
        this.selectedSecondaryKeyword = value;
        message = updateType === 'refresh_options' ? 'Generating new secondary keywords options, you will be notified when completed' : 'Secondary Keywords updated';
        this.updateTinyMCEContent('secondary-keywords', value);
        break;
      case 'metaDescription':
        this.selectedMetaDescription = value;
        message = updateType === 'refresh_options' ? 'Generating new meta description options, you will be notified when completed' : 'Meta Description updated';
        this.updateTinyMCEContent('meta-description', value);
        break;
      case 'wordCount':
        this.selectedWordCount = value;
        message = updateType === 'refresh_options' ? 'Generating new word count options, you will be notified when completed' : 'Word Count updated';
        this.updateTinyMCEContent('word-count', value);
        break;
      case 'toneOfVoice':
        this.selectedToneOfVoice = value;
        message = updateType === 'refresh_options' ? 'Generating new voice and tone options, you will be notified when completed' : 'Voice and Tone updated';
        this.updateTinyMCEContent('tone-of-voice', value);
        break;
      case 'contentGoal':
        this.selectedContentGoal = value;
        message = updateType === 'refresh_options' ? 'Generating new content goal options, you will be notified when completed' : 'Content Goal updated';
        this.updateTinyMCEContent('content-goal', value);
        break;
      case 'pointOfView':
        this.selectedPointOfView = value;
        message = updateType === 'refresh_options' ? 'Generating new point of view options, you will be notified when completed' : 'Point of View updated';
        this.updateTinyMCEContent('point-of-view', value);
        break;
      case 'businessObjective':
        this.selectedBusinessObjective = value;
        message = updateType === 'refresh_options' ? 'Generating new business objective options, you will be notified when completed' : 'Business objective updated';
        this.updateTinyMCEContent('business-objective', value);
        break;
      case 'cta':
        this.selectedCTA = value;
        message = updateType === 'refresh_options' ? 'Generating new call to aciton options, you will be notified when completed' : 'Call to Action (CTA) updated';
        this.updateTinyMCEContent('cta', value);
        break;
      case 'internalLinks':
        this.selectedInternalLinks = value;
        message = updateType === 'refresh_options' ? 'Generating new links to include options, you will be notified when completed' : 'Links to Include updated';
        this.updateTinyMCEContent('internal-links', value);
        break;
      case 'keyPoints':
        this.selectedKeyPoints = value;
        message = updateType === 'refresh_options' ? 'Generating new key points options, you will be notified when completed' : 'Key Points updated';
        this.updateTinyMCEContent('key-points', value);
        break;
      case 'thingsToAvoid':
        this.selectedKeyPoints = value;
        message = updateType === 'refresh_options' ? 'Generating new things to avoid options, you will be notified when completed' : 'Things to Avoid updated';
        this.updateTinyMCEContent('things-to-avoid', value);
        break;
      case 'audience':
        this.selectedAudienceInsights = value;
        message = updateType === 'refresh_options' ? 'Generating new target audience options, you will be notified when completed' : 'Target Audience updated';
        this.updateTinyMCEContent('audience-insights', value);
        break;
      case 'specialInstructions':
        this.selectedSpecialInstructions = value;
        message = updateType === 'refresh_options' ? 'Generating new special instructions options, you will be notified when completed' : 'Special instructions updated';
        this.updateTinyMCEContent('special-instructions', value);
        break;
      case 'serpAnalysis':
        this.selectedSerpAnalysis = value;
        message = updateType === 'refresh_options' ? 'Generating new serp analysis options, you will be notified when completed' : 'Serp analysis updated';
        this.updateTinyMCEContent('serp-analysis', value);
        break;
      case 'differentiationOpportunities':
        this.selectedDifOpportunities = value;
        message = updateType === 'refresh_options' ? 'Generating new differentiation opportunities options, you will be notified when completed' : 'Differentiation opportunities updated';
        this.updateTinyMCEContent('differentiation-opportunities', value);
        break;
      default:
        console.warn(`Not found: ${type}`);
    }
  
    if (message) {
      this.snackBar.open(message, 'Close', {
        duration: 3500,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: 'form-snackbar'
      });
    }
    this.updateCompleted.emit(type);
    this.cdr.detectChanges();
  }

  private updateTinyMCEContent(id: string, content: string | string[]): void {
    //("updateTinyMCEContent");
    const editor = tinymce.get(id);
    if (editor) {
      let formattedContent = content;
  
      switch (id) {
        case 'title':
          formattedContent = `<div class="detail-wrap m-1 mb-2 p-2"><h1>${content}</h1></div>`;
          break;
        case 'primary-keyword':
          formattedContent = `<div class="detail-wrap m-1 mb-2 p-2"><h4>Primary Keyword:</h4><span>${content}</span></div>`;
          break;
        case 'secondary-keywords':
            if (Array.isArray(content)) {
              formattedContent = `<div class="detail-wrap m-1 mb-2 p-2"><h4>Secondary Keywords:</h4><ul>${content.map(item => `<li>${item}</li>`).join('')}</ul></div>`;
            }
            break;
        case 'meta-description':
          formattedContent = `<div class="detail-wrap m-1 mb-2 p-2"><h4>Meta Description:</h4><span>${content}</span></div>`;
          break;
        case 'word-count':
          formattedContent = `<div class="detail-wrap m-1 mb-2 p-2"><h4>Word Count:</h4><span>${content}</span></div>`;
          break;
        case 'tone-of-voice':
          formattedContent = `<div class="detail-wrap m-1 mb-2 p-2"><h4>Voice and Tone:</h4><span>${content}</span></div>`;
          break;
        case 'content-goal':
          formattedContent = `<div class="detail-wrap m-1 mb-2 p-2"><h4>Content Goal:</h4><span>${content}</span></div>`;
          break;
        case 'point-of-view':
          formattedContent = `<div class="detail-wrap m-1 mb-2 p-2"><h4>Point of view:</h4><span>${content}</span></div>`;
          break;
        case 'business-objective':
          formattedContent = `<div class="detail-wrap m-1 mb-2 p-2"><h4>Business objective:</h4><span>${content}</span></div>`;
          break;
        case 'cta':
          formattedContent = `<div class="detail-wrap m-1 mb-2 p-2"><h4>Call to Action (CTA):</h4><span>${content}</span></div>`;
          break;
        case 'internal-links':
          if (Array.isArray(content)) {
            formattedContent = `<div class="detail-wrap m-1 mb-2 p-2"><h4>Links to Include:</h4><ul>${content.map(item => `<li>${item}</li>`).join('')}</ul></div>`;
          }
          break;
        case 'audience-insights':
          formattedContent = `<div class="detail-wrap m-1 mb-2 p-2"><h4>Target Audience:</h4><span>${content}</span></div>`;
          break;
        case 'special-instructions':
            if (Array.isArray(content)) {
              formattedContent = `<div class="detail-wrap m-1 mb-2 p-2"><h4>Special Instructions:</h4><ul>${content.map(item => `<li>${item}</li>`).join('')}</ul></div>`;
            }
            break;
        case 'serp-analysis':
          formattedContent = `<div class="detail-wrap m-1 mb-2 p-2"><h4>Serp analysis:</h4><span>${content}</span></div>`;
          break;
        case 'differentiation-opportunities':
            if (Array.isArray(content)) {
              formattedContent = `<div class="detail-wrap m-1 mb-2 p-2"><h4>Differentiation opportunities:</h4><ul>${content.map(item => `<li>${item}</li>`).join('')}</ul></div>`;
            }
            break;
        case 'content-outline':
          formattedContent = `<div class="detail-wrap m-1 mb-2 p-2"><h4>Content Outline:</h4><span>${content}</span></div>`;
          break;
        default:
          break;
      }
  
      editor.setContent(formattedContent);
    }
  }
  

  // method to convert strings to TitleCase
  toTitleCase(str: any): string {
    if (typeof str !== 'string') {
      console.error('toTitleCase was called with a non-string argument:', str);
      str = String(str);
    }
  
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  }

 // handle string arrays
  toTitleCaseArray(strArray: string[]): string[] {
    return strArray.map(str => this.toTitleCase(str));
  }

  generateOutline() {
    this.generateOutlineRequested.emit();
  }

  convertMarkdownToHtml(markdown: string): string {
    let html = markdown;
  
    html = html.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    html = html.replace(/__(.*?)__/g, '<strong>$1</strong>');
    html = html.replace(/\*(.*?)\*/g, '<em>$1</em>');
    html = html.replace(/_(.*?)_/g, '<em>$1</em>');
    html = html.replace(/\n/g, '<br>');
    return html;
  }

   // method for adding keywords
  //  addKeyword(event: MatChipInputEvent): void {
  //   const value = (event.value || '').trim();
  //   if (value) {
  //     this.selectedPrimaryKeywords.push(value);
  //   }
  //   event.chipInput!.clear();
  // }

  // method to remove keywords
  removeKeyword(keyword: string): void {
    const index = this.selectedSecondaryKeyword.indexOf(keyword);
    if (index >= 0) {
      this.selectedSecondaryKeyword.splice(index, 1);
    }
  }

  // when the title is updated, emit an event with the new value
  updateTitle(newTitle: string): void {
    this.selectedTitle = newTitle;
    this.titleUpdated.emit(this.selectedTitle);
  }

  toggleTitleSuggestions() {
    this.suggestionTitlesOpen = !this.suggestionTitlesOpen;
    this.showTitleSuggestions.emit(this.suggestionTitlesOpen);
  }  

  toggleMainKeywordSuggestions(): void {
    this.primarySuggestionsOpen = !this.primarySuggestionsOpen;
    this.showMainKeywordSuggestions.emit(this.primarySuggestionsOpen);
  }

  // when the primary is updated, emit an event with the new value
  updatePrimaryKeyword(newPrimaryKeyword: string): void {
    this.selectedPrimaryKeywords = newPrimaryKeyword;
    this.primaryKeywordUpdated.emit(this.selectedPrimaryKeywords);
  }
  
  toggleSecondaryKeywordSuggestions(): void {
    this.secondarySuggestionsOpen = !this.secondarySuggestionsOpen;
    this.showSecondaryKeywordSuggestions.emit(this.secondarySuggestionsOpen); 
  }

  toggleMetaDescriptionSuggestions(): void {
    this.metaDesciptionOpen = !this.metaDesciptionOpen;
    this.showMetaDescriptionSuggestions.emit(this.metaDesciptionOpen);
  }

  updateMetaDescription(metaDecription: string): void {
    this.selectedMetaDescription = metaDecription;
    this.metaDescriptionUpdated.emit(this.selectedMetaDescription);
  }

  // focusEditable(event: Event): void {
  //   const element = document.getElementById('editable-primary-keywords');
  //   // TypeScript não sabe que tipo de elemento é este, então você precisa afirmar o tipo correto:
  //   if (element instanceof HTMLElement) {
  //       element.focus(); // Agora o TypeScript sabe que 'element' é um HTMLElement e tem um método 'focus'.
  //   }
  // }


  selectSection(event: MouseEvent, sectionId: string): void {
    this.selectedSectionId = sectionId;
    this.maintainFocusStyle = true;
    this.selectedSectionElement = event.target as HTMLElement;
    this.applyHighlight(this.selectedSectionId);
    this.sectionSelected.emit(this.selectedSectionId);
  }

  deselectSection(): void {
    this.selectedSectionId = ''; // hide suggestions icon
  }

  onToggleSuggestionsPanel(): void {
    this.toggleSuggestionsPanel.emit();
  }

  toggleFocusClass(applyFocus: boolean): void {
    const editableElements = this.editableContainer.nativeElement.querySelectorAll('[contenteditable=true]');
    editableElements.forEach((element: HTMLElement) => {
      element.classList.add('focused');
    });
  }
  

  openSuggestionsPanel(sectionId: string): void {   
    this.selectedSectionId = sectionId;
    this.toggleSuggestionsPanel.emit();
  }
  
  
  calculateIconPosition(): number {
    if (!this.selectedSectionElement) return 0;
    const elementRect = this.selectedSectionElement.getBoundingClientRect();
    const iconPosition = elementRect.right; 
    return window.innerWidth - iconPosition; 
  }

  exportToWord() {
    if (this.editableContainer && this.editableContainer.nativeElement) {
      const styles = `
        <style>
          body {
            font-family: 'Arial', sans-serif; 
          }
          h1, h2, h3, p, h4, ul, li { 
            color: #626262; 
            margin-bottom: 20px; 
          }
          h1 {
            font-size: 2em; 
            margin-bottom: 30px; 
          }
          h2 {
            font-size: 1.2em;
            color: #4f4f4f;
            line-height: 30px;
            font-weight: 550;
          }
          h3 {
            font-size: 1em;
            color: #403f42;
            line-height: 40px;
            font-weight: 550; 
          }
          h4 {
            color: #626262; 
            font-weight: bold; 
          }
          ul {
            margin-top: 0;
          }
          li {
            font-weight: normal;
            margin-bottom: 10px; 
          }
        </style>
      `;
  
      const header = `<html><head><meta charset='utf-8'><title>Documento</title>${styles}</head><body>`;
      const footer = `</body></html>`;

      const dynamicFieldsContent = this.dynamicFields.map(field => {
        return `<h3>${field.title}:</h3><p>${field.content}</p>`;
      }).join('');      

      const content = `
        <h1>${this.selectedTitle}</h1>
        ${this.field ? `<h3>Field:</h3><p>${this.field}</p>` : ''}
        ${this.brand ? `<h3>Brand:</h3><p>${this.brand}</p>` : ''}
        ${this.publishUrl ? `<h3>Publish URL:</h3><p>${this.publishUrl}</p>` : ''}
        ${this.contentType ? `<h3>Content Type:</h3><p>${this.contentType}</p>` : ''}
        ${this.vertical ? `<h3>Vertical:</h3><p>${this.vertical}</p>` : ''}
        ${this.selectedPrimaryKeywords ? `<h3>Primary Keyword:</h3><p>${this.selectedPrimaryKeywords}</p>` : ''}
        ${this.selectedSecondaryKeyword && this.selectedSecondaryKeyword.length > 0 ? `<h3>Secondary Keywords:</h3><ul>${this.selectedSecondaryKeyword.map(keyword => `<li>${keyword}</li>`).join('')}</ul>` : ''}
        ${this.selectedMetaDescription ? `<h3>Meta Description:</h3><p>${this.selectedMetaDescription}</p>` : ''}
        ${this.selectedWordCount ? `<h3>Word Count:</h3><p>${this.selectedWordCount}</p>` : ''}
        ${this.selectedToneOfVoice ? `<h3>Voice and Tone:</h3><p>${this.selectedToneOfVoice}</p>` : ''}
        ${this.selectedContentGoal ? `<h3>Content Goal:</h3><p>${this.selectedContentGoal}</p>` : ''}
        ${this.selectedPointOfView ? `<h3>Point of view:</h3><p>${this.selectedPointOfView}</p>` : ''}
        ${this.selectedBusinessObjective ? `<h3>Business objective:</h3><p>${this.selectedBusinessObjective}</p>` : ''}
        ${this.selectedCTA ? `<h3>Call to Action (CTA):</h3><p>${this.selectedCTA}</p>` : ''}
        ${this.selectedInternalLinks && this.selectedInternalLinks.length > 0 ? `<h3>Links to Include:</h3><ul>${this.selectedInternalLinks.map(internalLinks => `<li>${internalLinks}</li>`).join('')}</ul>` : ''}
        ${this.selectedKeyPoints && this.selectedKeyPoints.length > 0 ? `<h3>Key Points:</h3><ul>${this.selectedKeyPoints.map(keyPoints => `<li>${keyPoints}</li>`).join('')}</ul>` : ''}
        ${this.selectedThingsToAvoid && this.selectedThingsToAvoid.length > 0 ? `<h3>Things to avoid:</h3><ul>${this.selectedThingsToAvoid.map(thingsToAvoid => `<li>${thingsToAvoid}</li>`).join('')}</ul>` : ''}
        ${this.selectedAudienceInsights ? `<h3>Target Audience:</h3><p>${this.selectedAudienceInsights}</p>` : ''}
        ${this.selectedSpecialInstructions && this.selectedSpecialInstructions.length > 0 ? `<h3>Special Instructions:</h3><ul>${this.selectedSpecialInstructions.map(specialInstructions => `<li>${specialInstructions}</li>`).join('')}</ul>` : ''}
        ${this.selectedSerpAnalysis ? `<h3>Serp analysis:</h3><p>${this.selectedSerpAnalysis}</p>` : ''}
        ${this.selectedDifOpportunities && this.selectedDifOpportunities.length > 0 ? `<h3>Differentiation opportunities:</h3><ul>${this.selectedDifOpportunities.map(difOpportunities => `<li>${difOpportunities}</li>`).join('')}</ul>` : ''}
        ${this.selectedOutlineContent ? `<h3>Content Outline:</h3><p>${this.selectedOutlineContent}</p>` : ''}
        ${dynamicFieldsContent}
      `;
  
      const html = header + content + footer;
  
      const blob = new Blob(['\ufeff', html], {
        type: 'application/msword'
      });
  
      const fileName = this.selectedTitle
      ? this.selectedTitle.replace(/[/\\?%*:|"<>]/g, '').replace(/\s+/g, '_').toLowerCase() + '.docx'
      : 'documento.docx';
    saveAs(blob, fileName);
    } else {
      console.error('error to load docx element');
    }
  }

  exportToPdf() {
    const exportHtml = this.createExportHtml();
  
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = exportHtml;
    tempDiv.style.position = 'absolute';
    tempDiv.style.left = '-9999px';
    document.body.appendChild(tempDiv);
  
    setTimeout(() => {
      html2canvas(tempDiv, {
        scale: 3, 
        useCORS: true
      }).then(canvas => {
        document.body.removeChild(tempDiv);
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'p',
          unit: 'mm',
          format: 'a4'
        });
  
        const pageHeight = pdf.internal.pageSize.height;
        let imgHeight = canvas.height * 190 / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
  
        pdf.addImage(imgData, 'PNG', 10, position, 190, imgHeight);
        heightLeft -= pageHeight;
  
        while (heightLeft > 0) {
          position = position + imgHeight;  
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 10, position - imgHeight, 190, imgHeight);
          heightLeft -= pageHeight;
        }
  
        const fileName = this.selectedTitle ? this.selectedTitle.replace(/[/\\?%*:|"<>]/g, '').replace(/\s+/g, '_').toLowerCase() + '.pdf' : 'document.pdf';
        pdf.save(fileName);
      });
    }, 100);
  }

  createExportHtml() {
    const styles = `
      <style>
      body { font-family: 'Arial', sans-serif; }
      h1, h2, h3, p, h4, ul, li {
        color: #626262;
        margin-bottom: 20px;
      }
      h1 {
        font-size: 2em;
        margin-bottom: 30px;
      }
      h2 {
        font-size: 1.5em;
      }
      h3 {
        font-size: 1.2em;
        color: #4f4f4f;
        line-height: 30px;
        font-weight: 550;
      }
      h4 {
        color: #626262;
        font-weight: bold;
      }
      ul, ol {
        padding-left: 30px;
        margin-bottom: 15px;
        list-style-type: disc;
      }
      li {
        margin-bottom: 5px;
        padding-right: 20px;
      }
      li + p, li + div {  
        margin-left: 20px;
      }
      .content-section p, .content-section div {
        margin-left: 20px;
      }
    </style>
  `;
  
    const dynamicFieldsContent = this.dynamicFields.map(field => {
      return `<h3>${field.title}:</h3><p>${field.content}</p>`;
    }).join('');
  
    const content = `
      <h1>${this.selectedTitle}</h1>
      ${this.field ? `<h3>Field:</h3><p>${this.field}</p>` : ''}
      ${this.brand ? `<h3>Brand:</h3><p>${this.brand}</p>` : ''}
      ${this.publishUrl ? `<h3>Publish URL:</h3><p>${this.publishUrl}</p>` : ''}
      ${this.contentType ? `<h3>Content Type:</h3><p>${this.contentType}</p>` : ''}
      ${this.vertical ? `<h3>Vertical:</h3><p>${this.vertical}</p>` : ''}
      ${this.selectedPrimaryKeywords ? `<h3>Primary Keyword:</h3><p>${this.selectedPrimaryKeywords}</p>` : ''}
      ${this.selectedSecondaryKeyword && this.selectedSecondaryKeyword.length > 0 ? `<h3>Secondary Keywords:</h3><ul>${this.selectedSecondaryKeyword.map(keyword => `<li>${keyword}</li>`).join('')}</ul>` : ''}
      ${this.selectedMetaDescription ? `<h3>Meta Description:</h3><p>${this.selectedMetaDescription}</p>` : ''}
      ${this.selectedWordCount ? `<h3>Word Count:</h3><p>${this.selectedWordCount}</p>` : ''}
      ${this.selectedToneOfVoice ? `<h3>Voice and Tone:</h3><p>${this.selectedToneOfVoice}</p>` : ''}
      ${this.selectedContentGoal ? `<h3>Content Goal:</h3><p>${this.selectedContentGoal}</p>` : ''}
      ${this.selectedPointOfView ? `<h3>Point of view:</h3><p>${this.selectedPointOfView}</p>` : ''}
      ${this.selectedBusinessObjective ? `<h3>Business objective:</h3><p>${this.selectedBusinessObjective}</p>` : ''}
      ${this.selectedCTA ? `<h3>Call to Action (CTA):</h3><p>${this.selectedCTA}</p>` : ''}
      ${this.selectedInternalLinks && this.selectedInternalLinks.length > 0 ? `<h3>Links to Include:</h3><ul>${this.selectedInternalLinks.map(internalLinks => `<li>${internalLinks}</li>`).join('')}</ul>` : ''}
      ${this.selectedKeyPoints && this.selectedKeyPoints.length > 0 ? `<h3>Key Points:</h3><ul>${this.selectedKeyPoints.map(keyPoints => `<li>${keyPoints}</li>`).join('')}</ul>` : ''}
      ${this.selectedThingsToAvoid && this.selectedThingsToAvoid.length > 0 ? `<h3>Things to avoid:</h3><ul>${this.selectedThingsToAvoid.map(thingsToAvoid => `<li>${thingsToAvoid}</li>`).join('')}</ul>` : ''}
      ${this.selectedAudienceInsights ? `<h3>Target Audience:</h3><p>${this.selectedAudienceInsights}</p>` : ''}
      ${this.selectedSpecialInstructions && this.selectedSpecialInstructions.length > 0 ? `<h3>Special Instructions:</h3><ul>${this.selectedSpecialInstructions.map(specialInstructions => `<li>${specialInstructions}</li>`).join('')}</ul>` : ''}
      ${this.selectedSerpAnalysis ? `<h3>Serp analysis:</h3><p>${this.selectedSerpAnalysis}</p>` : ''}
      ${this.selectedDifOpportunities && this.selectedDifOpportunities.length > 0 ? `<h3>Differentiation opportunities:</h3><ul>${this.selectedDifOpportunities.map(difOpportunities => `<li>${difOpportunities}</li>`).join('')}</ul>` : ''}
      <div class="content-section">${this.selectedOutlineContent ? `<h3>Content Outline:</h3><div>${this.selectedOutlineContent}</div>` : ''}</div>
      ${dynamicFieldsContent}
    `;
  
    const html = `<html><head><meta charset='utf-8'><title>Document</title>${styles}</head><body>${content}</body></html>`;
    return html;
  }
  
  
  
  hasContentToExport(): boolean {
    return !!(
      this.field ||
      this.brand ||
      this.publishUrl ||
      this.contentType ||
      this.vertical ||
      this.selectedTitle ||
      this.selectedPrimaryKeywords ||
      (this.selectedSecondaryKeyword && this.selectedSecondaryKeyword.length > 0) ||
      this.selectedMetaDescription ||
      this.selectedWordCount ||
      this.selectedToneOfVoice ||
      this.selectedContentGoal ||
      this.selectedPointOfView ||
      this.selectedBusinessObjective ||
      this.selectedCTA ||
      this.selectedInternalLinks ||
      this.selectedAudienceInsights ||
      (this.selectedSpecialInstructions && this.selectedSpecialInstructions.length > 0) ||
      this.selectedSerpAnalysis ||
      (this.selectedDifOpportunities && this.selectedDifOpportunities.length > 0) ||
      this.selectedOutlineContent
    );
  }
  
  focusOnSection(sectionId: string | null) {
    if (!sectionId) {
      return;
    }
  
    const element = document.getElementById(sectionId);
  
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  
      setTimeout(() => {
        const editorHeight = 70;
        const elementRect = element.getBoundingClientRect();
        const scrollTop = window.scrollY || document.documentElement.scrollTop; 
        const offsetTop = elementRect.top + scrollTop - editorHeight; 
  
        window.scrollTo({ top: offsetTop, behavior: 'smooth' });
  
        if (element.contentEditable === 'true') {
          element.focus();
        }
      }, 0); 
    } else {
      //console.log(`Element with ID ${sectionId} not found`);
    }
  }

  updateContentOutline(contentOutline: ContentOutline): void {
    this.selectedOutlineContent = contentOutline.outline_html;
    this.safeOutlineContent = this.sanitizer.bypassSecurityTrustHtml(this.selectedOutlineContent);
    this.cdr.detectChanges();
  }

  loadPreviounContentOutline(contentOutline: string): void {
    this.selectedOutlineContent = contentOutline;
    this.safeOutlineContent = this.sanitizer.bypassSecurityTrustHtml(this.selectedOutlineContent);
    this.cdr.detectChanges();
  }

  private updateSectionsWithSuggestions() {
    this.sectionsWithSuggestions.clear(); 
    this.suggestions.forEach(suggestion => {
      if (suggestion.section) {
        const sectionId = this.transformSectionToId(suggestion.section);
        this.sectionsWithSuggestions.add(sectionId);
      }
    });
  }

  private transformSectionToId(sectionName: string): string {
    return sectionName.toLowerCase().replace(/\s+/g, '-');
  }
  public getCurrentFormData(): AssistantForm {
   // console.log("getCurrentFormData");
    return {
      title: this.selectedTitle || "",
      primary_keyword: this.selectedPrimaryKeywords || "",
      secondary_keywords: this.selectedSecondaryKeyword || [],
      word_count: this.selectedWordCount || "",
      voice_and_tone: this.selectedToneOfVoice || "",
      content_goal: this.selectedContentGoal || "",
      business_objective: this.selectedBusinessObjective || "",
      call_to_action: this.selectedCTA || "",
      internal_links: this.selectedInternalLinks || [],
      target_audience: this.selectedAudienceInsights || "",
      key_points_to_emphasize: this.selectedKeyPoints || [],
      things_to_avoid: this.selectedThingsToAvoid || [],
      meta_description: this.selectedMetaDescription || "",
    };
  }

  updateField(fieldName: string, target: EventTarget): void {
    const element = target as HTMLElement;
    const fullText = element.textContent.trim();
    const editableText = fullText.substring(fullText.indexOf(':') + 1).trim();
    this[fieldName] = editableText;
  
    window.requestAnimationFrame(() => {
      const range = document.createRange();
      const sel = window.getSelection();
      range.selectNodeContents(element);
      range.collapse(false);
      sel.removeAllRanges();
      sel.addRange(range);
    });
  }

  //custom fields
  addDynamicField() {
    const newField = {
      id: `dynamic-field-${this.dynamicFields.length}`,
      title: 'New field',
      content: 'New description'
    };
    this.dynamicFields.push(newField);
    this.cdr.detectChanges(); 
    setTimeout(() => this.initializeTinyMCEForField(newField.id), 100);
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    this.scrollTarget.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }

  private initializeTinyMCEForField(fieldId: string) {
    tinymce.init({
      ...this.editorConfig,
      selector: `#${fieldId}`,
      setup: (editor) => {
        editor.on('change', () => {
          const content = editor.getContent();
          this.updateDynamicFieldContent(fieldId, content);
        });
      }
    });
  }
  
  
  private updateDynamicFieldContent(fieldId: string, content: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const titleElement = doc.querySelector('h4');
    const contentElement = doc.querySelector('span');
  
    const fieldIndex = this.dynamicFields.findIndex(field => field.id === fieldId);
    if (fieldIndex !== -1) {
      if (titleElement) {
        this.dynamicFields[fieldIndex].title = titleElement.textContent.replace(/:$/, ''); 
      }
      if (contentElement) {
        this.dynamicFields[fieldIndex].content = contentElement.textContent;
      }
      this.cdr.detectChanges();
    }
  }

  applyHighlight(sectionId: string): void {
    this.clearHighlights();
    if (!this.editableContainer) return;
  
    const element = this.editableContainer.nativeElement.querySelector(`#${sectionId}`);
    if (element) {
      element.style.boxShadow = '4px 4px 12px -7px rgba(0, 105, 203, 0.5), 2px 2px 12px -12px rgba(0, 105, 203, 0.5)';
      element.style.outline = '0px solid #ccc';
      element.style.border = '2px solid rgba(0, 105, 203, 0.5)';
      element.style.borderRadius = '10px';
    }
  }

  clearHighlights(): void {
    const elements = this.editableContainer.nativeElement.querySelectorAll('[contenteditable=true]');
    elements.forEach((element: HTMLElement) => {
      element.style.boxShadow = '';
      element.style.outline = '';
      element.style.border = '';
      element.style.borderRadius = '';
    });
  }
  
  clearHighlight(sectionId: string): void {
    const element = this.editableContainer.nativeElement.querySelector(`#${sectionId}`);
    if (element) {
      element.style.boxShadow = '';
      element.style.outline = '';
      element.style.border = '';
      element.style.borderRadius = '';
    }
  }

  htmlToDocxParagraphs(html: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const paragraphs = [];

    doc.body.childNodes.forEach(node => {
        if (node.nodeType === Node.ELEMENT_NODE) {
            const element = node as HTMLElement;
            const { paragraph, run } = this.getStyleForTag(element.tagName.toLowerCase());

            if (element.tagName.toLowerCase() === 'ul') {
                element.querySelectorAll('li').forEach(li => {
                    paragraphs.push(new Paragraph({
                        children: [new TextRun({
                            text: li.textContent || '',
                            ...run
                        })],
                        bullet: { level: 0 }, 
                        ...paragraph
                    }));
                });
            } else {
                paragraphs.push(new Paragraph({
                    children: [new TextRun({
                        text: element.textContent || '',
                        ...run
                    })],
                    ...paragraph
                }));
            }
        } else if (node.nodeType === Node.TEXT_NODE && node.textContent?.trim()) {
            paragraphs.push(new Paragraph({
                children: [new TextRun({
                    text: node.textContent || '',
                    ...this.getStyleForTag('p').run
                })],
                ...this.getStyleForTag('p').paragraph
            }));
        }
    });

    return paragraphs;
}

 getStyleForTag(tag: string): { paragraph: IParagraphPropertiesOptions, run: IRunPropertiesOptions } {
  const commonStyles = {
      run: {
          color: '626262', // Define the font color
      },
      paragraph: {
          spacing: { after: 200 } // Common spacing for most tags
      }
  };

  switch (tag) {
      case 'h1':
          return {
              paragraph: {
                  ...commonStyles.paragraph,
                  spacing: { after: 300 } // equivalent to 30px margin-bottom
              },
              run: {
                  ...commonStyles.run,
                  size: 32, // equivalent to 2em in 24-point base size
                  bold: true,
                  color: '#626262'
              }
          };
      case 'h2':
          return {
              paragraph: {
                  ...commonStyles.paragraph,
                  spacing: { line: 300, after: 200 }  // equivalent to 1.25 line height
              },
              run: {
                  ...commonStyles.run,
                  size: 24, // equivalent to 1.2em
                  bold: true,
                  color: '#4f4f4f'
              }
          };
      case 'h3':
          return {
              paragraph: {
                  ...commonStyles.paragraph,
                  spacing: { line: 400 } // equivalent to 1.67 line height
              },
              run: {
                  ...commonStyles.run,
                  size: 22, // equivalent to 1em
                  bold: true,
                  color: '403f42'
              }
          };
      case 'h4':
          return {
              paragraph: commonStyles.paragraph,
              run: {
                  ...commonStyles.run,
                  size: 22,
                  bold: true
              }
          };
          case 'p':
            return {
                paragraph: {
                    ...commonStyles.paragraph,
                    spacing: { before: 20, after: 200 } // 2px margin-top
                },
                run: {
                    ...commonStyles.run,
                    size: 14
                }
            };
        case 'li':
            return {
                paragraph: {
                    ...commonStyles.paragraph,
                    spacing: { before: 20, after: 100 } // 2px margin-top, 10px margin-bottom
                },
                run: {
                    ...commonStyles.run,
                    size: 14
                }
            };
        default:
            return {
                paragraph: {
                    ...commonStyles.paragraph,
                    spacing: { before: 20, after: 200 } // Default spacing
                },
                run: commonStyles.run
            };
    }
}

newExportToWord() {
  const children = [];

  const sections = [
      { title: 'Title', content: this.selectedTitle },
      { title: 'Brand', content: this.brand },
      { title: 'Publish URL', content: this.publishUrl },
      { title: 'Content Type', content: this.contentType },
      { title: 'Vertical', content: this.vertical },
      { title: 'Primary Keyword', content: this.selectedPrimaryKeywords },
      { title: 'Secondary Keywords', content: this.selectedSecondaryKeyword },
      { title: 'Word Count', content: this.selectedWordCount },
      { title: 'Target Audience', content: this.selectedAudienceInsights },
      { title: 'Voice and Tone', content: this.selectedToneOfVoice },
      { title: 'Content Goal', content: this.selectedContentGoal },
      { title: 'Business Objective', content: this.selectedBusinessObjective },
      { title: 'Call to Action', content: this.selectedCTA },
      { title: 'Internal Links', content: this.selectedInternalLinks },
      { title: 'Key Points to Emphasize', content: this.selectedKeyPoints },
      { title: 'Things to Avoid', content: this.selectedThingsToAvoid },
      { title: 'Point of View', content: this.selectedPointOfView },
      { title: 'Special Instructions', content: this.selectedSpecialInstructions },
      { title: 'Serp Analysis', content: this.selectedSerpAnalysis },
      { title: 'Differentiation Opportunities', content: this.selectedDifOpportunities },
      { title: 'Meta Description', content: this.selectedMetaDescription },
      { title: 'Content Outline', content: this.selectedOutlineContent },
      ...this.dynamicFields.map(field => ({ title: field.title, content: field.content }))
  ].filter(section => section.content && (Array.isArray(section.content) ? section.content.length > 0 : section.content.trim() !== ''));

  sections.forEach(section => {
    if (Array.isArray(section.content)) {
        children.push(new Paragraph({
            children: [
                new TextRun({
                    text: `${section.title}: `,
                    bold: true,
                    size: 22, 
                    color: '4F4F4F',
                })
            ],
            spacing: { after: 200 }
        }));
        section.content.forEach(item => {
            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: item,
                        size: 20, 
                        color: '626262', 
                    })
                ],
                bullet: { level: 0 },
                spacing: { after: 200 }
            }));
        });
    } else if (/<[a-z][\s\S]*>/i.test(section.content)) {
        // HTML content
        children.push(new Paragraph({
            children: [
                new TextRun({
                    text: `${section.title}: `,
                    bold: true,
                    size: 22,
                    color: '4F4F4F',
                })
            ],
            spacing: { after: 200 }
        }));
        const convertedParagraphs = this.htmlToDocxParagraphs(section.content);
        convertedParagraphs.forEach(paragraph => children.push(paragraph));
    } else {
        // Texto normal
        children.push(new Paragraph({
            children: [
                new TextRun({
                    text: `${section.title}: `,
                    bold: true,
                    size: 22,
                    color: '4F4F4F',
                }),
                new TextRun({
                    text: section.content,
                    size: 20,
                    color: '626262',
                })
            ],
            spacing: { after: 200 }
        }));
    }
});

    const doc = new Document({
        sections: [{ children }]
    });

    const filename = `${this.selectedTitle.replace(/[^a-zA-Z0-9_\- ]/g, '')}.docx`;

    Packer.toBlob(doc).then(blob => {
        saveAs(blob, filename); 
    });
}
updateFormWithVersion(version: VersionControlData): void {
  this.selectedTitle = version.data_obj.title;
  this.selectedPrimaryKeywords = version.data_obj.primary_keyword;
  this.selectedSecondaryKeyword = version.data_obj.secondary_keywords;
  this.selectedWordCount = version.data_obj.word_count;
  this.selectedAudienceInsights = version.data_obj.target_audience;
  this.selectedToneOfVoice = version.data_obj.voice_and_tone;
  this.selectedContentGoal = version.data_obj.content_goal;
  this.selectedBusinessObjective = version.data_obj.business_objective;
  this.selectedCTA = version.data_obj.call_to_action;
  this.selectedInternalLinks = version.data_obj.internal_links;
  this.selectedKeyPoints = version.data_obj.key_points_to_emphasize;
  this.selectedThingsToAvoid = version.data_obj.things_to_avoid;
  this.selectedMetaDescription = version.data_obj.meta_description;

  const dataObj: DataObject = {
    title: this.selectedTitle,
    primary_keyword: this.selectedPrimaryKeywords,
    secondary_keywords: this.selectedSecondaryKeyword,
    word_count: this.selectedWordCount,
    target_audience: this.selectedAudienceInsights,
    voice_and_tone: this.selectedToneOfVoice,
    content_goal: this.selectedContentGoal,
    business_objective: this.selectedBusinessObjective,
    call_to_action: this.selectedCTA,
    internal_links: this.selectedInternalLinks,
    key_points_to_emphasize: this.selectedKeyPoints,
    things_to_avoid: this.selectedThingsToAvoid,
    meta_description: this.selectedMetaDescription
  };

  this.assistantManagerService.saveVersion(this.sessionId, dataObj).subscribe({
    next: (response) => {
      this.fetchSessionHistory(this.sessionId);  // updating session history
    },
    error: (error) => {
      console.error('Error saving version', error);
    }
  });

  // Reinitialize TinyMCE or update other UI elements as needed
  this.initializeTinyMCE();
}


saveVersion(versionName: string): void {
  if (!versionName) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Please enter a version name.'
    });
    return;
  }

  // Consolidate data object
  const dataObj: DataObject = {
    title: this.selectedTitle,
    primary_keyword: this.selectedPrimaryKeywords,
    secondary_keywords: this.selectedSecondaryKeyword,
    word_count: this.selectedWordCount,
    target_audience: this.selectedAudienceInsights,
    voice_and_tone: this.selectedToneOfVoice,
    content_goal: this.selectedContentGoal,
    business_objective: this.selectedBusinessObjective,
    call_to_action: this.selectedCTA,
    internal_links: this.selectedInternalLinks,
    key_points_to_emphasize: this.selectedKeyPoints,
    things_to_avoid: this.selectedThingsToAvoid,
    meta_description: this.selectedMetaDescription
  };

  // Send the data object to the service
  this.assistantManagerService.saveVersion(this.sessionId, dataObj, versionName).subscribe({
    next: (response) => {
      if (response) {  // checks if the response has the ID
        Swal.fire(
          'Saved!',
          'Your version has been saved.',
          'success'
        );

        // Adicionar a versão ao array
        this.versionData.unshift({
          data_obj: dataObj,
          parent_ver_control_id: response.parentId || '',
          created_at: new Date().toISOString(),
          id: response.id,
          custom_name: versionName  
        });

        this.newVersionName = ''; // Clear field after saving
      } else {
        console.error('Response does not contain expected data');
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'The server did not return the expected data.'
        });
      }
    },
    error: (error) => {
      console.error('Error saving version', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed to Save',
        text: 'There was a problem saving the version. Please try again.'
      });
    }
  });
}


  openSaveNewVersionDialog(): void {
    const dialogRef = this.dialog.open(SaveVersionDialogComponent, {
      width: '400px',
      height: 'auto'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.saveVersion(result);
      }
    });
  }

  autoSaveVersion(): void {
   // console.log("autoSaveVersion");
    const dataObj: DataObject = {
      title: this.selectedTitle,
      primary_keyword: this.selectedPrimaryKeywords,
      secondary_keywords: this.selectedSecondaryKeyword,
      word_count: this.selectedWordCount,
      target_audience: this.selectedAudienceInsights,
      voice_and_tone: this.selectedToneOfVoice,
      content_goal: this.selectedContentGoal,
      business_objective: this.selectedBusinessObjective,
      call_to_action: this.selectedCTA,
      internal_links: this.selectedInternalLinks,
      key_points_to_emphasize: this.selectedKeyPoints,
      things_to_avoid: this.selectedThingsToAvoid,
      meta_description: this.selectedMetaDescription
    };

    //console.log("autoSaveVersion DataObj",dataObj);
  
    this.assistantManagerService.saveVersion(this.sessionId, dataObj).subscribe({
      next: (response) => {
       // console.log('autoSaveVersion saved successfully', response);
        this.fetchSessionHistory(this.sessionId); 
      },
      error: (error) => {
        console.error('Error autoSaveVersion version', error);
      }
    });
  }
  fetchSessionHistory(sessionId: string): void {
    //console.log("fetchSessionHistory",sessionId);
    this.assistantManagerService.getSessionData(sessionId).subscribe({
      next: (response) => {
        //console.log("fetchSessionHistory",response);
        this.sessionHistory = response.response;
        console.log("sessionHistory",this.sessionHistory);
        this.currentSessionIndex = this.sessionHistory.length - 1;
      },
      error: (error) => {
        //console.error('Error fetching session data', error);
      }
    });
  }
  goToPreviousSession(): void {
    if (this.currentSessionIndex > 0) {
      this.currentSessionIndex--;
      this.updateFormWithSession(this.sessionHistory[this.currentSessionIndex]);
    }
  }

  goToNextSession(): void {
    if (this.currentSessionIndex < this.sessionHistory.length - 1) {
      this.currentSessionIndex++;
      this.updateFormWithSession(this.sessionHistory[this.currentSessionIndex]);
    }
  }  

  updateFormWithSession(session: any): void {
    //console.log("updateFormWithSession", session);
    let dataObj = session.data_obj;
  
    // Verifique se data_obj é uma string e faça o parse, caso contrário, use-o diretamente
    if (typeof dataObj === 'string') {
      try {
        dataObj = JSON.parse(dataObj);
      } catch (error) {
        //console.error('Error parsing data_obj:', error);
        return; // Parar a execução se o parse falhar
      }
    }
  
    // Access 'form' dentro de 'data_obj' se presente
    const form = dataObj.form || dataObj; // Use form se presente, caso contrário, use dataObj diretamente
    //console.log("updateFormWithSession FORM",form);
  
    this.selectedTitle = form.title || "";
    this.selectedPrimaryKeywords = form.primary_keyword || "";
    this.selectedSecondaryKeyword = form.secondary_keywords || [];
    this.selectedWordCount = form.word_count || "";
    this.selectedAudienceInsights = form.target_audience || "";
    this.selectedToneOfVoice = form.voice_and_tone || "";
    this.selectedContentGoal = form.content_goal || "";
    this.selectedBusinessObjective = form.business_objective || "";
    this.selectedCTA = form.call_to_action || "";
    this.selectedInternalLinks = form.internal_links || [];
    this.selectedKeyPoints = form.key_points_to_emphasize || [];
    this.selectedThingsToAvoid = form.things_to_avoid || [];
    this.selectedMetaDescription = form.meta_description || "";
    this.initializeTinyMCE();
  
    //console.log("Updated with session data:", this.selectedTitle, this.selectedPrimaryKeywords);
  }
}


  





/*
newExportToPdf() {
  const docDefinition = {
    content: [
      { text: this.selectedTitle, fontSize: 24, bold: true, color: '#626262', margin: [0, 0, 0, 20] },
      this.field ? { text: [{ text: 'Field: ', bold: true, fontSize: 12, lineHeight: 1.5 }, { text: `${this.field}`, color: '#626262' }], margin: [0, 0, 0, 5] } : null,
      this.brand ? { text: [{ text: 'Brand: ', bold: true, fontSize: 12, lineHeight: 1.5 }, { text: `${this.brand}`, color: '#626262' }], margin: [0, 0, 0, 5] } : null,
      this.publishUrl ? { text: [{ text: 'Publish URL: ', bold: true, fontSize: 12, lineHeight: 1.5 }, { text: `${this.publishUrl}`, color: '#626262' }], margin: [0, 0, 0, 5] } : null,
      this.contentType ? { text: [{ text: 'Content Type: ', bold: true, fontSize: 12, lineHeight: 1.5 }, { text: `${this.contentType}`, color: '#626262' }], margin: [0, 0, 0, 5] } : null,
      this.vertical ? { text: [{ text: 'Vertical: ', bold: true, fontSize: 12, lineHeight: 1.5 }, { text: `${this.vertical}`, color: '#626262' }], margin: [0, 0, 0, 5] } : null,
      this.selectedPrimaryKeywords ? { text: [{ text: 'Primary Keyword: ', bold: true, fontSize: 12, lineHeight: 1.5 }, { text: `${this.selectedPrimaryKeywords}`, color: '#626262' }], margin: [0, 0, 0, 5] } : null,
      this.selectedSecondaryKeyword && this.selectedSecondaryKeyword.length > 0 ? {
        stack: [
          { text: 'Secondary Keywords:', fontSize: 12, bold: true, lineHeight: 1.5, color: '#4f4f4f', margin: [0, 0, 0, 5] },
          { ul: this.selectedSecondaryKeyword.map(keyword => ({ text: keyword, fontSize: 12, color: '#626262' })), margin: [0, 0, 5, 5] }
        ]
      } : null,
      this.selectedMetaDescription ? { text: [{ text: 'Meta Description: ', bold: true, fontSize: 12, lineHeight: 1.5 }, { text: `${this.selectedMetaDescription}`, color: '#626262' }], margin: [0, 0, 0, 5] } : null,
      this.selectedWordCount ? { text: [{ text: 'Word Count: ', bold: true, fontSize: 12, lineHeight: 1.5 }, { text: `${this.selectedWordCount}`, color: '#626262' }], margin: [0, 0, 0, 5] } : null,
      this.selectedToneOfVoice ? { text: [{ text: 'Voice and Tone: ', bold: true, fontSize: 12, lineHeight: 1.5 }, { text: `${this.selectedToneOfVoice}`, color: '#626262' }], margin: [0, 0, 0, 5] } : null,
      this.selectedContentGoal ? { text: [{ text: 'Content Goal: ', bold: true, fontSize: 12, lineHeight: 1.5 }, { text: `${this.selectedContentGoal}`, color: '#626262' }], margin: [0, 0, 0, 5] } : null,
      this.selectedPointOfView ? { text: [{ text: 'Point of view: ', bold: true, fontSize: 12, lineHeight: 1.5 }, { text: `${this.selectedPointOfView}`, color: '#626262' }], margin: [0, 0, 0, 5] } : null,
      this.selectedBusinessObjective ? { text: [{ text: 'Business objective: ', bold: true, fontSize: 12, lineHeight: 1.5 }, { text: `${this.selectedBusinessObjective}`, color: '#626262' }], margin: [0, 0, 0, 5] } : null,
      this.selectedCTA ? { text: [{ text: 'Call to Action (CTA): ', bold: true, fontSize: 12, lineHeight: 1.5 }, { text: `${this.selectedCTA}`, color: '#626262' }], margin: [0, 0, 0, 5] } : null,
      this.selectedInternalLinks && this.selectedInternalLinks.length > 0 ? {
        stack: [
          { text: 'Links to Include:', fontSize: 12,  bold: true, lineHeight: 1.5, color: '#4f4f4f', margin: [0, 0, 0, 5] },
          { ul: this.selectedInternalLinks.map(internalLink => ({ text: internalLink, fontSize: 12, color: '#626262' })), margin: [0, 0, 5, 5] }
        ]
      } : null,
      this.selectedKeyPoints && this.selectedKeyPoints.length > 0 ? {
        stack: [
          { text: 'Key Points to Emphasize:', bold: true, fontSize: 12, lineHeight: 1.5, color: '#4f4f4f', margin: [0, 0, 0, 5] },
          { ul: this.selectedKeyPoints.map(keyPoint => ({ text: keyPoint, fontSize: 12, color: '#626262' })), margin: [0, 0, 5, 5] }
        ]
      } : null,
      this.selectedThingsToAvoid && this.selectedThingsToAvoid.length > 0 ? {
        stack: [
          { text: 'Things to Avoid:', bold: true, fontSize: 12, lineHeight: 1.5, color: '#4f4f4f', margin: [0, 0, 0, 5] },
          { ul: this.selectedThingsToAvoid.map(thingToAvoid => ({ text: thingToAvoid, fontSize: 12, color: '#626262' })), margin: [0, 0, 5, 5] }
        ]
      } : null,
      this.selectedAudienceInsights ? { text: [{ text: 'Target Audience: ', bold: true, fontSize: 12, lineHeight: 1.5 }, { text: `${this.selectedAudienceInsights}`, color: '#626262' }], margin: [0, 0, 0, 5] } : null,
      this.selectedSpecialInstructions && this.selectedSpecialInstructions.length > 0 ? {
        stack: [
          { text: 'Special Instructions:', bold: true, fontSize: 12, lineHeight: 1.5, color: '#4f4f4f', margin: [0, 0, 0, 5] },
          { ul: this.selectedSpecialInstructions.map(instruction => ({ text: instruction, fontSize: 12, color: '#626262' })), margin: [0, 0, 5, 5] }
        ]
      } : null,
      this.selectedSerpAnalysis ? { text: [{ text: 'Serp analysis: ', bold: true, fontSize: 12, lineHeight: 1.5 }, { text: `${this.selectedSerpAnalysis}`, color: '#626262' }], margin: [0, 0, 0, 5] } : null,
      this.selectedDifOpportunities && this.selectedDifOpportunities.length > 0 ? {
        stack: [
          { text: 'Differentiation Opportunities:', bold: true, fontSize: 12, lineHeight: 1.5, color: '#4f4f4f', margin: [0, 0, 0, 5] },
          { ul: this.selectedDifOpportunities.map(opportunity => ({ text: opportunity, fontSize: 12, color: '#626262' })), margin: [0, 0, 5, 5] }
        ]
      } : null,
      ...this.dynamicFields.map(field => ({ text: `${field.title}: ${field.content}`, bold: true, color: '#4f4f4f', margin: [0, 0, 0, 5], lineHeight: 1.5 })),
      { text: 'Content Outline:', bold: true, fontSize: 12, lineHeight: 1.5 , margin: [0, 0, 0, 10] },
      htmlToPdfmake(this.selectedOutlineContent, {
        window: window,
        defaultStyles: {
          h1: { fontSize: 24, bold: true, color: '#626262', margin: [0, 0, 0, 5] },
          h2: { fontSize: 14, bold: true, margin: [0, 0, 0, 5] },
          ul: { margin: [0, 0, 0, 5] },
          li: { fontSize: 11, margin: [0, 0, 0, 5] }
        }
      })
    ],
    defaultStyle: {
      fontSize: 12
    }
  };

  pdfMake.createPdf(docDefinition).download(this.selectedTitle.replace(/[/\\?%*:|"<>]/g, '').replace(/\s+/g, '_').toLowerCase() + '.pdf');
}*/
