export const Constants = {
  urls:
  {
    gatewayService:
    {
      login: 'auth/login',
    },
    accountsService: {
      service: 'accounts',
      comments : 'comments'
    },
  },
  auxiliary_texts:
  {
    chatBot:
    {
      chats: 'chats/stream',
      assistant_ws: '/chats/ws/dr_as/',
    },
    controls:
    {
      chat_history: '/chats/history/',
      get_profile_slug: '/get-profile-slug',
      version_control: '/chats/ver_control/',
      sessions_profile: '/sessions/by_profile/',
      favorite_session: '/sessions/favorite/',
      update_session_name: '/chats/sessions/',
      save_version: '/chats/ver_control/save',
    },
    updateSuggestions: {
      title: {
        messageTemplate: "Please update my title value to {}",
        loadingText: "Updating title data, please wait a bit",
        defaultValue: "Please suggest different titles based on the information we have."
      },
      primaryKeyword: {
        messageTemplate: "Please update my Primary keyword value to {}",
        loadingText: "Updating Primary keyword data, please wait a bit",
        defaultValue: "Please suggest different Primary keywords based on the information we have."
      },
      secondaryKeywords: {
        messageTemplate: "Update the values of secondary_keywords within the form to {}",
        loadingText: "Updating secondary keyword data, please wait a bit",
        defaultValue: "Please suggest different secondary keywords based on the information we have."
      },
      metaDescription: {
        messageTemplate: "Please update my meta description value to {}",
        loadingText: "Updating meta description data, please wait a bit",
        defaultValue: "Please suggest different meta descriptions based on the information we have."
      },
      wordCount: {
        messageTemplate: "Please update my word count value to {}",
        loadingText: "Updating word count data, please wait a bit",
        defaultValue: "Please suggest different word counts based on the information we have."
      },
      toneOfVoice: {
        messageTemplate: "Please update my Voice and Tone value to {}",
        loadingText: "Updating Voice and Tone data, please wait a bit",
        defaultValue: "Please suggest different Voice and Tones based on the information we have."
      },
      contentGoal: {
        messageTemplate: "Please update my content goal value to {}",
        loadingText: "Updating content goal data, please wait a bit",
        defaultValue: "Please suggest different content goals based on the information we have."
      },
      pointOfView: {
        messageTemplate: "Please update my point of view value to {}",
        loadingText: "Updating point of view data, please wait a bit",
        defaultValue: "Please suggest different point of views based on the information we have."
      },
      businessObjective: {
        messageTemplate: "Please update my business obkective value to {}",
        loadingText: "Updating business obkective data, please wait a bit",
        defaultValue: "Please suggest different business obkectives based on the information we have."
      },
      cta: {
        messageTemplate: "Please update my call to action value to {}",
        loadingText: "Updating call to action data, please wait a bit",
        defaultValue: "Please suggest different call to action based on the information we have."
      },
      internalLinks: {
        messageTemplate: "Please update my Links to Include value to {}",
        loadingText: "Updating Links to Include data, please wait a bit",
        defaultValue: "Please suggest different Links to Includes based on the information we have."
      },
      keyPoints: {
        messageTemplate: "Please update my key points value to {}",
        loadingText: "Updating key points data, please wait a bit",
        defaultValue: "Please suggest different key pointss based on the information we have."
      },
      thingsToAvoid: {
        messageTemplate: "Please update my things to avoid value to {}",
        loadingText: "Updating things to avoid data, please wait a bit",
        defaultValue: "Please suggest different things to avoids based on the information we have."
      },
      audience: {
        messageTemplate: "Please update my target_audience value to {}",
        loadingText: "Updating target audience data, please wait a bit",
        defaultValue: "Please suggest different options for target audience based on the information we have."
      },
      specialInstructions: {
        messageTemplate: "Please update my special instructions value to {}",
        loadingText: "Updating special instruction data, please wait a bit",
        defaultValue: "Please suggest different options for special instruction insights based on the information we have."
      },
      serpAnalysis: {
        messageTemplate: "Please update my serpAnalysis instructions value to {}",
        loadingText: "Updating serpAnalysis instruction data, please wait a bit",
        defaultValue: "Please suggest different options for serpAnalysis instruction insights based on the information we have."
      },
      differentiationOpportunities: {
        messageTemplate: "Please update my differentiationOpportunities instructions value to {}",
        loadingText: "Updating differentiationOpportunities instruction data, please wait a bit",
        defaultValue: "Please suggest different options for differentiationOpportunities instruction insights based on the information we have."
      },
    },    
  },
};
