<div class="brief-creator-card">

  <div class="actions-toolbar-container">
    <button *ngIf="hasContentToExport()" mat-button [matMenuTriggerFor]="exportMenu" class="button-export mt-2 mb-5">
      Export <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #exportMenu="matMenu">
      <button mat-menu-item (click)="newExportToWord()">
        <mat-icon>description</mat-icon>
        <span>Word</span>
      </button>
      <!-- <button mat-menu-item (click)="newExportToPdf()">
        <mat-icon>picture_as_pdf</mat-icon>
        <span>PDF</span>
      </button>-->
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="version" class="blue-button ms-2 mt-2 mb-5">
      Version <mat-icon>history</mat-icon>
    </button>
    <mat-menu #version="matMenu">
      <button mat-menu-item (click)="openSaveNewVersionDialog()">
        <mat-icon>save</mat-icon>
        <span>New Version</span>
      </button>
      <ng-container *ngFor="let item of versionData">
        <button mat-menu-item *ngIf="item.custom_name" (click)="updateFormWithVersion(item)">
          {{ item.custom_name }}
        </button>
      </ng-container>    
    </mat-menu>
    
    <button mat-button (click)="addDynamicField()" class="button-export ms-2 mt-2 mb-5">
      <span class="button-custom-field-text">+ Add Custom Field</span>
    </button>

    <button class="blue-button ms-2 mt-2 mb-5" mat-button (click)="goToPreviousSession()" *ngIf="currentSessionIndex > 0">
      <mat-icon>arrow_back</mat-icon>
      Return
    </button>

    <button class="blue-button ms-2 mt-2 mb-5" mat-button (click)="goToNextSession()" *ngIf="currentSessionIndex < sessionHistory.length - 1">
      <mat-icon>arrow_forward</mat-icon>
      Forward
    </button>
  </div>

  

  <!--<div class="tw-flex tw-justify-between">
      <div class="shrink-0 tw-flex">
        <button mat-button (click)="addDynamicField()" class="blue-button mt-2 mb-5">
          <span class="button-custom-field-text">+ Add Custom Field</span>
        </button>
      </div>

      <div class="tw-flex">
        <button mat-button [matMenuTriggerFor]="version" class="blue-button ms-2 mt-2 mb-5">
          Version <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #version="matMenu">
          <button mat-menu-item>Version 1</button>
          <button mat-menu-item>Version 2</button>
        </mat-menu>

        <button *ngIf="hasContentToExport()" mat-button [matMenuTriggerFor]="exportMenu" class="blue-button ms-2 mt-2 mb-5">
          Export <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #exportMenu="matMenu">
          <button mat-menu-item (click)="newExportToWord()">
            <mat-icon>description</mat-icon>
            <span>Word</span>
          </button>
          <button mat-menu-item (click)="newExportToPdf()">
            <mat-icon>picture_as_pdf</mat-icon>
            <span>PDF</span>
          </button>
        </mat-menu>
      </div>
  </div>-->
  
  <div id="container2"></div>
  <div class="form-details-display" #editableContainer id="editable-container">

    <!-- Title -->
    <div *ngIf="selectedTitle" contenteditable="true" id="title" class="tinymce-heading detail-item editable-container"
      (click)="selectSection($event, 'title')" (click)="openSuggestionsPanel('title')" (blur)="handleBlur($event, 'title')">
      <div class="detail-wrap m-1 mb-2 p-2">
        <h1>{{ selectedTitle }}</h1>
      </div>
      <!-- <mat-divider *ngIf="selectedTitle !== null && selectedTitle !== undefined" class="mb-2 mt-2"></mat-divider> -->
    </div>
    <!--mat-divider *ngIf="selectedTitle" class="ms-3 mb-2 mt-2"></mat-divider-->

    <!-- pre selection fields start -->
    <div *ngIf="brand" class="detail-item mt-3" contenteditable="true" id="brand">
      <div class="detail-wrap m-1 mb-2 p-2">
        <h4>Brand:</h4>
        <span>{{ brand }}</span>
      </div>
    </div>

    <div *ngIf="publishUrl" class="detail-item mt-3" contenteditable="true" id="publish-url">
      <div class="detail-wrap m-1 mb-2 p-2">
        <h4>Publish URL:</h4>
        <span>{{ publishUrl }}</span>
      </div>
    </div>

    <div *ngIf="contentType" class="detail-item mt-3" contenteditable="true" id="content-type">
      <div class="detail-wrap m-1 mb-2 p-2">
        <h4>Content Type:</h4>
        <span>{{ contentType }}</span>
      </div>
    </div>

    <div *ngIf="vertical" class="detail-item mt-3" contenteditable="true" id="vertical">
      <div class="detail-wrap m-1 mb-2 p-2">
        <h4>Vertical:</h4>
        <span>{{ vertical }}</span>
      </div>
    </div>
    <!-- pre selection fields end -->

    <!-- Primary Keyword -->
    <div *ngIf="selectedPrimaryKeywords" class="detail-item mt-3" contenteditable="true"
          id="primary-keyword"   (click)="selectSection($event, 'primary-keyword')" (click)="openSuggestionsPanel('primary-keyword')" (blur)="handleBlur($event, 'primary-keyword')">
      <div class="detail-wrap m-1 mb-2 p-2">
        <h4>Primary Keyword:</h4>
        <span>{{ selectedPrimaryKeywords }}</span>
      </div>
    </div>




    <!-- Secondary Keywords -->
    <div *ngIf="selectedSecondaryKeyword.length > 0" contenteditable="true" id="secondary-keywords"
      (click)="selectSection($event, 'secondary-keywords')" (click)="openSuggestionsPanel('secondary-keywords')" (blur)="handleBlur($event, 'secondary-keywords')"
      class="detail-item mt-3">
      <div class="detail-wrap m-1 mb-2 p-2">
        <h4>Secondary Keywords:</h4>
        <ul class="mb-0">
          <li *ngFor="let keyword of selectedSecondaryKeyword">{{ keyword }}</li>
        </ul>
      </div>
    </div>


    <!-- word Count -->
    <div *ngIf="selectedWordCount" class="detail-item mt-3" contenteditable="true" id="word-count"
      (click)="selectSection($event, 'word-count')" (click)="openSuggestionsPanel('word-count')">
      <div class="detail-wrap m-1 mb-2 p-2">
        <h4>Word Count:</h4> <span>{{ selectedWordCount }}</span>
        <mat-icon *ngIf="sectionsWithSuggestions.has('Word Count')">feedback</mat-icon>
        <mat-icon *ngIf="sectionsWithSuggestions.has('word-count')">feedback</mat-icon>
      </div>
    </div>

    <!-- Audience Insights -->
    <div *ngIf="selectedAudienceInsights" class="detail-item mt-3" contenteditable="true" id="audience-insights" (click)="selectSection($event, 'audience')"
      (click)="openSuggestionsPanel('audience')">
      <div class="detail-wrap m-1 mb-2 p-2">
        <h4>Target Audience:</h4> <span>{{ selectedAudienceInsights }}</span>
        <mat-icon *ngIf="sectionsWithSuggestions.has('audience')">feedback</mat-icon>
      </div>
    </div>


    <!-- voice_and_tone -->
    <div *ngIf="selectedToneOfVoice" class="detail-item mt-3" contenteditable="true" id="tone-of-voice"
      (click)="selectSection($event, 'tone-of-voice')" (click)="openSuggestionsPanel('tone-of-voice')">
      <div class="detail-wrap m-1 mb-2 p-2">
        <h4>Voice and Tone:</h4> <span>{{ selectedToneOfVoice }}</span>
        <mat-icon *ngIf="sectionsWithSuggestions.has('tone-of-voice')">feedback</mat-icon>
      </div>
    </div>

    <!-- content_objective -->
    <div *ngIf="selectedContentGoal" class="detail-item mt-3" contenteditable="true" id="content-goal"
      (click)="selectSection($event, 'content-goal')" (click)="openSuggestionsPanel('content-goal')">
      <div class="detail-wrap m-1 mb-2 p-2">
        <h4>Content Goal:</h4> <span>{{ selectedContentGoal }}</span>
        <mat-icon *ngIf="sectionsWithSuggestions.has('content-goal')">feedback</mat-icon>
      </div>
    </div>

    <!-- business objective -->
    <div *ngIf="selectedBusinessObjective" class="detail-item mt-3" contenteditable="true" id="business-objective"
      (click)="selectSection($event, 'business-objective')" (click)="openSuggestionsPanel('business-objective')">
      <div class="detail-wrap m-1 mb-2 p-2">
        <h4>Business objective:</h4> <span>{{ selectedBusinessObjective }}</span>
        <mat-icon *ngIf="sectionsWithSuggestions.has('business-objective')">feedback</mat-icon>
      </div>
    </div>

    <!-- CTA -->
    <div *ngIf="selectedCTA" class="detail-item mt-3" contenteditable="true" id="cta"
      (click)="selectSection($event, 'cta')" (click)="openSuggestionsPanel('cta')">
      <div class="detail-wrap m-1 mb-2 p-2">
        <h4>Call to Action (CTA):</h4> <span>{{ selectedCTA }}</span>
        <mat-icon *ngIf="sectionsWithSuggestions.has('cta')">feedback</mat-icon>
      </div>
    </div>



    <!-- internal links -->
    <div *ngIf="selectedInternalLinks.length > 0" contenteditable="true" id="internal-links"
      (click)="selectSection($event, 'internal-links')" (click)="openSuggestionsPanel('internal-links')"
      class="detail-item mt-3">
      <div class="detail-wrap m-1 mb-2 p-2">
        <h4>Links to Include:</h4>
        <ul class="mb-0">
          <li *ngFor="let internalLinks of selectedInternalLinks">{{ internalLinks }}</li>
        </ul>
      </div>
    </div>

    <!-- Key Points to Emphasize -->
    <div *ngIf="selectedKeyPoints.length > 0" contenteditable="true" id="key-points"
      (click)="selectSection($event, 'key-points')" (click)="openSuggestionsPanel('key-points')"
      class="detail-item mt-3">
      <div class="detail-wrap m-1 mb-2 p-2">
        <h4>Key Points to Emphasize:</h4>
        <ul class="mb-0">
          <li *ngFor="let keyPoints of selectedKeyPoints">{{ keyPoints }}</li>
        </ul>
      </div>
    </div>

    <!-- Things to Avoid -->
    <div *ngIf="selectedThingsToAvoid.length > 0" contenteditable="true" id="things-to-avoid"
      (click)="selectSection($event, 'things-to-avoid')" (click)="openSuggestionsPanel('things-to-avoid')"
      class="detail-item mt-3">
      <div class="detail-wrap m-1 mb-2 p-2">
        <h4>Things to Avoid:</h4>
        <ul class="mb-0">
          <li *ngFor="let thingsToAvoid of selectedThingsToAvoid">{{ thingsToAvoid }}</li>
        </ul>
      </div>
    </div>

    <!-- point of view -->
    <div *ngIf="selectedPointOfView" class="detail-item mt-3" contenteditable="true" id="point-of-view"
      (click)="selectSection($event, 'point-of-view')" (click)="openSuggestionsPanel('point-of-view')">
      <div class="detail-wrap m-1 mb-2 p-2">
        <h4>Point of view:</h4> <span>{{ selectedPointOfView }}</span>
      </div>
    </div>


    <!-- special_instructions -->
    <div *ngIf="selectedSpecialInstructions.length > 0 " class="detail-item mt-3" contenteditable="true"
      id="special-instructions" (click)="selectSection($event, 'special-instructions')"
      (click)="openSuggestionsPanel('special-instructions')">
      <div class="detail-wrap m-1 mb-2 p-2">
        <h4>Special Instructions:</h4> <span>{{ selectedSpecialInstructions }}</span>
      </div>
    </div>

    <!-- serp_analysis -->
    <div *ngIf="selectedSerpAnalysis" class="detail-item mt-3" contenteditable="true" id="serp-analysis"
      (click)="selectSection($event, 'serp-analysis')" (click)="openSuggestionsPanel('serp-analysis')">
      <div class="detail-wrap m-1 mb-2 p-2">
        <h4>Serp analysis:</h4> <span>{{ selectedSerpAnalysis }}</span>
      </div>
    </div>

    <!-- differentiation_opportunities -->
    <div *ngIf="selectedDifOpportunities.length > 0" class="detail-item mt-3" contenteditable="true"
      id="differentiation-opportunities" (click)="selectSection($event, 'differentiation-opportunities')"
      (click)="openSuggestionsPanel('differentiation-opportunities')">
      <div class="detail-wrap m-1 mb-2 p-2">
        <h4>Differentiation opportunities:</h4> <span>{{ selectedDifOpportunities }}</span>
      </div>
    </div>

    <!-- Meta Description -->
    <div *ngIf="selectedMetaDescription" class="detail-item mt-3" contenteditable="true" id="meta-description"
      (click)="selectSection($event, 'meta-description')" (click)="openSuggestionsPanel('meta-description')">
      <div class="detail-wrap m-1 mb-2 p-2">
        <h4>Meta Description:</h4> <span>{{ selectedMetaDescription }}</span>
        <mat-icon *ngIf="sectionsWithSuggestions.has('Meta Description')">feedback</mat-icon>
        <mat-icon *ngIf="sectionsWithSuggestions.has('meta-description')">feedback</mat-icon>
      </div>
    </div>

    <!-- content_outline -->
    <div *ngIf="selectedOutlineContent" class="detail-item mt-3" contenteditable="true" id="content-outline"
      (click)="selectSection($event, 'content-outline')">
      <div class="detail-wrap m-1 mb-2 p-2">
        <h4>Content Outline:</h4> <span [innerHTML]="selectedOutlineContent"></span>
      </div>
    </div>

    <!-- custom fields -->
    <div *ngFor="let field of dynamicFields" id="{{ field.id }}" class="detail-item mt-3" contenteditable="true">
      <div class="detail-wrap m-1 mb-2 p-2">
        <h4>{{ field.title }}:</h4> 
        <span>{{ field.content }}</span>
      </div>
    </div>    


    <button (click)="addDynamicField()" class="add-field-btn">
      <span class="button-text">+ Add Custom Field</span>
    </button>

    <div #scrollTarget></div>
    
    


    <!-- Outline -->
    <!-- <div *ngIf="outlineContent" class="outline-section mt-3">
      <h3 class="outline-title">Outline</h3>
      <div class="detail-item mt-3" contenteditable="true" id="editable-outline">
        <span>{{ outlineContent }}</span>
      </div>
    </div> -->
  </div>
</div>