import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService
{
  cookieService = inject(SsrCookieService);
  private readonly TOKEN_KEY = 'cc_token';

  constructor(private http: HttpClient) {}

  exLogin(token: string) {
    this.cookieService.set(this.TOKEN_KEY, token);
    return of({ success: true });
  }

  getToken(): string | null {
    return this.cookieService.get(this.TOKEN_KEY);
  }

  clearToken(): void {
    this.cookieService.delete(this.TOKEN_KEY);
  }
}


