import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AssistantForm } from '../models/brief-assistant-model';

@Injectable({
  providedIn: 'root'
})
export class SharedDataChatService {
  // private formDetailsSource = new BehaviorSubject<AssistantForm | null>(null);
  // formDetails$ = this.formDetailsSource.asObservable();

  constructor() { }

  copyText(text: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text)
          .then(() => resolve())
          .catch(err => reject(err));
      } else {
        console.error('The copy to clipboard function is not supported in this browser');
        reject('Clipboard not supported');
      }
    });
  }
}
