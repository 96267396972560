<div class="outline-wrapper me-3 mb-4 pe-2">
  <div>
  <div *ngIf="scoring && !isReloadingSuggestions" class="scoring-section">
    <div class="scoring-circle position-relative">
      <circle-progress 
        [percent]="scoring"
        [outerStrokeColor]="circleColor.outerStrokeColor"
        [outerStrokeGradientStopColor]="circleColor.outerStrokeGradientStopColor">
      </circle-progress>
      <div class="scoring-value">{{ scoring }}</div> 
    </div>
    <div class="scoring-text">
      <strong>{{ getScoringText() }}</strong>
    </div>
    <div class="refresh-icon" (click)="refreshSuggestions()" matTooltip="Refresh suggestions">
      <i class="material-icons">refresh</i>
    </div>
  </div>
  
  <div class="tab-titles">
    <div class="tab-title" [class.active]="selectedTab === 'outline'" (click)="selectTab('outline')">Outline</div>
    <div class="tab-title" [class.active]="selectedTab === 'suggestions'" (click)="selectTab('suggestions')">
      <ng-container *ngIf="loadingSuggestions  || isReloadingSuggestions; else normalContent">
        <div class="loading-spinner">
          <span class="spinner-container">
            <mat-spinner diameter="20"></mat-spinner>
          </span>
          <span class="loading-text">Loading feedback...</span>
        </div>
      </ng-container>
      <ng-template #normalContent>
        Feedback
        <span *ngIf="suggestions?.length" class="suggestions-count">{{ suggestions.length }}</span>
      </ng-template>      
    </div>
       
  </div>
  
  <div class="tab-content">
    <ul *ngIf="selectedTab === 'outline'" class="outline-list">
      <li *ngFor="let item of outlineItems; let i = index"
      [class.selected]="item.selected"
      (mouseenter)="item.hover=true" 
      (mouseleave)="item.hover=false"
      (click)="toggleSelection(item)">
      {{ item.text }} <span *ngIf="item.content">{{ item.content }}</span>
      </li>
    </ul>
    <ul *ngIf="selectedTab === 'suggestions' && !isReloadingSuggestions" class="suggestions-list">
      <ng-container *ngFor="let suggestion of suggestions">
        <li *ngIf="suggestion.section && suggestion.recommendation" class="suggestion-item mb-3"
            [class.selected]="suggestion.selected"
            (click)="toggleSuggestionSelection(suggestion)">
          <img [src]="getIntentionImage(suggestion.intention)" [alt]="suggestion.intention + ' icon'" class="suggestion-icon">
          <div class="suggestion-text">
            <strong>{{ suggestion.section }}</strong>: {{ suggestion.recommendation }}
            <span class="custom-chip" [ngClass]="getIntentionClass(suggestion.intention)">
              {{ suggestion.intention }}
            </span>
          </div>
        </li>
      </ng-container>
    </ul>           
  </div>
</div>
