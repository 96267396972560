import { bootstrapApplication, createApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { ApplicationRef } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { CommentsComponent } from './app/comments/comments.component';
import { WcAssistantsDashboardComponent } from './app/ai-assistants/wc-assistants-dashboard/wc-assistants-dashboard.component';

 bootstrapApplication(AppComponent, appConfig)
   .catch((err) => console.error(err));


(async () => {
  const app: ApplicationRef = await createApplication(appConfig);
  const CommentsComp = createCustomElement(CommentsComponent, {injector: app.injector});
  const briefCreatorComp = createCustomElement(WcAssistantsDashboardComponent, {injector: app.injector});
  customElements.define('comments-widget', CommentsComp);
  customElements.define('brief-creator-widget', briefCreatorComp);
})();
