<div class="main-container">
    <app-chat-panel 
        #chatPanel 
        [showContent]="showAdditionalContent" 
        [profileSlug]="profileSlug" 
        (chatHistoryLoaded)="handleChatHistoryLoaded($event)" 
        (scoringFeedbacksLoaded)="handleScoringFeedbacks($event)" 
        (contentOutlineLoaded)="handleContentOutline($event)"
        (highlightedSessionsLoaded)="handleHighlightedSessionsLoaded($event)"  
        (returnInitiated)="handleReturnFromChatPanel()"
        (newChatInitiated)="startNewChat($event)" 
        (sessionIdLoaded)="handleSessionIdLoaded($event)"
        >
    </app-chat-panel>


    <div *ngIf="showAdditionalContent" class="assistant-container text-center">
        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6ZkDeysKs8rwoOKoY8aZdewcgB8odjL4Ypg&usqp=CAU"
            alt="Icon" class="mx-auto d-block mb-2">
        <div class="widget-container">
            <div class="widget-title">Favorites and Latest Briefs </div>
            <div *ngFor="let session of highlightedSessions" class="widget" (click)="handleSessionSelected(session.id)">
                <div class="widget-content">
                  <mat-icon class="favorite-icon" [class.widget-icon-favorite]="session.is_favorite">star</mat-icon>
                  <div class="session-date">
                    <ng-container *ngIf="session.custom_name; else createdAt">{{ session.custom_name }}</ng-container>
                    <ng-template #createdAt>{{ session.created_at }}</ng-template>
                  </div>
                </div>
              </div>              
        </div>
        <div class="widget-title mb-4 mt-4">Click to Start </div>
        <div class="d-grid gap-2 d-md-flex justify-content-center flex-wrap">
            <button mat-raised-button (click)="startNewChat('bb3')" class="btn btn-assistant mx-2 mb-2">Create a brief</button>
        </div>
    </div>
</div>