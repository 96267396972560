import { Routes } from '@angular/router';
import { CommentsComponent } from './comments/comments.component';
import { ChatBotComponent } from './ai-assistants/chat-bot/chat-bot.component';
import { ChatAssistDashboardComponent } from './ai-assistants/chat-assist-dashboard/chat-assist-dashboard.component';
import { WcAssistantsDashboardComponent } from './ai-assistants/wc-assistants-dashboard/wc-assistants-dashboard.component';

export const routes: Routes =
[
  { path: 'comments/test', component: CommentsComponent },
  { path: 'chat_bot', component: ChatBotComponent },
  { path: 'chat_dashboard', component: WcAssistantsDashboardComponent },
  { path: 'wc_chat_dashboard', component: WcAssistantsDashboardComponent },
];
