import { Component, Input, SimpleChanges, EventEmitter, Output, ChangeDetectorRef, inject, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { UnusedOptions } from '../chat-bot/models/brief-assistant-model';
import {MatRadioModule} from '@angular/material/radio';
import {TooltipPosition, MatTooltipModule} from '@angular/material/tooltip';
import {FormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { AssistantManagerService } from '../chat-bot/services/assistant-manager-service.service';
import { SuggestionUpdate } from '../chat-bot/models/suggestions-update';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {MatDividerModule} from '@angular/material/divider';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { SharedDataChatService } from '../chat-bot/services/shared-data-chat.service';
import { ProgressIndicatorsComponent } from '../progress-indicators/progress-indicators.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { environment } from 'src/environments/environment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

interface OutlineItem {
  text: string;
  selected: boolean;
  hover: boolean;
  content?: string; 
}

@Component({
  selector: 'app-suggestions-panel',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatListModule, MatButtonModule, MatCardModule, MatExpansionModule, MatRadioModule, FormsModule, MatCheckboxModule, MatTooltipModule, MatDividerModule, MatSnackBarModule,  ProgressIndicatorsComponent, MatProgressBarModule,MatProgressSpinnerModule ],
  providers: [AssistantManagerService, SharedDataChatService],
  templateUrl: './suggestions-panel.component.html',
  styleUrl: './suggestions-panel.component.sass'
})
export class SuggestionsPanelComponent {
  chatService = inject(AssistantManagerService);
  sharedDataService = inject(SharedDataChatService);
  @Input() title: string = '';
  @Input() unusedOptions: UnusedOptions;
  @Input() currentStep: string  = '';
  @Input() currentStepSuggestions: string[] = [];
  @Input() selectedTitle: string;
  @Input() reciveSelectedPrimaryKeywords: string;
  @Input() reciveSelectedSecondaryKeywords: string[];
  @Input() reciveSelectedMetaDescription: string;
  @Input() reciveSelectedWordCount: string;
  @Input() reciveSelectedToneOfVoice: string;
  @Input() reciveSelectedContentGoal: string;
  @Input() reciveSelectedPov: string;
  @Input() reciveSelectedBusinessObjective: string;
  @Input() reciveSelectedCta: string;
  @Input() reciveSelectedInternalLinks: string[];
  @Input() reciveSelectedKeyPoints: string[];
  @Input() reciveSelectedThingsToAvoid: string[];
  @Input() reciveSelectedAudience: string;
  @Input() reciveSelectedSpecialInstructions: string[];
  @Input() reciveSelectedSerpAnalysis: string;
  @Input() reciveSelectedOpportunities: string[];
  @Input() isPanelOpen: boolean = false;
  @Input() currentSelectedSectionId: string = '';



  @Output() refreshRequested = new EventEmitter<void>();
  @Output() titleChanged = new EventEmitter<string>();
  @Output() primaryKeywordChanged = new EventEmitter<string>();
  @Output() updateRequested = new EventEmitter<SuggestionUpdate>();
  savedTitles: string[] = [];
  savedPrimaryKeywords: string[] = [];
  savedSecondaryKeywords: string[] = [];
  savedMetaDescription: string[] = [];
  savedWordCount: string[] = [];
  savedToneOfVoice: string[] = [];
  savedContentGoal: string[] = [];
  savedPov: string[] = [];
  savedBusinessObjective: string[] = [];
  saveddCta: string[] = [];
  savedInternalLinks: string[] = [];
  savedKeyPoints: string[] = [];
  savedThingsToAvoid: string[] = [];
  savedAudienceInsights: string[] = [];
  savedSpecialInstructions: string[] = [];
  savedSerpAnalysis: string[] = [];
  savedOpportunities: string[] = [];
  selectedPrimaryKeywords: { [keyword: string]: boolean } = {};
  selectedSecondaryKeywords: { [keyword: string]: boolean } = {};
  public newSuggestionsloading: boolean = false
  //progress indicators
  isProgressLoading: boolean = true;
  selectedOptions = new Set<string>();
  // progressTasks = [
  //   { name: "Upload title", status: "pending" },
  //   { name: "Upload primary keyword", status: "queued" },
  //   { name: "Upload secondary keywords", status: "queued" }
  // ];
  progressTasks = [];
  outlineItems: OutlineItem[] = [];
  frontendServURL = environment.frontendServURL;
  loadingAlternatives = {
    title: false,
    primaryKeyword: false,
    secondaryKeywords: false,
    metaDescription: false,
    wordCount: false,
    voiceAndTone: false,
    contentGoal: false,
    pointOfView: false,
    businessObjective: false,
    cta: false,
    internalLinks: false,
    keyPoints: false,
    thingsToAvoid: false,
    targetAudience: false,
    specialInstructions: false,
    serpAnalysis: false,
    differentiationOpportunities: false
  };
  


  testTitle: string[] = [
    'Light (high-level intro on the subject): 600-700 words',
    'Moderate (intermediate depth with some insights and detail): 1,000-1,200 words',
    'Deep Dive (thorough, in-depth review of the subject): 1,800-2,200 words'
  ];


  constructor(private cdr: ChangeDetectorRef, public dialog: MatDialog, public breakpointObserver: BreakpointObserver, private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    // this.simulateTaskCompletion();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentStepSuggestions']) {
      this.updateSuggestionsBasedOnCurrentStep();
      this.cdr.detectChanges(); 
      
    }
    if (changes['isOpenTitles'] || changes['isOpenMainKeywords'] || changes['isOpenSecondaryKeywords'] || changes['isOpenMetaDescription']  || changes['isOpenWordCount'] || changes['isOpenCta'] || changes['isOpenAudience'] ) {
      this.cdr.detectChanges();
    }

    if (changes['currentSelectedSectionId']) {     
    }

    if (changes['selectedTitle'] && changes['selectedTitle'].currentValue) {
      const newItem = { text: 'Title', selected: false, hover: false };
      this.addOutlineItem(newItem);
    }

  }
  

  updateSuggestionsBasedOnCurrentStep(): void {
    if (this.currentStepSuggestions && this.currentStepSuggestions.length > 0) {
      // update alternatives based on step
      //console.log("updateSuggestionsBasedOnCurrentStep", this.currentStepSuggestions);
      switch (this.currentStep) {
        case 'title':
          this.savedTitles = [...this.currentStepSuggestions];
          console.log("Title", this.savedTitles);
          this.setLoading('title', false);
          break;
        case 'keyword_step_primary':
        case 'main_keyword':
        case 'primary_keyword':
          this.savedPrimaryKeywords = [...this.currentStepSuggestions];
          console.log("savedPrimaryKeywords", this.savedPrimaryKeywords);
          this.setLoading('primaryKeyword', false);
          break;
        case 'keyword_step_secondary':
        case 'secondary_keyword':
        case 'secondary_keywords':
          this.savedSecondaryKeywords = [...this.currentStepSuggestions];
          this.setLoading('secondaryKeywords', false);
          break;
        case 'meta_description':
          this.savedMetaDescription = [...this.currentStepSuggestions];
          this.setLoading('metaDescription', false);
          break;
        case 'word_count':
          this.savedWordCount = [...this.currentStepSuggestions];
          this.setLoading('wordCount', false);
          break;
        case 'voice_and_tone':
        case 'tone_of_voice':
          this.savedToneOfVoice = [...this.currentStepSuggestions];
          this.setLoading('toneOfVoice', false);
          break;
        case 'content_goal':
          this.savedContentGoal = [...this.currentStepSuggestions];
          this.setLoading('contentGoal', false);
          break;
        case 'point_of_view':
          this.savedPov = [...this.currentStepSuggestions];
          this.setLoading('pointOfView', false);
          break;
        case 'business_objective':
          this.savedBusinessObjective = [...this.currentStepSuggestions];
          this.setLoading('businessObjective', false);
          break;
        case 'cta':
        case 'call_to_action':
          this.saveddCta = [...this.currentStepSuggestions];
          this.setLoading('cta', false);
          break;
        case 'internal_links':
          this.savedInternalLinks = [...this.currentStepSuggestions];
          this.setLoading('internalLinks', false);
          break;
        case 'audience':
        case 'audience_insights':
        case 'target_audience':
          this.savedAudienceInsights = [...this.currentStepSuggestions];
          this.setLoading('audience', false);
          break;
        case 'special_instructions':
          this.savedSpecialInstructions = [...this.currentStepSuggestions];
          this.setLoading('specialInstructions', false);
          break;
        case 'key_points_to_emphasize':
          this.savedKeyPoints = [...this.currentStepSuggestions];
          this.setLoading('keyPoints', false);
          break;
        case 'things_to_avoid':
          this.savedThingsToAvoid = [...this.currentStepSuggestions];
          this.setLoading('thingsToAvoid', false);
          break;
        case 'serp_analysis':
          this.savedSerpAnalysis = [...this.currentStepSuggestions];
          this.setLoading('serpAnalysis', false);
          break;
        case 'differentiation_opportunities':
          this.savedOpportunities = [...this.currentStepSuggestions];
          this.setLoading('differentiationOpportunities', false);
          break;
        default:
          console.log("No such step to update:", this.currentStep);
          break;
      }
    }
  }
  
  
  toggleSecondaryKeywordSelection(keyword: string): void {
    this.selectedSecondaryKeywords[keyword] = !this.selectedSecondaryKeywords[keyword];
  }

  confirmSuggestionsChange(type: string, value: any, updateType?: any): void {
    this.updateRequested.emit({ type, value, updateType });
  }

  
  onRefreshClick(type: string,  value?: any, updateType?: string): void {
    this.updateRequested.emit({ type, value, updateType }); 
  }


  showSnackbar(message: string, action: string = 'OK', duration: number = 3000, className: string = '') {
    this.snackBar.open(message, action, {
      duration: duration,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: [className] 
    });
  }

  copyText(event: MouseEvent, text: string): void {
    event.stopPropagation(); // Prevent the click event from propagating
    this.sharedDataService.copyText(text)
      .then(() => {
        // handle success
        this.showSnackbar("Text copied successfully", 'OK', 3000, 'success-snackbar');
      })
      .catch(err => {
        // Handle error
        this.showSnackbar("Unable to copy text"); 
      });
  }

  
  updateTaskStatus(taskName: string, status: 'queued' | 'processing' | 'done') {
    const taskIndex = this.progressTasks.findIndex(task => task.name === taskName);
    if (taskIndex !== -1) {
      this.progressTasks[taskIndex].status = status;
      this.progressTasks = [...this.progressTasks];
      this.cdr.detectChanges(); 
    }
  }
  
  checkAllTasksCompleted() {
    const allDone = this.progressTasks.every(task => task.status === 'done');
    this.isProgressLoading = !allDone;
  }


  toggleSelection(selectedItem: any) {
    this.outlineItems.forEach(item => item.selected = false);
    selectedItem.selected = true;
  }

  removeFromOutline(index: number) {
    this.outlineItems.splice(index, 1);
    this.outlineItems.forEach(item => item.selected = false);
  }

  addOutlineItem(newItem: { text: string, selected: boolean, hover: boolean }): void {
    const exists = this.outlineItems.some(item => item.text === newItem.text);
    if (!exists) {
      this.outlineItems.push(newItem);
    }
  }

  toggleSecondaryKeywordsSelection(secondaryKeyword: string): void {
    const index = this.reciveSelectedSecondaryKeywords.indexOf(secondaryKeyword);
    if (index > -1) {
        this.reciveSelectedSecondaryKeywords.splice(index, 1);
    } else {
        this.reciveSelectedSecondaryKeywords.push(secondaryKeyword);
    }
    this.reciveSelectedSecondaryKeywords = [...this.reciveSelectedSecondaryKeywords];
  }
  
  isSecondaryKeywordsSelected(option: string): boolean {
    return this.reciveSelectedSecondaryKeywords.includes(option);
  }

  toggleSpecialInstructionsSelection(specialInstructions: string): void {
    const index = this.reciveSelectedSpecialInstructions.indexOf(specialInstructions);
    if (index > -1) {
        this.reciveSelectedSpecialInstructions.splice(index, 1);
    } else {
        this.reciveSelectedSpecialInstructions.push(specialInstructions);
    }
    this.reciveSelectedSpecialInstructions = [...this.reciveSelectedSpecialInstructions];
  }
   
  isSpecialInstructionsSelected(option: string): boolean {
    return this.reciveSelectedSpecialInstructions.includes(option);
  }

  toggleDifferentationOppotunitiesSelection(differentationOppotunities: string): void {
    const index = this.reciveSelectedOpportunities.indexOf(differentationOppotunities);
    if (index > -1) {
        this.reciveSelectedOpportunities.splice(index, 1);
    } else {
        this.reciveSelectedOpportunities.push(differentationOppotunities);
    }
    this.reciveSelectedOpportunities = [...this.reciveSelectedOpportunities];
  }

  toggleInternalLinksSelection(internalLinks: string): void {
    const index = this.reciveSelectedInternalLinks.indexOf(internalLinks);
    if (index > -1) {
        this.reciveSelectedInternalLinks.splice(index, 1);
    } else {
        this.reciveSelectedInternalLinks.push(internalLinks);
    }
    this.reciveSelectedInternalLinks = [...this.reciveSelectedInternalLinks];
  }

  toggleKeyPointsSelection(keyPoints: string): void {
    const index = this.reciveSelectedKeyPoints.indexOf(keyPoints);
    if (index > -1) {
        this.reciveSelectedKeyPoints.splice(index, 1);
    } else {
        this.reciveSelectedKeyPoints.push(keyPoints);
    }
    this.reciveSelectedKeyPoints = [...this.reciveSelectedKeyPoints];
  }

  toggleThingsToAvoidSelection(thingsToAvoid: string): void {
    const index = this.reciveSelectedThingsToAvoid.indexOf(thingsToAvoid);
    if (index > -1) {
        this.reciveSelectedThingsToAvoid.splice(index, 1);
    } else {
        this.reciveSelectedThingsToAvoid.push(thingsToAvoid);
    }
    this.reciveSelectedThingsToAvoid = [...this.reciveSelectedThingsToAvoid];
  }
  
  
  
  isInternalLinksSelected(internalLinks: string): boolean {
    return this.reciveSelectedInternalLinks.includes(internalLinks);
  }

  isKeyPointsSelected(keyPoints: string): boolean {
    return this.reciveSelectedKeyPoints.includes(keyPoints);
  }

  isThingsToAvoidSelected(keyPoints: string): boolean {
    return this.reciveSelectedKeyPoints.includes(keyPoints);
  }


  
  isDifferentationOppotunitiesSelected(option: string): boolean {
    return this.reciveSelectedOpportunities.includes(option);
  }
  
  sendSelectedSecondaryOptions(optionType: string, updateType: string): void {
    const selectedOptionsArray = [...this.reciveSelectedSecondaryKeywords];
    this.confirmSuggestionsChange(optionType, selectedOptionsArray, updateType);
}

  sendSelectedSpecialInstructionsOptions(optionType: string, updateType: string): void {
    const selectedOptionsArray = [...this.reciveSelectedSpecialInstructions];
    this.confirmSuggestionsChange(optionType, selectedOptionsArray, updateType);
  }

  sendSelecteddifferentationOppotunitiesOptions(optionType: string, updateType: string): void {
    const selectedOptionsArray = [...this.reciveSelectedOpportunities];
    this.confirmSuggestionsChange(optionType, selectedOptionsArray, updateType);
  }

  sendSelectedInternalLinksOptions(optionType: string, updateType: string): void {
    const selectedOptionsArray = [...this.reciveSelectedInternalLinks];
    this.confirmSuggestionsChange(optionType, selectedOptionsArray, updateType);
  }

  sendSelectedKeyPointsOptions(optionType: string, updateType: string): void {
    const selectedOptionsArray = [...this.reciveSelectedKeyPoints];
    this.confirmSuggestionsChange(optionType, selectedOptionsArray, updateType);
  }

  sendSelectedThingsToAvoidOptions(optionType: string, updateType: string): void {
    const selectedOptionsArray = [...this.reciveSelectedThingsToAvoid];
    this.confirmSuggestionsChange(optionType, selectedOptionsArray, updateType);
  }


  // update loading alternatives state
  setLoading(section: string, state: boolean) {
    if (this.loadingAlternatives.hasOwnProperty(section)) {
      this.loadingAlternatives[section] = state;
    }
  }

  handleUpdateRequested(update: SuggestionUpdate) {
    this.setLoading(update.type, true);
  }

  /*Leave commented for now
  hasDataForSection(sectionId: string): boolean {
    switch (sectionId) {
      case 'title':
        return this.savedTitles.length > 0;
      case 'primary-keyword':
        return this.savedPrimaryKeywords.length > 0;
      case 'secondary-keywords':
        return this.savedSecondaryKeywords.length > 0;
      case 'meta-description':
        return this.savedMetaDescription.length > 0;
      case 'word-count':
        return this.savedWordCount.length > 0;
      case 'tone-of-voice':
        return this.savedToneOfVoice.length > 0;
      case 'content-goal':
        return this.savedContentGoal.length > 0;
      case 'point-of-view':
        return this.savedPov.length > 0;
      case 'business-objective':
        return this.savedBusinessObjective.length > 0;
      case 'cta':
        return this.saveddCta.length > 0;
      case 'internal-links':
        return this.savedInternalLinks.length > 0;
      case 'key-points':
        return this.savedKeyPoints.length > 0;
      case 'things-to-avoid':
        return this.savedThingsToAvoid.length > 0;
      case 'audience':
        return this.savedAudienceInsights.length > 0;
      case 'special-instructions':
        return this.savedSpecialInstructions.length > 0;
      case 'serp-analysis':
        return this.savedSerpAnalysis.length > 0;
      case 'differentiation-opportunities':
        return this.savedOpportunities.length > 0;
      default:
        return false;
    }
  }*/
  
}
