import { Injectable, Inject, PLATFORM_ID, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject, Subject, catchError, map, take, tap, throwError } from 'rxjs';'@angular/core';
import { ChatMessageRespnse } from '../models/chat-message-model';
import { Constants } from 'src/app/_shared/constants';
import { isPlatformBrowser } from '@angular/common';
import { SuggestionUpdate } from '../models/suggestions-update';
import { NgZone } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataObject } from '../models/version-control-model';
import { differenceInDays } from 'date-fns';
import { SessionResponse, Session } from '../models/briefs-sessions-model';

import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/_shared/services/auth-service';
import { SpinnerHandlerService } from 'src/app/_shared/services/spinner-handler.service';


@Injectable({
  providedIn: 'root'
})
export class AssistantManagerService {
  private assistantId: string = '';
  private includeParams: boolean = true;
  private reconnectAttempts = 0;
  private reconnectMaxAttempts = 3;
  public currentThreadId: string | null = null;
  private webSocket!: WebSocket;
  private messagesSubject = new ReplaySubject<any>(1);
  public messages$ = this.messagesSubject.asObservable();
  private currentThreadIdSource = new BehaviorSubject<string | null>(null);
  public currentThreadId$ = this.currentThreadIdSource.asObservable();
  private isWebSocketConnected = false;
  private webSocketConnectionAttemptInProgress = false; 
  
  //suggestions changes
  private titleSubject = new BehaviorSubject<string>('');
  private primaryKeywordSubject = new BehaviorSubject<string>('');
  private secondaryKeywordsSubject = new BehaviorSubject<string[]>([]);
  private metaDescriptionnSubject = new BehaviorSubject<string>('');
  private refreshRequestedSubject = new Subject<void>();

  title$ = this.titleSubject.asObservable();
  primaryKeyword$ = this.primaryKeywordSubject.asObservable();
  secondaryKeywords$ = this.secondaryKeywordsSubject.asObservable();
  metaDescriptionKeyword$ = this.metaDescriptionnSubject.asObservable();
  refreshRequested$ = this.refreshRequestedSubject.asObservable();
  private tasksSubject = new BehaviorSubject<SuggestionUpdate[]>([]);
  public tasks$: Observable<SuggestionUpdate[]> = this.tasksSubject.asObservable();
  private lastUserMessage: string | null = null;
  private resendCount: number = 0;  
  private lastFormData: any;
  private unexpectedClosure = false;
  private profileSlugSource = new BehaviorSubject<string | null>(null);
  profileSlug$ = this.profileSlugSource.asObservable();
  private receivedInitialMessage: boolean = false;
  private connectionOpenedSubject = new Subject<void>();
  public connectionOpened$ = this.connectionOpenedSubject.asObservable(); 
  public scrollToBottomRequested = new EventEmitter<void>();


  type: 'title' | 'primaryKeyword' | 'secondaryKeywords' | 'metaDescription';
  value: string | string[]; // String for title and primary, array for secondary



  constructor(private http: HttpClient, private spinnerHandler: SpinnerHandlerService, private ngZone: NgZone, @Inject(PLATFORM_ID) private platformId: Object, private authService: AuthService) {}

  private convertToWebSocketUrl(url: string): string {
    return url.replace(/^http(s?):\/\//, 'ws$1://');
  }
  
  private getFullWebSocketUrl(sessionId?: string): string {
    const token = this.authService.getToken();
    const baseWsUrl = this.convertToWebSocketUrl(environment.aiToolsService) + Constants.auxiliary_texts.chatBot.assistant_ws;
    console.log("getFullWebSocketUrl", baseWsUrl);
    console.log("includeParams getFullWebSocketUrl", this.includeParams);
    if (this.includeParams) {
      const params = new URLSearchParams({
        store_chat_history: 'true',
        ver_control_obj: 'form',
        token: token ?? ''
      });
  
      if (sessionId) {
        params.append('session_id', sessionId);
      }
  
      return `${baseWsUrl}${this.assistantId}?${params.toString()}`;
    } else {
      return `${baseWsUrl}${this.assistantId}`;
    }
  }
  

  

  connect(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const token = this.authService.getToken();
    if (!token) {
      console.error('Auth token is not set. Cannot connect to WebSocket.');
      return;
    }

    const fullWebSocketUrl = this.getFullWebSocketUrl();
    this.webSocket = new WebSocket(fullWebSocketUrl);

    this.webSocket.onopen = () => {
      this.reconnectAttempts = 0;
      this.isWebSocketConnected = true;
      this.connectionOpenedSubject.next()
    };

    this.webSocket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      this.ngZone.run(() => {
        this.messagesSubject.next(message);
      });
    };

    this.webSocket.onerror = (event) => {
      console.error('WebSocket error:', event);
      this.ngZone.run(() => {
        this.messagesSubject.error('WebSocket connection error.');
      });
    };

    this.webSocket.onclose = (event) => {
      this.isWebSocketConnected = false;
      if (!event.wasClean) {
        console.error('WebSocket connection was closed unexpectedly.');
        this.reconnect();
      }
    };
  }

  private reconnect(): void {
    if (this.reconnectAttempts < this.reconnectMaxAttempts) {
      setTimeout(() => {
        this.connect();
        this.reconnectAttempts++;
      }, Math.pow(2, this.reconnectAttempts) * 1000); // Exponential back-off
    } else {
      console.error('Max reconnect attempts reached. Please try connecting manually.');
    }
  }

  sendMessage(userMessage: string): void {
    if (this.webSocket && this.webSocket.readyState === WebSocket.OPEN) {
      this.webSocket.send(userMessage);
      this.lastUserMessage = userMessage; // store the last message sent
      //this.messagesSubject.next({ type: 'assistant', response: userMessage });
    } else {
      // console.log("Service SendMsg Error", this.lastUserMessage);
      this.messagesSubject.next({
        type: 'error',
        error: {
          response: ['An unexpected error occurred, please submit your answer again']
        }
      });
      console.error('WebSocket is not open. Message not sent.');
      this.resendLastMessage(); 
    }
}


  resendLastMessage(): void {
    if (this.lastUserMessage && this.resendCount < 10) {
      this.resendCount++;
      // console.log("Count Resend Msg", this.resendCount);
      setTimeout(() => {
        this.sendMessage(this.lastUserMessage);
      }, 3000); // wait 3 seconds
    } else {
      // console.log("Emitting final error message after 5 retries.");
      this.messagesSubject.next({
        type: 'maxRetryError',
        error: {
          response: ['All attempts to resend the message have failed. Please try again later.']
        }
      });
      this.resendCount = 0; // reset count
    }
  }

  closeConnection(): void {
    if (this.webSocket && this.webSocket.readyState === WebSocket.OPEN) {
      this.webSocket.close();
      console.log("WebSocket connection closed.");
  }
  
  }

  updateWebSocketUrl(assistantId: string, includeParams: boolean = true): void {
    this.assistantId = assistantId;
    console.log("updateWebSocketUrl",includeParams);
    this.includeParams = includeParams;
  }
  

  handleWebSocketError(error: Event): void {
    this.messagesSubject.next({
      type: 'error',
      content: 'An error occurred. Please try again later.',
      isError: true
    });
  }

  // checks if the webscoket is connected
  isConnected(): boolean {
    // console.log("this.webSocket",this.webSocket);
    return this.webSocket && this.webSocket.readyState === WebSocket.OPEN;
  }

  updateTitle(title: string) {
    this.titleSubject.next(title);
  }

  updatePrimaryKeyword(keyword: string) {
    this.primaryKeywordSubject.next(keyword);
  }

  updateSecondaryKeywords(keywords: string[]) {
    this.secondaryKeywordsSubject.next(keywords);
  }

  updateMetaDescription(metaDescription: string) {
    this.metaDescriptionnSubject.next(metaDescription);
  }

  requestRefresh() {
    this.refreshRequestedSubject.next();
  }

  addOrUpdateTask(update: SuggestionUpdate) {
    const tasks = this.tasksSubject.value;
    const taskIndex = tasks.findIndex(task => task.type === update.type);
    if (taskIndex !== -1) {
    
      tasks[taskIndex] = { ...tasks[taskIndex], ...update };
    } else {
     
      tasks.push(update);
    }
    this.tasksSubject.next([...tasks]);
  }


  updateTaskStatus(taskName: string, status: SuggestionUpdate['status']) {
    const tasks = this.tasksSubject.value.map(task => {
      if (task.type === taskName) {
        return { ...task, status };
      }
      return task;
    });
    this.tasksSubject.next(tasks);
  }

  // sesion data
getSessionData(sessionId: string): Observable<any> {
  const url = `${environment.aiToolsService}${Constants.auxiliary_texts.controls.version_control}${sessionId}`;
  const token = this.authService.getToken();
  const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
  });
  return this.http.get(url, { headers });
}

  // profile sessions
  getProfileSessions(profileSlug: string): Observable<{ today: Session[], last7Days: Session[], last30Days: Session[] }> {
    const url = `${environment.aiToolsService}${Constants.auxiliary_texts.controls.sessions_profile}${profileSlug}`;
    const token = this.authService.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    return this.http.get<SessionResponse>(url, { headers }).pipe(
      map(data => {
        if (!data || !data.response || !data.response.sessions) {
          throw new Error('Invalid server response');
        }
        return this.processSessions(data.response.sessions);
      }),
      catchError(error => throwError(() => new Error('Error fetching sessions: ' + error.message)))
    );
  }

  private processSessions(sessions: Session[]): { today: Session[], last7Days: Session[], last30Days: Session[] } {
    const now = new Date();
    const today: Session[] = [];
    const last7Days: Session[] = [];
    const last30Days: Session[] = [];
  
    sessions.forEach(session => {
      const sessionDate = new Date(session.created_at);
      const sessionDay = new Date(sessionDate.getFullYear(), sessionDate.getMonth(), sessionDate.getDate());
      const nowDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  
      const dayDiff = Math.floor((nowDay.getTime() - sessionDay.getTime()) / (1000 * 3600 * 24));
  
      if (dayDiff === 0) {
        today.push(session);
      } else if (dayDiff <= 7) {
        last7Days.push(session);
      } else if (dayDiff <= 30) {
        last30Days.push(session);
      }
    });
  
    return { today, last7Days, last30Days };
  }  

  // profile sessions
  getChatHistory(sessionId: string): Observable<any> {
    const url = `${environment.aiToolsService}${Constants.auxiliary_texts.controls.chat_history}${sessionId}`;
    const token = this.authService.getToken();
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });
    return this.http.get(url, { headers });
  }

  saveVersion(sessionId: string, dataObj: DataObject, customName: string = ''): Observable<any> {
    const payload = { session_id: sessionId, data_obj: dataObj, custom_name: customName };
    const fullUrl = environment.aiToolsService + Constants.auxiliary_texts.controls.save_version;

    return this.http.post(fullUrl, payload);
  }

public saveLastFormData(formData: any): void {
  // console.log("ServicesaveLastFormData ",formData);
  this.lastFormData = formData;
}

private onReconnectSendData(): void {
  // console.log("onReconnectSendData");
  if (this.lastFormData) {
    console.log("onReconnectSendData FormData",this.lastFormData);
    let message = `My title is: ${this.lastFormData.title}, my Primary Keyword is: ${this.lastFormData.primaryKeyword}`;
   
    message += "\nProceed to the next step";
    this.sendMessage(message);
  }
}

setFavorite(sessionId: string, isFavorite: boolean): Observable<any> {
  const url = `${environment.aiToolsService}${Constants.auxiliary_texts.controls.favorite_session}${sessionId}`;
  return this.http.patch(url, { is_favorite: isFavorite });
}

updateSessionName(sessionId: string, customName: string): Observable<any> {
  const url = `${environment.aiToolsService}${Constants.auxiliary_texts.controls.update_session_name}${sessionId}`;
  return this.http.put(url, { custom_name: customName });
}

// test new assistant

connectWithInitialMessage(assistantId: string, initialMessageDetails: any, sessionId?: string): void {
  console.log("initialMessageDetails", initialMessageDetails);
  console.log("SERVICE SESION ID", sessionId);

  if (!isPlatformBrowser(this.platformId)) return;

  this.assistantId = assistantId;

  if (this.webSocket && this.webSocket.readyState === WebSocket.OPEN) {
    
  } else if (!this.webSocketConnectionAttemptInProgress) {
    this.webSocketConnectionAttemptInProgress = true;

    if (this.webSocket) {
      this.webSocket.close();
    }

    const fullWebSocketUrl = this.getFullWebSocketUrl(sessionId); // pass sessionId
    console.log("Connecting with URL:", fullWebSocketUrl);
    this.webSocket = new WebSocket(fullWebSocketUrl);

    this.webSocket.onopen = () => {
      this.isWebSocketConnected = true;
      this.webSocketConnectionAttemptInProgress = false;
      this.sendInitialDetails(initialMessageDetails);
    };

    this.webSocket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log("onmessage1", message);

      if (!this.receivedInitialMessage) {
        console.log("!this.receivedInitialMessage");
        this.receivedInitialMessage = true;
        this.spinnerHandler.handleRequest('minus'); // stop spinner when receiving first message
        this.scrollToBottomRequested.emit(); 
        // ignore this message and do not process
      } else {
        this.ngZone.run(() => {
          console.log("onmessage", message);
          this.messagesSubject.next(message);
        });
      }
    };

    this.webSocket.onerror = (event) => {
      console.error('WebSocket error:', event);
      this.webSocketConnectionAttemptInProgress = false;
    };

    this.webSocket.onclose = (event) => {
      this.isWebSocketConnected = false;
      this.webSocketConnectionAttemptInProgress = false;
      if (!event.wasClean) {
        console.error('WebSocket connection was closed unexpectedly.');
      }
    };
  } else {
    console.log("Connection attempt is already in progress.");
  }
}



private sendInitialDetails(details: any): void {
  const initialMessage = this.formatInitialMessage(details);
  this.sendMessagePreviousBrief(initialMessage);
}

private formatInitialMessage(details: any): string {
  let message = "I already have this information for you: ";
  message += `\nForm Details: ${JSON.stringify(details.form)}`;
  message += "\nPlease check the data that is already filled in and in the next message that is sent, continue with the next step that is not yet filled in.";
  return message;
}

  private sendMessagePreviousBrief(message: string): void {
    if (this.webSocket && this.webSocket.readyState === WebSocket.OPEN) {
      this.webSocket.send(message);
  } else {
      console.error("WebSocket is not connected.");
  }
  }

saveScoringFeedback(sessionId: string, scoring: number, suggestions: any[]): Observable<any> {
  const url = `${environment.aiToolsService}/sessions/${sessionId}/scoring_feedback`;
  const body = {
      scoring: scoring,
      suggestions: suggestions
  };
  return this.http.post(url, body);
}

getScoringFeedbacks(sessionId: string): Observable<any> {
  const url = `${environment.aiToolsService}/sessions/${sessionId}/scoring_feedback`;
  const token = this.authService.getToken();
  const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
  });
  return this.http.get(url, { headers });
}

saveContentOutline(sessionId: string, contentOutline: any[]): Observable<any> {
  const url = `${environment.aiToolsService}/sessions/${sessionId}/content_outline`;
  const body = {
    outline_html: contentOutline
  };
  return this.http.post(url, body);
}

getContentOutline(sessionId: string): Observable<any> {
  const url = `${environment.aiToolsService}/sessions/${sessionId}/content_outline`;
  const token = this.authService.getToken();
  const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
  });
  return this.http.get(url, { headers });
}

// load previous brief with chatHistory and scoringFeedbacks
loadSessionData(sessionId: string): Observable<any> {
  const chatHistory = this.getChatHistory(sessionId);
  const scoringFeedbacks = this.getScoringFeedbacks(sessionId);
  const contentOutline = this.getContentOutline(sessionId); 

  return forkJoin({
    chatHistory,
    scoringFeedbacks,
    contentOutline
  }).pipe(
    catchError(error => {
      console.error('Error loading session data:', error);
      return throwError(() => new Error('Error loading session data'));
    })
  );
}

getProfileSlug(token: string): Observable<any> {
  const url = `${environment.aiToolsService}${Constants.auxiliary_texts.controls.get_profile_slug}`;
  return this.http.post<any>(url, { token }).pipe(
    tap({
      next: (response) => {
        this.profileSlugSource.next(response.profile_slug);
      },
      error: (error) => {
        console.error('Error fetching profile slug:', error);
        this.profileSlugSource.next(null);
      }
    })
  );
}

deleteSession(sessionId: string): Observable<any> {
  const url = `${environment.aiToolsService}/sessions/${sessionId}`;
  const token = this.authService.getToken();
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  });

  return this.http.delete(url, { headers }).pipe(
    map(response => {
      return response;
    }),
    catchError(error => {
      console.error('Error deleting session:', error);
      return throwError(() => new Error('Error deleting session: ' + error.message));
    })
  );
}
}
  