import { Component, ElementRef, HostListener, Input, OnInit, PLATFORM_ID, ChangeDetectorRef, afterNextRender, CUSTOM_ELEMENTS_SCHEMA, inject} from '@angular/core';
import { CommonModule, isPlatformBrowser, isPlatformServer} from '@angular/common';
import { HttpClientModule } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { SpinnerInterceptorService } from '../_shared/services/spinner-interceptor.service';
//import { NgxSpinnerModule } from "ngx-spinner";
//import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EditorModule } from '@tinymce/tinymce-angular'

import { environment } from '../../environments/environment';

import { CommentsService } from './services/comments-service';
import { CreateComment } from './models/create-comment-model';
import { UpdateComment } from './models/update-comment-model';
import { Author, ListComments } from './models/list-comments';
import { Comment } from  './models/comment-model';
import { faLessThan, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

import { AuthService } from '../_shared/services/auth-service';

import { ThreadDets } from './models/thread-dets-model';
import { Profile } from './models/profile-model';
import { Reactions } from './models/reactions-mode';




let env = environment
@Component({
  selector: 'app-comments',
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule,
    //NgxSpinnerModule,
    //SweetAlert2Module.forRoot(),
    FontAwesomeModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    EditorModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  templateUrl: './comments.component.html',
  styleUrl: './comments.component.sass'
})

export class CommentsComponent implements OnInit{

  @Input() token: string = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2Nyb3dkY29udGVudC5jb20iLCJhdWQiOiJodHRwczovL2Nyb3dkY29udGVudC5jb20iLCJpYXQiOjE3MDIzMTU1MjUsIm5iZiI6MTcwMjMxNTUyNSwiY29udGV4dCI6Im1hbmFnZWRfcG9ydGFsIiwicmVsX2V4dF90eXBlIjoiY2xpZW50IiwicmVsX2V4dF9pZCI6IjEifQ.XGmL5mr8LHFWYzkSPNQPJhX3CaMYIBMFU9AcgnVmIJU";
  @Input() context: string = "main_app";
  @Input() rel_ext_type: string = "deliveries";
  @Input() rel_ext_id: string = "16";

  env = environment
  platformId = inject(PLATFORM_ID);
  commentsService = inject(CommentsService)

  threadDets:ThreadDets;
  currentEditorId: string;
  mainEditorId: string;
  editorVisibility = {};
  comments: Comment[] = [];
  listComments: ListComments[] = [];
  editingComment: ListComments = null;
  editingCommentContent: string = '';
  showReplies: boolean = true;
  isVisible: boolean;
  content = ''
  userReply: string = null;
  replyingToComment: ListComments | null = null;
  imageUrl?: string;
  currentMainCommentId = null;
  skipComments: number = 0;
  limitComments: number = 5;
  allCommentsLoaded: boolean = false;

  // loading msg
  isLoading: boolean = true;
  loadingError: string = '';

  // tinymce
  editorConfig = {
    base_url: this.env.frontendURL+'/tinymce',
    suffix: '.min',
    plugins: 'lists link image table wordcount',
    menubar: false,
    height: '200px',
    branding: false,
    placeholder: "Add a comment...",
    formats: {
      preformatted: { block: 'pre', styles: { 'white-space': 'pre-wrap' } }
    },
    toolbar: 'undo redo | bold italic underline | bullist numlist | link image giphy emojis | code | formatselect',
    setup: (editor) => {

      editor.on('keydown', (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            if (this.editingComment) {
                this.saveEditedContent(editor.id);
            } else {
                this.saveEditorContent(editor.id);
            }
        }
      });


      editor.on('paste', function (e) {
        var clipboardData = e.clipboardData || window.Clipboard;
        var items = clipboardData.items;

        for (var i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            var blob = items[i].getAsFile();
            var reader = new FileReader();

            reader.onload = function (event) {
              var result = event.target.result;
              if (typeof result === "string") {
                var img = new Image();
                img.onload = function() {
                  // call the function to resize the image
                  var resizedImageHTML = resizeImage(img);
                  editor.insertContent(resizedImageHTML);
                };
                img.src = result; // set the result as image source
              }
            };

            reader.readAsDataURL(blob);

            // prevent TinyMCE from pasting image as URL
            e.preventDefault();
          }
        }
      });

      function getNewDimensions(img) {
        let width = img.width;
        let height = img.height;
        const aspectRatio = width / height;

        // sets maximum sizes
        const maxSmallWidth = 600;
        const maxSmallHeight = 300;
        const maxMediumWidth = 1200;
        const maxMediumHeight = 800;
        const maxLargeWidth = 1920;
        const maxLargeHeight = 1080;

        // if the image is already smaller than the small dimensions, do not increase the size
        if (width <= maxSmallWidth && height <= maxSmallHeight) {
          return { width, height };
        }

        // if the image is between small and medium dimensions, resize to small dimensions
        if (width <= maxMediumWidth && height <= maxMediumHeight) {
          // use the small size as a reference for resizing
          if (width > maxSmallWidth || height > maxSmallHeight) {
            if (aspectRatio >= 1) {
              width = maxSmallWidth;
              height = width / aspectRatio;
            } else {
              height = maxSmallHeight;
              width = height * aspectRatio;
            }
          }
        }
        // if image is larger than medium dimensions, resize to medium dimensions
        else if (width > maxMediumWidth || height > maxMediumHeight) {
          if (width > maxLargeWidth || height > maxLargeHeight) {
            if (aspectRatio >= 1) {
              width = maxMediumWidth;
              height = width / aspectRatio;
            } else {
              height = maxMediumHeight;
              width = height * aspectRatio;
            }
            // ensuring that the height does not exceed the maximum limit if the width was set to maxMediumWidth
            if (height > maxMediumHeight) {
              height = maxMediumHeight;
              width = height * aspectRatio;
            }
          }
        }

        // returns the new dimensions, ensuring the value is an integer
        return { width: Math.round(width), height: Math.round(height) };
      }


      function resizeImage(image) {
        let newDims = getNewDimensions(image);
        let width = newDims.width;
        let height = newDims.height;

        // create a canvas for resizing
        let canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, width, height);

        // returns the resized and possibly compressed image
              // checks if the browser supports WebP
        if (canvas.toDataURL('image/webp').indexOf('data:image/webp') == 0) {
          // webP supported
          return '<img src="' + canvas.toDataURL('image/webp', 0.8) + '"/>'; // 0.8 is the quality level
        } else {
          // fallback to PNG
          return '<img src="' + canvas.toDataURL('image/png') + '"/>';
        }
      }
      editor.on('SetContent', (e) => {
      });


      // adding giphy - leave commmented for now
      // editor.ui.registry.addButton('giphy', {
      //   text: 'Gifs',
      //   onAction: () => {
      //     this.openGiphyModal(editor);
      //   }
      // });

      // adding emojis
      editor.ui.registry.addButton('emojis', {
        text: 'Emojis',
        onAction: () => {
          this.showEmojiSelectionModal(editor);
        }
      });
    },
      init_instance_callback: (editor) => {
        this.editorInitialized(editor);
        this.insertSendButton(editor);
        editor.focus();
    }
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private authSer:AuthService,
    private elRef: ElementRef,
    private library: FaIconLibrary
    ) {
      library.addIcons(faSpinner);
      this.hideEditor = this.hideEditor.bind(this);
    }

  ngOnInit()
  {
    console.log("Comments Token",this.token);
    if(this.token != undefined && this.token != "") {
      this.authSer.exLogin(this.token)
      console.log("IF Comments Token",this.token);

    this.loadThreadDets();
    this.fetchComments();

    this.mainEditorId = 'mainEditorId';
    this.currentEditorId = this.generateEditorId();
    this.editorVisibility[this.currentEditorId] = false;
    }
    if (isPlatformBrowser(this.platformId))
    {

    }
  }

   // generates a unique ID for the editor
   private generateEditorId(): string
   {
     return 'editor_' + Math.random().toString(36).slice(2, 9);
   }

   createEditor(commentData: ListComments) {
     const newEditorId = 'editorForComment-' + commentData.id + '-' + Math.random().toString(36).substring(2, 11);

     if (this.isReply(commentData)) {
       //reply of reply
       this.currentMainCommentId = this.findMainCommentId(commentData.id);
     }else {
       //reply of main cooment
     this.currentMainCommentId = commentData.id;
     }

     // update the target comment and editor visibility
     this.replyingToComment = null;
     setTimeout(() => {
       this.replyingToComment = commentData;
       this.currentEditorId = newEditorId;
       this.editorVisibility[this.currentEditorId] = true;
       this.cdr.detectChanges();
     }, 0);
   }

   toggleEditor(id: string) {
     // if the editor is hidden or not initialized, show it
     if (!this.editorVisibility[id]) {
       this.editorVisibility[id] = true;
     } else {
       // if the editor is visible, hide it but do not remove
       this.hideEditor(id);
     }
     this.cdr.detectChanges();
   }

   hideEditor(id: string) {
     let editorInstance = window['tinymce'].get(id);
     if (editorInstance && editorInstance.getContent() === '') {
      // just hide the editor container
       const editorContainer = editorInstance.getContainer();
       editorContainer.style.display = 'none';
       this.editorVisibility[id] = false;
       this.cdr.detectChanges();
     }
   }

   @HostListener('document:click', ['$event'])
 onClickOutside(event: MouseEvent) {
   // check all editors
   Object.keys(this.editorVisibility).forEach(editorId => {
     const editorContainer = this.elRef.nativeElement.querySelector(`#${editorId}`);
     if (editorContainer && !editorContainer.contains(event.target)) {
       this.hideEditor(editorId);
     }
   });
 }


   // method to check if a comment is a reply based on its ID
   isReply(commentData: ListComments): boolean {
     return this.listComments.some(comment =>
       comment.replies && comment.replies.some(reply => reply.id === commentData.id));
   }

   // Helper method to find the ID of the main comment of a reply
   findMainCommentId(replyId: string): string | null {
     let mainCommentId = null;
     this.listComments.forEach(comment => {
       if (comment.replies && comment.replies.some(reply => reply.id === replyId)) {
         mainCommentId = comment.id;
       }
     });
     return mainCommentId;
   }


   saveEditorContent(id: string) {
     let editorInstance = window['tinymce'].get(id);
     if (editorInstance) {
       const userContent = editorInstance.getContent();

       const newCommentData: CreateComment = {
         thread:
         {
           context: this.context,
           rel_ext_type: this.rel_ext_type,
           rel_ext_id: this.rel_ext_id
         },
         content: userContent
       };

       if (this.replyingToComment) {
         newCommentData.reply_of = this.currentMainCommentId;
       }

       this.sendComment(newCommentData, this.currentMainCommentId);

       // removes editor instance and update visibility
       editorInstance.remove();
       this.editorVisibility[id] = false;

       // generates a new ID for the next editor instance
       this.currentEditorId = null

       // restore the initial state related to the response
       this.replyingToComment = null;

       // force change detection to update the UI
       this.cdr.detectChanges();
     } else {
       alert(`Editor with ID ${id} not found or not active.`);
       console.error(`Editor with ID ${id} not found or not active.`);
     }
   }

   openGiphyModal(editor) {
     fetch(`https://api.giphy.com/v1/gifs/search?api_key=z1PXY9qbqeKNiLLcvFtBpZ723bfsg6CR&q=emoji&limit=5`)
       .then(response => response.json())
       .then(data => {
         if (data.data.length) {
           this.showGifSelectionModal(data.data, editor);
         }
       })
       .catch(error => {
         console.error("Error when searching for GIF:", error);
       });
   }

   showGifSelectionModal(gifs, editor)
   {
     // creating HTML markup for GIFs with a unique ID for each image
     let gifOptionsHtml = gifs.map((gif, index) =>
         `<img id="gif-selector-${index}" src="${gif.images.fixed_width.url}" alt="Emoji GIF" style="cursor:pointer; margin: 5px;" data-url="${gif.images.fixed_width.url}"/>`
     ).join('');

     // tinymce dialog window configuration
     editor.windowManager.open({
         title: 'select an emoji gif',
         size: 'medium',
         body: {
             type: 'panel',
             items: [{
                 type: 'htmlpanel',
                 html: gifOptionsHtml
             }]
         },
         buttons: [
             {
                 type: 'cancel',
                 text: 'Close'
             }
         ]
     });

   // ading event listeners for each GIF image right after opening the dialog
   // using setTimeout to ensure elements are available in the DOM
   setTimeout(() => {
         gifs.forEach((gif, index) => {
             const imgElement = document.getElementById(`gif-selector-${index}`);
             if (imgElement) {
                 imgElement.addEventListener('click', (e) => {
                   const target = e.target as HTMLElement;
                   const gifUrl = target.dataset['url'];
                   if (gifUrl) {
                       editor.insertContent('<img src="' + gifUrl + '" alt="Emoji GIF" />');
                       editor.windowManager.close(); // Close the dialog after inserting the GIF
                   }
               });
             }
         });
     }, 100);
   }


   showEmojiSelectionModal(editor) {
     const emojis = [
       '😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇',
       '🙂', '🙃', '😉', '😌', '😍', '🥰', '😘', '😗', '😙', '😚',
       '😋', '😛', '😝', '😜', '🤪', '🤨', '🧐', '🤓', '😎', '🥸',
       '🤩', '😏', '😒', '😞', '😔', '😟', '😕', '🙁', '😣', '😖',
       '😫', '😩', '🥺', '😢', '😭', '😤', '😠', '🤯',
       '😳', '🥵', '🥶', '😱', '😨', '😰', '😥', '😓', '🤗', '🤔',
       '🤭', '🤫', '🤥', '😶', '😐', '😑', '😬', '🙄', '😯', '😦',
       '😧', '😮', '😲', '🥱', '😴', '🤤', '😪', '😵', '🤐', '🥴',
       , '🤕', '🤑', '🤠', '🤡','💕', '👋', '🤟', '🤟',
       '👏', '🚀',  '👾', '🤖', '🎃', '😺', '😸', '❤️','👇', '👍',

     ];


     // creating HTML markup for emojis with a unique ID for each span
     let emojiOptionsHtml = emojis.map((emoji, index) =>
         `<span id="emoji-selector-${index}" class="selectable-emoji" style="cursor:pointer; font-size: 20px; margin: 5px;" data-emoji="${emoji}">${emoji}</span>`
       ).join('');

       // TinyMCE dialog window configuration
       editor.windowManager.open({
         title: 'Select an Emoji',
         size: 'medium',
         body: {
             type: 'panel',
             items: [{
                 type: 'htmlpanel',
                 html: emojiOptionsHtml
             }]
         },
         buttons: [
             {
                 type: 'cancel',
                 text: 'Close'
             }
         ]
       });

     // adding event listeners for each emoji right after opening the dialog
     // using setTimeout to ensure elements are available in the DOM
     setTimeout(() => {
       emojis.forEach((emoji, index) => {
           const spanElement = document.getElementById(`emoji-selector-${index}`);
           if (spanElement) {
               spanElement.addEventListener('click', (e) => {
                   const target = e.target as HTMLElement;
                   const selectedEmoji = target.dataset['emoji'];
                   if (selectedEmoji) {
                       editor.insertContent(selectedEmoji);
                       editor.windowManager.close();
                   }
               });
           }
       });
     }, 100);
   }

   // editing comments start

   startEditingComment(comment: ListComments)
   {
     this.editingComment = comment;
     this.editingCommentContent = comment.content;
     this.cdr.detectChanges();
   }

   saveEditedContent(id: string) {
     let editorInstance = window['tinymce'].get(id);
     if (editorInstance) {
       const editedContent = editorInstance.getContent();

       // check if there is a comment being edited
       if (this.editingComment) {

         // update the content of the comment being edited
         this.editingComment.content = editedContent;

         // use this.editingComment.id to get the correct comment_id
         const updateData: UpdateComment = {
           comment_id: this.editingComment.id,
           content: editedContent
         };
         // call service to update comment
         this.commentsService.updateComment(updateData).subscribe({
           next: (response) => {
           },
           error: (error) => {

             console.error('Error updating comment', error);
           }
         });
       }

       editorInstance.remove();
       this.editingComment = null;
       this.cdr.detectChanges();
     } else {
       console.error(`Editor with ID ${id} not found or not active.`);
     }
   }

   // sets the content of the editor to the content of the comment being edited.
     editorInitialized(editor: any) {
       this.editorVisibility[editor.id] = true;
       if (this.editingComment) {
         editor.setContent(this.editingComment.content);
       }
     }


   // editing comments end
   getTotalCommentsCount(comments: ListComments[]): number
   {
     let count = comments.length;
     comments.forEach(comment => {
         if (comment.replies) {
             count += this.getTotalCommentsCount(comment.replies);
         }
     });
     return count;
   }

   likeComment(comment: ListComments) { // any utilizado para simplificar, ajuste conforme a tipagem correta
     // logged in user caught from 'threadDets'
     const currentUser = this.threadDets.current_profile;

     // if the 'reactions' field does not exist, initialize with an empty array
     if (!comment.reactions) {
       comment.reactions = [];
     }

     // try to find a 'like' reaction
     let likeReaction = comment.reactions.find(r => r.type === 'like');

     // if there is no 'like' reaction, create a
     if (!likeReaction) {
       likeReaction = { type: 'like', quantity: 0, reacted_by: [] };
       comment.reactions.push(likeReaction);
     }

     // checks if the current user has already liked
     const hasLiked = likeReaction.reacted_by.some(user => user.display_name === currentUser.display_name);

     // ui update
     if (hasLiked) {
       // decrease the quantity and remove the user from the reacted list
       likeReaction.quantity--;
       likeReaction.reacted_by = likeReaction.reacted_by.filter(user => user.display_name !== currentUser.display_name);
         // check if the quantity is zero and update the like reaction
       if (likeReaction.quantity === 0) {
         const reactionIndex = comment.reactions.indexOf(likeReaction);
         if (reactionIndex > -1) {
           comment.reactions.splice(reactionIndex, 1);
         }
       }
     } else {
       // increment the quantity and add the user to the reacted list
       likeReaction.quantity++;
       likeReaction.reacted_by.push({
         display_name: currentUser.display_name,
         url_picture: currentUser.url_picture,
         created_at: new Date().toISOString(),
         updated_at: new Date().toISOString(),
       });
     }

      // forcing Angular change detection to update UI
      this.cdr.detectChanges();

     // persists the 'like' action in the backend
     this.commentsService.likeReaction('comment',comment.id, this.token)
       .subscribe({
         next: response => {
           if (!response || !response.passed) {
             // reverts UI changes if the response is negative
             this.revertLike(comment, currentUser);
             console.error('Error liking comment', response);
           }
         },
         error: error => {
           // reverts UI changes in case of API error
           this.revertLike(comment, currentUser);
           console.error('Error calling likeReaction service', error);
         }
       });
   }

   likeThread(thReactId: string) {
     this.commentsService.likeReaction('thread', thReactId, this.token)
       .subscribe({
         next: response => {
           if (response && response.passed) {
             this.loadThreadDets();
           } else {
             console.error('Thread like was not successful', response);
             alert("Thread like was not successful");
           }
         },
         error: error => {
           console.error('Error liking thread', error);
           alert("Error liking thread")
         }
       });
   }




   shouldDisplayLike(comment: ListComments): string { // any utilizado para simplificar, ajuste conforme a tipagem correta
     // check if the current user has already reacted with 'like'
     const likeReaction = comment.reactions?.find(r => r.type === 'like');
     const hasLiked = likeReaction?.reacted_by?.some(r => r.display_name === this.threadDets.current_profile.display_name);
     return hasLiked ? '' : 'Like';
   }

   //function to reverse the 'like' action
   revertLike(comment: ListComments, currentUser: Profile) {
     const likeReaction = comment.reactions.find(r => r.type === 'like');
     if (likeReaction) {
       // checks if the current user has already reacted using 'display_name'
       const hasLiked = likeReaction.reacted_by.some(user => user.display_name === currentUser.display_name);

       if (hasLiked) {
         // if have already reacted, remove the reaction (reverse the like)
         likeReaction.quantity--;  // Decrementa o contador de likes
         likeReaction.reacted_by = likeReaction.reacted_by.filter(user => user.display_name !== currentUser.display_name);
       } else {
         // if haven't reacted yet, treat it as a new like (this is strange for a 'revertLike', confirm the logic)
         likeReaction.quantity++;
         likeReaction.reacted_by.push({
           display_name: currentUser.display_name,
           url_picture: currentUser.url_picture,
           created_at: new Date().toISOString(),
           updated_at: null
         });
       }
     }
   }

   sendDeleteComment(commentToDelete: ListComments) {
     this.commentsService.deleteComment(commentToDelete.id, this.token).subscribe({
       next: response => {
         if (response && response.passed && response.response) {
           // flag to check if removal has occurred
           let removed = false;

           // first, try to find and remove the comment from the response array
           this.listComments.forEach((comment, index) => {
             if (comment.replies) {
               const replyIndex = comment.replies.findIndex(reply => reply.id === commentToDelete.id);
               if (replyIndex > -1) {
                 // remove the response from the replies array
                 this.listComments[index].replies.splice(replyIndex, 1);
                 this.listComments[index].replies_counter = this.listComments[index].replies.length;
                 removed = true;
               }
             }
           });

           // if not an answer, remove the main comment
           if (!removed) {
             const index = this.listComments.findIndex(comment => comment.id === commentToDelete.id);
             if (index > -1) {
               this.listComments.splice(index, 1);
               removed = true;
             }
           }

           // a comment was removed, update the list to force Change Detection
           if (removed) {
             this.listComments = [...this.listComments];
           }

           this.cdr.detectChanges();
         }
       },
       error: error => {
         alert("Error when deleting comment");
         console.error('delete comment error', error);
       }
     });
   }

   // available time to edit or delete comments
   canEditOrDelete(commentDate: string): boolean
   {
     const commentDateTime = new Date(commentDate);
     const currentTime = new Date();
     const diffInMinutes = (currentTime.getTime() - commentDateTime.getTime()) / (1000 * 60);
     return diffInMinutes <= 3;
   }

   // openreplys
   visibleReplies(comment: Comment): Comment[]
   {
     return comment.replies.filter(reply => reply.isVisible);
   }

   hasHiddenReplies(comment: Comment): boolean
   {
       return comment.replies.some(reply => !reply.isVisible);
   }

   hiddenRepliesCount(comment: Comment): number
   {
       return comment.replies.filter(reply => !reply.isVisible).length;
   }

   showAllReplies(comment: Comment)
   {
       comment.replies.forEach(reply => reply.isVisible = true);
       this.cdr.detectChanges();
   }

   loadThreadDets()
   {
     this.commentsService.getThreadDets(this.context, this.rel_ext_type, this.rel_ext_id)
       .subscribe({
         next: res => {
          console.log('res: ', res)
           if (res && res.passed && res.response) {
           this.threadDets = res.response;
             this.cdr.detectChanges();
           }
         },
         error: error => {
           this.loadingError = 'Error loading thread';
         },
         complete: () => {
         }
       });
   }

   fetchComments(): void
   {
     this.isLoading = true;
     this.loadingError = '';
     //this.spinner.show();

     this.commentsService.getComments(this.context, this.rel_ext_type, this.rel_ext_id, this.limitComments.toString(), this.skipComments.toString())
       .subscribe({
         next: response => {
           if (response && response.response.length > 0) {
             const newComments = response.response.map(comment => {
               const updatedAuthor = {
                 ...comment.author,
                 display_name: comment.author.display_name
               };

               const getInitials = (name: string): string => {
                 return name
                   .match(/(\b\S)?/g)
                   .join("")
                   .match(/(^\S|\S$)?/g)
                   .join("");
               }

               // display replys by default
               comment.showReplies = true;

               const shouldShowInitials = updatedAuthor.url_picture === "guy";

               return {
                 ...comment,
                 shouldShowInitials,
                 authorInitials: shouldShowInitials ? getInitials(updatedAuthor.display_name) : '',
                 author: updatedAuthor
               };
             });

             // concatenates new comments with existing ones
             this.listComments = [...this.listComments, ...newComments];
             this.skipComments += this.limitComments; // increase the skip
             this.allCommentsLoaded = newComments.length < this.limitComments; // checks if all comments have been loaded
           } else {
             this.allCommentsLoaded = true; // no more comments to load
           }
           //this.spinner.hide();
           this.isLoading = false;
           this.cdr.detectChanges();
         },
         error: error => {
           this.loadingError = 'Error loading comments';
           this.isLoading = false;
           //this.spinner.hide();
         },
         complete: () => {
           this.isLoading = false;
         }
       });
   }

     getInitials(name: string): string {
       let names = name.split(' ');
       let initials = names.length > 1
         ? (names[0].charAt(0) + names[names.length - 1].charAt(0))
         : names[0].charAt(0);
       return initials.toUpperCase();
     }

     hasReplies(comment: ListComments): boolean {
       return comment && comment.replies && comment.replies.length > 0;
     }

     toggleReplies(commentData: ListComments) {
       commentData.showReplies = !commentData.showReplies;

       if (commentData.showReplies && commentData.moreRepliesAvailable) {
         const currentRepliesCount = commentData.replies ? commentData.replies.length : 0;

         if (commentData.replies_counter > currentRepliesCount) {
           this.loadCommentReplies(commentData.id, 5, currentRepliesCount);
         }
       }
     }

     loadCommentReplies(commentId: string, limit: number = 5, skip: number = 0): void {
       this.commentsService.getCommentReplies(commentId, limit, skip).subscribe({
         next: (response) => {
           if (response && response.passed) {
             const index = this.listComments.findIndex(comment => comment.id === commentId);
             if (index !== -1) {
               const newReplies = response.response.filter(newReply =>
                 !this.listComments[index].replies.some(existingReply => existingReply.id === newReply.id)
               );
               this.listComments[index].replies = [...this.listComments[index].replies, ...newReplies];

               this.listComments[index].moreRepliesAvailable = this.listComments[index].replies.length < this.listComments[index].replies_counter;
             }
           } else {
             alert('Failed to load replies or no replies available');
           }
         },
         error: () => {
           alert('Error loading replies');
         }
       });
     }

       // method to be called when the user wants to load more responses
       onShowMoreReplies(comment: ListComments) {
         const currentRepliesCount = comment.replies ? comment.replies.length : 0;
         this.loadCommentReplies(comment.id, 5, currentRepliesCount);
       }

     sendComment(commentData: CreateComment, mainCommentId?: string) {
       this.commentsService.createComment(commentData).subscribe({
           next: (response) => {
               if (response && response.passed && response.response) {
                   const newComment: Comment = {
                       id: response,
                       content: commentData.content,
                   };
                   // normalize and update comments
                   const normalizedComment = this.normalizeCommentData(newComment);
                   this.updateLocalCommentList(normalizedComment, mainCommentId);
               }
           },
           error: (error) => {
               console.error('Error posting comment:', error);
               alert("Error when inserting new comment");
           }
       });
     }

   sendUpdateComment(updateComment: UpdateComment){
     this.commentsService.updateComment(updateComment).subscribe({
       next: response => {
         if (response) {
           const newComment: Comment = {
             content: updateComment.content,
           };
           const normalizedComment = this.normalizeCommentData(newComment);
           this.updateLocalCommentList(normalizedComment);
         }
       },
       error: error => {
         console.error('Error post:', error);
         alert("Error when updating comment");
       }
     });

   }

   updateLocalCommentList(newComment: ListComments, mainCommentId?: string)
   {
     if (mainCommentId) {
       const mainComment = this.listComments.find(comment => comment.id === mainCommentId);
       if (mainComment) {
         mainComment.replies = [newComment, ...mainComment.replies];
         mainComment.replies_counter++;
         mainComment.showReplies = true;
       }
     } else {

       this.listComments.unshift(newComment);
     }
     this.cdr.detectChanges();
   }

   normalizeCommentData(rawComment: any): ListComments {
     let reactions_counters = [];
     let url_picture = rawComment.author?.url_picture || 'guy';

     let author: Author = {
       context: rawComment.author?.context || '',
       rel_ext_type: rawComment.author?.rel_ext_type || '',
       rel_ext_id: rawComment.author?.rel_ext_id || '',
       display_name: rawComment.author?.name || this.threadDets.current_profile.display_name,
       url_picture: url_picture,
       created_at: rawComment.author?.created_timestamp || new Date().toISOString(),
       updated_at: rawComment.author?.updated_timestamp || new Date().toISOString(),
       profile_id: rawComment.author?.profile_id || ''
     };

     return {
       id: rawComment.id?.response || rawComment.id,
       content: rawComment.content,
       replies_counter: rawComment.replies_counter || 0,
       reply_of: rawComment.reply_of || '',
       created_at: rawComment.created_timestamp || new Date().toISOString(),
       updated_at: rawComment.updated_timestamp || new Date().toISOString(),
       author: author,
       replies: [],
       reactions: []
     };
   }

   // onPaginatorChange(event: any): void
   // {
   //   const skip = event.pageIndex * event.pageSize;
   //   const limit = event.pageSize.toString();
   //   this.fetchComments(skip, limit);
   // }

   isCurrentUserReaction(reactedBy: Reactions['reacted_by'], currentProfileDisplayName: string): string {
     const currentUserReacted = reactedBy.some(user => user.display_name === currentProfileDisplayName);
     const totalReactions = reactedBy.length;

     if (currentUserReacted) {
       if (totalReactions === 1) {
         return 'You'; // only the logged in user reacted
       } else {
         return `You and ${totalReactions - 1} others`; // user logged in and others reacted
       }
     } else {
       if (totalReactions === 1) {
         return reactedBy[0]?.display_name; // only one user, who is not logged in, reacted
       } else {
         return `${reactedBy[0]?.display_name} and ${totalReactions - 1} others`; // one user and others reacted
       }
     }
   }

   stripHtml(html: string): string {
     return html.replace(/<[^>]*>/g, '');
   }

   insertSendButton(editor) {
     // get the TinyMCE editor container
     const editorContainer = editor.getContainer();

     // create send button
     const sendButton = document.createElement('button');
     sendButton.textContent = 'Send';

     // keep styles in line
     sendButton.style.backgroundColor = '#0067c4';
     sendButton.style.color = 'white';
     sendButton.style.border = 'none';
     sendButton.style.borderRadius = '4px';
     sendButton.style.padding = '10px 20px';
     sendButton.style.fontSize = '16px';
     sendButton.style.fontWeight = 'bold';
     sendButton.style.cursor = 'pointer';
     sendButton.style.boxShadow = '0 2px 4px rgba(0,0,0,0.2)';

     // Centralized Positioning
     sendButton.style.position = 'absolute';
     sendButton.style.bottom = '50%'; // Centraliza verticalmente
     sendButton.style.right = '10px';
     sendButton.style.transform = 'translateY(50%)'; // Ajusta a centralização

     // add the button to the editor container, outside the iframe
     editorContainer.appendChild(sendButton);

     // event listener for the button
     sendButton.addEventListener('click', () => {
       const content = editor.getContent();
       // Sua lógica de salvar conteúdo
       this.saveEditorContent(editor.id); // Chama o método de salvar
     });
   }


}
