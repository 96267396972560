import { Component, Inject } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';

@Component({
  selector: 'app-save-version-dialog',
  standalone: true,
  imports: [CommonModule,MatButtonModule, MatFormFieldModule, MatInputModule, MatDialogActions, MatDialogTitle, MatDialogClose, MatDialogContent, FormsModule],
  templateUrl: './save-version-dialog.component.html',
  styleUrl: './save-version-dialog.component.sass'
})
export class SaveVersionDialogComponent {

  versionName: string = '';

  constructor(public dialogRef: MatDialogRef<SaveVersionDialogComponent>) {}

}
