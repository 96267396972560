  import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
  import { CommonModule } from '@angular/common';
  import { Suggestion } from '../chat-bot/models/scoring';
  import {MatTabsModule} from '@angular/material/tabs';
  import { NgCircleProgressModule } from 'ng-circle-progress';
  import { CircleProgressModule } from '../circle-progress/circle-progress.module';
  import { MatTooltipModule } from '@angular/material/tooltip';
  import {MatChipsModule} from '@angular/material/chips';
  import {MatIconModule} from '@angular/material/icon';
  import { SpinnerHandlerService } from 'src/app/_shared/services/spinner-handler.service';
  import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
  import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

  interface OutlineItem {
    text: string;
    selected: boolean;
    hover: boolean;
    content?: string; 
    slug?: string;
  }

  @Component({
    selector: 'app-outline',
    standalone: true,
    imports: [CommonModule, MatTabsModule, CircleProgressModule, MatTooltipModule, MatChipsModule, MatIconModule, MatProgressSpinnerModule],
    templateUrl: './outline.component.html',
    styleUrl: './outline.component.sass'
  })
  export class OutlineComponent {
    private _loadingSuggestions: boolean = false
    private _suggestions: Suggestion[];
    @Input() isPanelOpen: boolean = false;
    @Input() scoring: number; 
    @Input()
    set suggestions(value: Suggestion[]) {
      const validSuggestions = value.filter(s => this.isValidSuggestion(s));
      this._suggestions = [...validSuggestions];  
    
      if (validSuggestions.length > 0) {
        this.loadingSuggestions = false;
        this.isReloadingSuggestions = false;
        this.snackBar.open("Scoring and suggestions generated successfully", "Close", { duration: 3000 });  
      }
      this.cdr.markForCheck();
    }
    
    @Input() loadingSuggestions: boolean = false;
    @Output() OutlineSelected: EventEmitter<string> = new EventEmitter<string>();
    @Output() refreshRequested: EventEmitter<void> = new EventEmitter();
    outlineItems: OutlineItem[] = [];
    selectedTab: string = 'outline';
    filteredSuggestions: Suggestion[] = [];
    frontendServURL = environment.frontendServURL;
    @Input() isReloadingSuggestions: boolean = false;
  

  get circleColor() {
    if (this.scoring >= 80) {
      return { outerStrokeColor: "#6bc06f", outerStrokeGradientStopColor: "#65e697" };
    } else if (this.scoring >= 60) {
      return { outerStrokeColor: "#f7b500", outerStrokeGradientStopColor: "#ffc700" }; 
    } else {
      return { outerStrokeColor: "#ff6347", outerStrokeGradientStopColor: "#ff826e" }; 
    }
  }


    constructor(private spinnerHandler: SpinnerHandlerService, private cdr: ChangeDetectorRef, private snackBar: MatSnackBar) {
      //mockdata to test outlineItems - Julia - for testing
      /*this.outlineItems = [
        { text: 'Title', selected: false, hover: false, slug: 'title' },
        { text: 'Field', selected: false, hover: false, slug: 'field' },
        { text: 'Brand', selected: false, hover: false, slug: 'brand' },
        { text: 'Publish Url', selected: false, hover: false, slug: 'publish-url' },
        { text: 'Primary Keyword', selected: false, hover: false, slug: 'primary-keyword' },
        { text: 'Secondary Keywords', selected: false, hover: false, slug: 'secondary-keywords' },
        { text: 'Meta Description', selected: false, hover: false, slug: 'meta-description' },
        { text: 'Word Count', selected: false, hover: false, slug: 'word-count' },
        { text: 'Tone of Voice', selected: false, hover: false, slug: 'tone-of-voice' },
        { text: 'Content Goal', selected: false, hover: false, slug: 'content-goal' },
        { text: 'Point of View', selected: false, hover: false, slug: 'point-of-view' },
        { text: 'Business Objective', selected: false, hover: false, slug: 'business-objective' },
        { text: 'Call to Action', selected: false, hover: false, slug: 'cta' },
        { text: 'Internal Links', selected: false, hover: false, slug: 'internal-links' },
        { text: 'Audience Insights', selected: false, hover: false, slug: 'audience-insights' },
        { text: 'Special Instructions', selected: false, hover: false, slug: 'special-instructions' },
        { text: 'SERP Analysis', selected: false, hover: false, slug: 'serp-analysis' },
        { text: 'Differentiation Opportunities', selected: false, hover: false, slug: 'differentiation-opportunities' },
        { text: 'Content Outline', selected: false, hover: false, slug: 'content-outline' }
      ];*/
    }

    ngOnInit() {
    }

    public triggerChangeDetection(): void {
      this.cdr.markForCheck();
    }


    get suggestions(): Suggestion[] {
      return this._suggestions;
    }
    
    isValidSuggestion(suggestion: any): boolean {
      return suggestion &&
             typeof suggestion === 'object' &&
             'section' in suggestion &&
             'slug' in suggestion &&
             'recommendation' in suggestion &&
             'intention' in suggestion;
    }
    
    toggleSelection(selectedItem: OutlineItem, slug?: string): void {
      if (selectedItem.selected) {
          selectedItem.selected = false;
          this.OutlineSelected.emit(''); 
      } else {
          this.outlineItems.forEach(item => item.selected = false);
          selectedItem.selected = true;
          this.OutlineSelected.emit(slug || selectedItem.slug);
      }
    }

    toggleSuggestionSelection(suggestion: Suggestion): void {
      if (suggestion.selected) {
        suggestion.selected = false;
        this.OutlineSelected.emit('');
      } else {
        this.suggestions.forEach(s => s.selected = false); 
        suggestion.selected = true;
        let emitSlug = suggestion.slug.replace(/main[-_]keyword/g, 'primary-keyword');
        emitSlug = emitSlug.replace(/_/g, '-');
        this.OutlineSelected.emit(emitSlug);
      }
    }   
    
    /*removeFromOutline(index: number) {
      this.outlineItems.splice(index, 1);
      this.outlineItems.forEach(item => item.selected = false);
    }*/

    addOutlineItem(newItem: { text: string; selected: boolean; hover: boolean; slug?: string }): void {
      const exists = this.outlineItems.some(item => item.text === newItem.text);
      if (!exists) {
        this.outlineItems.push(newItem);
        // reorder the list to ensure 'Title' is always at the beginning
        this.outlineItems.sort((a, b) => {
          if (a.text === 'Title') return -1;
          if (b.text === 'Title') return 1;
          return 0;
        });
      }
    }

    highlightOutlineItem(selectedSlug: string): void {
      this.outlineItems.forEach(item => {
          item.selected = item.slug === selectedSlug;
      });
  }
  getScoringText(): string {
    if (this.scoring >= 80) {
      return "Well Done!";
    } else if (this.scoring >= 60) {
      return "Almost there!";
    } else {
      return "Not so well";
    }
  }

  selectTab(tabName: string): void {
    this.selectedTab = tabName;
  }

  refreshSuggestions(): void {
    this.isReloadingSuggestions = true;
    this.refreshRequested.emit();
  }

  getIntentionClass(intention: string): string {
    switch (intention.toLowerCase()) {
      case 'positive':
        return 'positive';
      case 'negative':
        return 'negative';
      case 'neutral':
        return 'neutral';
      default:
        return '';
    }
  }
  

  getIntentionIcon(intention: string): string {
    switch (intention) {
      case 'positive':
        return 'check_circle_outline';
      case 'negative':
        return 'warning';
      case 'neutral':
        return 'help_outline'; 
      default:
        return '';
    }
  }

  getIntentionImage(intention: string): string {
    const basePath = this.frontendServURL + '/assets/img/';
    switch (intention.toLowerCase()) {
      case 'positive':
        return basePath + 'reaction-satisfied.svg';
      case 'negative':
        return basePath + 'reaction-dissatisfied.svg';
      case 'neutral':
        return basePath + 'reaction-neutral.svg';
      default:
        return '';
    }
  }
  
}
