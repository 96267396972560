import {Component, Output, OnInit, PLATFORM_ID, afterNextRender, inject, EventEmitter, Input, SimpleChanges, ViewChild} from '@angular/core';
import { ChatPanelComponent } from '../chat-bot/chat-panel/chat-panel.component';

import {CommonModule, isPlatformBrowser} from '@angular/common';
import {FormsModule, ReactiveFormsModule } from '@angular/forms';

import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';

import { environment } from 'src/environments/environment';
import { Session } from '../chat-bot/models/briefs-sessions-model';

@Component({
    selector: 'app-brief-pre-selection',
    standalone: true,
    templateUrl: './brief-pre-selection.component.html',
    styleUrl: './brief-pre-selection.component.sass',
    imports: [ChatPanelComponent,ReactiveFormsModule, CommonModule, FormsModule, MatIconModule,MatTooltipModule,MatButtonModule ],
})
export class BriefPreSelectionComponent {

  @Input() showAdditionalContent: boolean = true;
  @Output() assistantChosen: EventEmitter<string> = new EventEmitter();
  @Output() chatHistoryLoaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() scoringFeedbacksLoaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() contentOutlineLoaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() sessionIdLoaded: EventEmitter<string> = new EventEmitter<string>()
  @Output() updateVisibilityAndLoadSessions = new EventEmitter<string>();
  @Output() returnToDashboard = new EventEmitter<void>();
  @ViewChild('chatPanel') chatPanel: ChatPanelComponent;
  highlightedSessions: Session[] = [];
  profileSlug: string;

  ngOnChanges(changes: SimpleChanges) {
    console.log("ngOnChanges",changes);
    if (changes['showAdditionalContent'] && !this.showAdditionalContent) {
      console.log("showAdditionalContent",changes);
      this.updateVisibilityAndLoadSessions.emit(this.profileSlug);
    }
  }

  startNewChat(assistantKey: string) {
    console.log("startNewChat",assistantKey);
    this.assistantChosen.emit(assistantKey);
  }

  handleChatHistoryLoaded(data: any): void {
    this.chatHistoryLoaded.emit(data);
  }

  handleScoringFeedbacks(data: any): void {
    this.scoringFeedbacksLoaded.emit(data);
  }

  handleContentOutline(data: any): void {
    //console.log("Pre-Seleciont - HandleContentOutline",data);
    this.contentOutlineLoaded.emit(data);
  }

  handleSessionIdLoaded(sessionId: string): void {
    console.log("handleSessionIdLoaded",sessionId);
    this.sessionIdLoaded.emit(sessionId);
  }

  handleHighlightedSessionsLoaded(sessions: Session[]): void {
    this.highlightedSessions = sessions;
  }

  handleReturnFromChatPanel() {
    this.returnToDashboard.emit();
  }

  handleSessionSelected(sessionId: string): void {
    if (this.chatPanel) {
      console.log("handleSessionSelected",sessionId);
      this.chatPanel.loadSessionData(sessionId);
    } else {
      console.error('ChatPanelComponent is not yet loaded');
    }
  }  

  // method to init the chatPanel
  initializeChatPanel(): void {
    if (this.chatPanel) {
      this.chatPanel.initializeComponent();
    } else {
      console.error('ChatPanelComponent is not yet loaded');
    }
  }

}
