
<!-- isolated part start -->
<div class="comments-section">
  <div class="d-flex mt-2 mb-2">
    <mat-divider class="w-100"></mat-divider>
  </div>

    <!-- Modal -->
    <div class="modal-comment"  *ngIf="isLoading">
      <div class="modal-content">
        <div class="loading-spinner"></div>
        <p class="loading-message">
          <fa-icon [icon]="['fas', 'spinner']" animation="spin" class="mr-2"></fa-icon>Loading comments...
        </p>
      </div>
    </div>

    <!-- show loadingError -->
    <div *ngIf="loadingError" class="error-message">
      {{ loadingError }}
    </div>

    <div *ngIf="!isLoading" >

  <!-- TO-DO when we have more reactions, modify the call -->
  <div class="d-flex justify-content-between align-items-center mb-1">
    <!-- checks if there is at least one reaction before rendering the div -->
    <div *ngIf="threadDets.reactions != undefined && threadDets.reactions[0]?.quantity > 0; else spacer" class="mt-0 mb-1 d-flex align-items-center">
      <p class="small textGrey mb-0">
        <img class="me-2 red-heart" height="16px" width="16px" [src]="env.frontendURL + '/assets/img/icon-heart-sm.svg'" alt="Like" />
        {{ isCurrentUserReaction(threadDets?.reactions[0]?.reacted_by, threadDets?.current_profile?.display_name) }}
      </p>
    </div>
    <ng-template #spacer>
      <div class="flex-grow-1"></div>
    </ng-template>

    <ng-template #spacer>
      <div class="flex-grow-1"></div>
    </ng-template>

    <!-- displays the total number of comments -->
    <p class="small textGrey mb-1">{{ threadDets.comments_counter }} comment(s)</p>
  </div>

  <div class="d-flex mb-2">
    <mat-divider class="w-100"></mat-divider>
  </div>


  <div class="d-inline-flex align-items-center ms-3">
    <ng-container *ngIf="threadDets && threadDets.current_profile">
      <ng-container *ngIf="threadDets.current_profile.url_picture !== 'guy'; else initialsAvatar">
        <img width="36" height="36" [src]="threadDets.current_profile.url_picture" class="writer-icon avtr-img me-3" alt="Freelancer">
      </ng-container>
      <ng-template #initialsAvatar>
        <div class="initials-avatar me-3">
          {{ getInitials(threadDets.current_profile.display_name) }}
        </div>
      </ng-template>
    </ng-container>
    <a class="me-3" (click)="likeThread(threadDets.id)">
      <img class="me-2" width="20" height="20" [src]="env.frontendURL + '/assets/img/icon-favorite.svg'" alt="Like">
      <span>Like</span>
    </a>
    <!-- <a class="me-3"><img class="me-2" width="20" height="20" [src]="env.frontendURL + '/assets/img/icon-comment.svg'">Comment</a> -->
  </div>

  <div class="clearfix"></div>

  <!-- user adds new comments -->
  <div class="d-flex mt-3 ms-3">
    <ng-container *ngIf="threadDets && threadDets.current_profile">
      <ng-container *ngIf="threadDets.current_profile.url_picture !== 'guy'; else initialsAvatar">
        <img width="36" height="36" [src]="threadDets.current_profile.url_picture" class="writer-icon avtr-img me-3" alt="Freelancer">
      </ng-container>
      <ng-template #initialsAvatar>
        <div class="initials-avatar me-3">
          {{ getInitials(threadDets.current_profile.display_name) }}
        </div>
      </ng-template>
    </ng-container>

    <div *ngIf="!editorVisibility[mainEditorId]" #editorContainer class="container-parent w-100 relative">
      <div class="comment-box w-100" (click)="toggleEditor(mainEditorId)">
        <div class="placeholder-text">Add a comment</div>
      </div>
    </div>
    <editor class="w-100" [id]="mainEditorId" *ngIf="editorVisibility[mainEditorId]" [init]="editorConfig" apiKey="{{env.tinyKey}}"></editor>
  </div>


  <!--isolated part end-->

    <div class="comment-main">
      <ng-template #commentTemplate let-commentData="$implicit" >
        <div class="d-flex mt-3 ms-3">
          <ng-container *ngIf="commentData.author.url_picture === 'guy'; else imageTemplate">
            <div class="initials-avatar me-3">
              {{ getInitials(commentData.author.display_name) }}
            </div>
          </ng-container>
          <!-- template for when the image is available -->
          <ng-template #imageTemplate>
            <img width="36" height="36" [src]="commentData.author.url_picture" class="writer-icon me-3" alt="{{ commentData.author.display_name }}">
          </ng-template>
          <div class="d-flex flex-column w-100">
            <div class="w-100 h-auto pe-3 ps-3 border rounded-end rounded-bottom mb-2 text-field--filled-like">
              <div class="mat-mdc-form-field-flex">
                <div class="pb-2 pt-2">
                  <p class="fw-bold mt-0 mb-0">
                    <a href="#">{{commentData.author.display_name}}</a>
                  </p>
                  <p class="small textGrey mb-2">
                    {{ commentData.created_at | date:'MMMM dd, yyyy' }} - {{ commentData.created_at | date:'h:mm a' }}
                  </p>
                  <p class="m-0 p-0" *ngIf="editingComment !== commentData" [innerHTML]="commentData.content"></p>
                  <div class="mt-2 mb-2" *ngIf="editingComment === commentData">
                      <editor id="editorForEdit{{commentData.id}}" [init]="editorConfig" apiKey="{{env.tinyKey}}"></editor>
                  </div>
                </div>
              </div>
            </div>

            <!-- Actions below the comment -->
            <div class="d-inline-flex align-items-center m-0">
              <a class="me-3" (click)="likeComment(commentData)">
                {{ shouldDisplayLike(commentData) }}
                <span class="small textGrey mb-0" *ngIf="commentData?.reactions && commentData.reactions?.length > 0">
                  <img class="ms-1 me-1 red-heart" height="16px" width="16px" [src]="env.frontendURL + '/assets/img/icon-heart-sm.svg'" alt="Like" />
                  <!-- just likes for now -->
                  {{ commentData.reactions[0].quantity }}
                </span>
              </a>

              <!-- Other actions (reply, edit, delete) based on conditions -->
              <a class="me-2"  (click)="createEditor(commentData)"><span class="me-2">|</span>Reply</a>
              <a class="me-2" *ngIf="commentData.author.display_name === threadDets.current_profile.display_name && canEditOrDelete(commentData.updated_at)" (click)="startEditingComment(commentData)"><span class="me-2">|</span>Edit</a>
              <a class="me-2" *ngIf="commentData.author.display_name === threadDets.current_profile.display_name && canEditOrDelete(commentData.updated_at)" (click)="sendDeleteComment(commentData)"><span class="me-2">|</span>Delete</a>
              <a class="me-2" *ngIf="hasReplies(commentData)" (click)="toggleReplies(commentData)">
                {{ commentData.showReplies ? '|  Hide ' + ' replies'  : '|  See ' + ' replies' }}
              </a>
            </div>

            <!-- Reply editor below the comment -->
            <div class="mt-2 mb-2" *ngIf="replyingToComment === commentData">
              <editor id="editor-container-{{commentData.id}}" [init]="editorConfig" apiKey="{{env.tinyKey}}"></editor>
            </div>
          </div>
        </div>

        <!-- TO-DO use ng Container / template to send the reply in the same html code as the comments Display replies to the comment -->
        <div *ngIf="commentData.showReplies" class="reply" >
          <ng-container class="ms-5" *ngFor="let reply of commentData.replies">
        <div class="d-flex mt-3 ms-3">
          <!-- To-DO - user reply img avatar -->
          <div *ngIf="commentData.author.url_picture === 'guy'; else image" >
            <div class="reply-avatar  me-3">
              {{ commentData.authorInitials }}
            </div>
          </div>
          <ng-template #image>
            <img width="32" height="32" [src]="commentData.author.url_picture" class="writer-icon me-3" alt="{{ commentData.author.display_name }}">
          </ng-template>
          <div class="d-flex flex-column w-100">
            <div class="w-100 h-auto pe-3 ps-3 border rounded-end rounded-bottom mb-2 text-field--filled-like">
              <div class="mat-mdc-form-field-flex">
                <div class="pb-2 pt-2 reply-content">
                  <p class="fw-bold mt-0 mb-0">
                    <a href="#">{{reply.author.display_name}}</a>
                  </p>
                  <p class="small textGrey mb-2">
                    {{ reply.created_at | date:'MMMM dd, yyyy' }} - {{ reply.created_at | date:'h:mm a' }}
                  </p>
                  <!-- to-do check innerHTML -->
                  <span class="reply-content" *ngIf="editingComment !== reply">{{ stripHtml(reply.content) }}</span>
                  <div class="mt-2 mb-2" *ngIf="editingComment === reply">
                      <editor id="editorForEdit{{reply.id}}" [init]="editorConfig" apiKey="{{env.tinyKey}}"></editor>
                  </div>
                </div>
              </div>
            </div>

            <!-- Actions below the comment -->
            <div class="d-inline-flex align-items-center m-0">

              <a class="me-3" (click)="likeComment(reply)">
                {{ shouldDisplayLike(reply) }}
                <span class="small textGrey mb-0" *ngIf="reply?.reactions && reply?.reactions?.length > 0">
                  <img class="ms-1 me-1 red-heart" height="16px" width="16px" [src]="env.frontendURL + '/assets/img/icon-heart-sm.svg'" alt="Like"/>
                  <!-- just likes for now -->
                  {{ reply?.reactions[0].quantity }}
                </span>
              </a>

              <!-- Other actions (reply, edit, delete) based on conditions -->
              <a class="me-2"  (click)="createEditor(reply)"><span class="me-2">|</span>Reply</a>
              <a class="me-2" *ngIf="reply.author.display_name === threadDets.current_profile.display_name && canEditOrDelete(reply.updated_at)" (click)="startEditingComment(reply)"><span class="me-2">|</span>Edit</a>
              <a class="me-2" *ngIf="reply.author.display_name === threadDets.current_profile.display_name && canEditOrDelete(reply.updated_at)" (click)="sendDeleteComment(reply)"><span class="me-2">|</span>Delete</a>
              <a class="me-2" *ngIf="hasReplies(reply)" (click)="toggleReplies(reply)">
                {{ reply.showReplies ? '|  Hide ' + reply.replies?.length + ' replies'  : '|  See more ' + reply.replies_counter + ' replies' }}
            </a>
            </div>

            <!-- Reply editor below the comment -->
            <div class="mt-2 mb-2" *ngIf="replyingToComment === reply">
              <editor apiKey="{{env.tinyKey}}" id="editor-container-{{reply.id}}" [init]="editorConfig"></editor>
            </div>

            <!-- Display replies to the comment -->

          </div>
        </div>
      </ng-container>
      <!-- load more replies -->
      <a *ngIf="commentData.replies_counter > commentData.replies?.length" (click)="onShowMoreReplies(commentData)" class="ms-3 load-more-replies">
        <mat-icon class="mr-1">expand_more</mat-icon>Load more replies
      </a>
      </div>
      </ng-template>
  </div>
    <!-- Initial call to display all main comments -->
    <ng-container *ngIf="!isLoading && !loadingError">
      <ng-container *ngFor="let comment of listComments">
        <ng-container *ngTemplateOutlet="commentTemplate; context: {$implicit: comment}"></ng-container>
      </ng-container>
    </ng-container>
    <!-- load more comments -->
    <div *ngIf="!allCommentsLoaded" class="load-more-comments">
      <button (click)="fetchComments()">Load more comments</button>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
