<h2 mat-dialog-title class="dialog-title mb-2 text-secondary">Save New Version</h2>
<mat-dialog-content class="dialog-content px-4 py-3">
  <mat-form-field appearance="fill" class="full-width-field">
    <mat-label>Version Name</mat-label>
    <input matInput [(ngModel)]="versionName" class="form-control">
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end" class="dialog-actions justify-content-end p-3">
    <button mat-raised-button mat-dialog-close class="btn-cancel">Cancel</button>
    <button mat-raised-button [mat-dialog-close]="versionName" cdkFocusInitial class="btn-save">Save</button>    
</mat-dialog-actions>
