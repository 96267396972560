<!-- progress indicators -->
<!-- <app-progress-indicators [isLoading]="isProgressLoading"
                         [tasks]="progressTasks"></app-progress-indicators> -->
<div [hidden]="!newSuggestionsloading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

                         


<div class="suggestions-panel 11mt-2" [class.open]="isPanelOpen" *ngIf="isPanelOpen">           
  <!-- title suggestions start -->
  <div *ngIf="currentSelectedSectionId === 'title'">
    <div class="alternatives-container">
      <div class="custom-tag">
          <span class="section-name">Title:</span> 
          <span class="alternative-text">Alternatives</span>
      </div>
      <div *ngIf="loadingAlternatives.title">
        <mat-spinner diameter="20"></mat-spinner>
      </div>
      <button *ngIf="!loadingAlternatives.title" mat-icon-button matTooltip="Reload all suggestions" color="accent" (click)="onRefreshClick('title', selectedTitle, 'refresh_options')">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
    <div class="chat-option-widget" *ngIf="!loadingAlternatives.title">
      <div *ngFor="let titleOption of savedTitles; let i = index"
        class="chat-option-list d-flex align-items-center justify-content-between" (click)="selectedTitle = titleOption" 
        (click)="confirmSuggestionsChange('title', titleOption, 'new_option')" [class.option-selected-background]="selectedTitle === titleOption">
        <span class="flex-grow-1">{{ titleOption }}</span>
        <div class="options-icon-container ms-2" (click)="copyText($event, titleOption)">
          <img class="show-on-hover" [src]="frontendServURL + '/assets/img/icon-copy.svg'" matTooltip="Copy" matTooltipPosition="above" alt="copy Icon">
        </div>
      </div>
    </div>
    <div *ngIf="savedTitles.length === 0"  no-suggestions-container>
      <p class="no-suggestions-text">No suggestions available</p>
    </div>
  </div>
  <!-- title suggestions end -->

  <!-- primary keyword start -->
  <div *ngIf="currentSelectedSectionId === 'primary-keyword'">
    <div class="alternatives-container">
      <div class="custom-tag">
          <span class="section-name">Primary Keyword:</span> 
          <span class="alternative-text">Alternatives</span>
      </div>
      <div *ngIf="loadingAlternatives.primaryKeyword">
        <mat-spinner diameter="20"></mat-spinner>
      </div>
      <button *ngIf="!loadingAlternatives.primaryKeyword" mat-icon-button matTooltip="Reload all suggestions" color="accent" (click)="onRefreshClick('primaryKeyword', reciveSelectedPrimaryKeywords, 'refresh_options')">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
    <div class="chat-option-widget" *ngIf="!loadingAlternatives.primaryKeyword">
      <div *ngFor="let mainKeyword of savedPrimaryKeywords; let i = index"
        class="chat-option-list d-flex align-items-center justify-content-between" (click)="reciveSelectedPrimaryKeywords = mainKeyword"
        (click)="confirmSuggestionsChange('primaryKeyword', mainKeyword , 'new_option')" [class.option-selected-background]="mainKeyword === reciveSelectedPrimaryKeywords">
        <span class="flex-grow-1">{{ mainKeyword }}</span>
        <div class="options-icon-container ms-2" (click)="copyText($event, mainKeyword)">
          <img class="show-on-hover" [src]="frontendServURL + '/assets/img/icon-copy.svg'" matTooltip="Copy" matTooltipPosition="above" alt="copy Icon">
        </div>
      </div>
    </div>
    <div *ngIf="savedPrimaryKeywords.length === 0"  no-suggestions-container>
      <p class="no-suggestions-text">No suggestions available</p>
    </div>
  </div>
  <!-- primary keyword end -->

  <!-- secondary keywords start -->
  <div *ngIf="currentSelectedSectionId === 'secondary-keywords'">
    <div class="alternatives-container">
      <div class="custom-tag">
          <span class="section-name">Secondary Keywords:</span> 
          <span class="alternative-text">Alternatives</span>
      </div>
      <div *ngIf="loadingAlternatives.secondaryKeywords">
        <mat-spinner diameter="20"></mat-spinner>
      </div>
      <button *ngIf="!loadingAlternatives.secondaryKeywords" mat-icon-button class="ms-2" matTooltip="Reload all suggestions" color="accent" 
      (click)="onRefreshClick('secondaryKeywords', reciveSelectedSecondaryKeywords, 'refresh_options')">
        <mat-icon>refresh</mat-icon>
    </button>
    </div>
    <div class="chat-option-widget" *ngIf="!loadingAlternatives.secondaryKeywords">
      <div *ngFor="let secondaryKeyword of savedSecondaryKeywords; let i = index; let last = last"
        class="chat-option-list d-flex align-items-center justify-content-between py-1"
        (click)="toggleSecondaryKeywordsSelection(secondaryKeyword)" [class.option-selected-background]="isSecondaryKeywordsSelected(secondaryKeyword)">
        <span class="flex-grow-1">{{ secondaryKeyword }}</span>
        <div class="options-icon-container ms-2" (click)="copyText($event, secondaryKeyword)">
          <img class="show-on-hover" [src]="frontendServURL + '/assets/img/icon-copy.svg'" matTooltip="Copy" matTooltipPosition="above" alt="copy Icon">
        </div>
      </div>
    </div>

    <div *ngIf="savedSecondaryKeywords.length === 0"  no-suggestions-container>
      <p class="no-suggestions-text">No suggestions available</p>
    </div>

    <div class="panel-footer d-flex justify-content-start align-items-center" *ngIf="!loadingAlternatives.secondaryKeywords">
      <!-- Button for sending selected options -->
      <button *ngIf="savedSecondaryKeywords.length > 0" mat-raised-button class="send-options-button" (click)="sendSelectedSecondaryOptions('secondaryKeywords', 'new_option')">
        <mat-icon class="m-0">send</mat-icon><span class="hidden-wordings-sgstn ms-1">Send selected options</span>
      </button>
    </div>
  </div>
  <!-- secondary keywords end -->

  <!-- meta description panel start -->
  <div *ngIf="currentSelectedSectionId === 'meta-description'">
    <div class="alternatives-container">
      <div class="custom-tag">
          <span class="section-name">Meta Description:</span> 
          <span class="alternative-text">Alternatives</span>
      </div>
      <div *ngIf="loadingAlternatives.metaDescription">
        <mat-spinner diameter="20"></mat-spinner>
      </div>
      <button *ngIf="!loadingAlternatives.metaDescription"  mat-icon-button matTooltip="Reload all suggestions" color="accent" (click)="onRefreshClick('metaDescription', reciveSelectedMetaDescription, 'refresh_options')">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
    <div class="chat-option-widget" *ngIf="!loadingAlternatives.metaDescription">
      <div *ngFor="let metaDescription of savedMetaDescription; let i = index"
      (click)="confirmSuggestionsChange('metaDescription', metaDescription, 'new_option')" class="chat-option-list d-flex align-items-center justify-content-between" (click)="reciveSelectedMetaDescription = metaDescription"
        [class.option-selected-background]="metaDescription === reciveSelectedMetaDescription">
        <span class="flex-grow-1">{{ metaDescription }}</span>
        <div class="options-icon-container ms-2" (click)="copyText($event, metaDescription)">
          <img class="show-on-hover" [src]="frontendServURL + '/assets/img/icon-copy.svg'" matTooltip="Copy" matTooltipPosition="above" alt="copy Icon">
        </div>
      </div>
    </div>
    <div *ngIf="savedMetaDescription.length === 0"  no-suggestions-container>
      <p class="no-suggestions-text">No suggestions available</p>
    </div>
  </div>
  <!-- meta description end -->

  <!-- word count panel start -->
  <div *ngIf="currentSelectedSectionId === 'word-count'">
    <div class="alternatives-container">
      <div class="custom-tag">
          <span class="section-name">Word Count:</span> 
          <span class="alternative-text">Alternatives</span>
      </div>
      <div *ngIf="loadingAlternatives.wordCount">
        <mat-spinner diameter="20"></mat-spinner>
      </div>
      <button *ngIf="!loadingAlternatives.wordCount" mat-icon-button matTooltip="Reload all suggestions" color="accent" (click)="onRefreshClick('wordCount', reciveSelectedWordCount, 'refresh_options')">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
    <div class="chat-option-widget" *ngIf="!loadingAlternatives.wordCount">
      <div *ngFor="let wordCount of savedWordCount; let i = index"
      (click)="confirmSuggestionsChange('wordCount', wordCount, 'new_option')" class="chat-option-list d-flex align-items-center justify-content-between" (click)="reciveSelectedWordCount = wordCount"
        [class.option-selected-background]="wordCount === reciveSelectedWordCount">
        <span class="flex-grow-1">{{ wordCount }}</span>
        <div class="options-icon-container ms-2" (click)="copyText($event, wordCount)">
          <img class="show-on-hover" [src]="frontendServURL + '/assets/img/icon-copy.svg'" matTooltip="Copy" matTooltipPosition="above" alt="copy Icon">
        </div>
      </div>
    </div>
    <div *ngIf="savedWordCount.length === 0"  no-suggestions-container>
      <p class="no-suggestions-text">No suggestions available</p>
    </div>
  </div>
 <!-- word count panel end -->

 <!-- tone of voice panel start -->
 <div *ngIf="currentSelectedSectionId === 'tone-of-voice'">
  <div class="alternatives-container">
    <div class="custom-tag">
        <span class="section-name">Voice and Tone:</span> 
        <span class="alternative-text">Alternatives</span>
    </div>
    <div *ngIf="loadingAlternatives.voiceAndTone">
      <mat-spinner diameter="20"></mat-spinner>
    </div>
    <button *ngIf="!loadingAlternatives.voiceAndTone" mat-icon-button matTooltip="Reload all suggestions" color="accent" (click)="onRefreshClick('tone-of-voice', reciveSelectedToneOfVoice, 'refresh_options')">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
  <div class="chat-option-widget" *ngIf="!loadingAlternatives.voiceAndTone">
    <div *ngFor="let toneOfVoice of savedToneOfVoice; let i = index"
      (click)="confirmSuggestionsChange('toneOfVoice', toneOfVoice, 'new_option')" class="chat-option-list d-flex align-items-center justify-content-between" (click)="reciveSelectedToneOfVoice = toneOfVoice"
      [class.option-selected-background]="toneOfVoice === reciveSelectedToneOfVoice">
      <span class="flex-grow-1">{{ toneOfVoice }}</span>
      <div class="options-icon-container ms-2" (click)="copyText($event, toneOfVoice)">
        <img class="show-on-hover" [src]="frontendServURL + '/assets/img/icon-copy.svg'" matTooltip="Copy" matTooltipPosition="above" alt="copy Icon">
      </div>
    </div>
  </div>
  <div *ngIf="savedToneOfVoice.length === 0"  no-suggestions-container>
    <p class="no-suggestions-text">No suggestions available</p>
  </div>
</div>
<!-- tone of voice panel end -->

<!--content goal panel start -->
<div *ngIf="currentSelectedSectionId === 'content-goal'">
  <div class="alternatives-container">
    <div class="custom-tag">
        <span class="section-name">Content Goal:</span> 
        <span class="alternative-text">Alternatives</span>
    </div>
    <div *ngIf="loadingAlternatives.contentGoal">
      <mat-spinner diameter="20"></mat-spinner>
    </div>
    <button *ngIf="!loadingAlternatives.contentGoal" mat-icon-button matTooltip="Reload all suggestions" color="accent" (click)="onRefreshClick('content-goal', reciveSelectedContentGoal, 'refresh_options')">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
  <div class="chat-option-widget" *ngIf="!loadingAlternatives.contentGoal">
    <div *ngFor="let contentGoal of savedContentGoal; let i = index"
    (click)="confirmSuggestionsChange('contentGoal', contentGoal, 'new_option')" class="chat-option-list d-flex align-items-center justify-content-between" (click)="reciveSelectedContentGoal = contentGoal"
      [class.option-selected-background]="contentGoal === reciveSelectedContentGoal">
      <span class="flex-grow-1">{{ contentGoal }}</span>
      <div class="options-icon-container ms-2" (click)="copyText($event, contentGoal)">
        <img class="show-on-hover" [src]="frontendServURL + '/assets/img/icon-copy.svg'" matTooltip="Copy" matTooltipPosition="above" alt="copy Icon">
      </div>
    </div>
  </div>
  <div *ngIf="savedContentGoal.length === 0"  no-suggestions-container>
    <p class="no-suggestions-text">No suggestions available</p>
  </div>
</div>
<!-- content goal panel end -->

<!--point of view panel start -->
<div *ngIf="currentSelectedSectionId === 'point-of-view'">
  <div class="alternatives-container">
    <div class="custom-tag">
        <span class="section-name">Point of View:</span> 
        <span class="alternative-text">Alternatives</span>
    </div>
    <div *ngIf="loadingAlternatives.pointOfView">
      <mat-spinner diameter="20"></mat-spinner>
    </div>
    <button *ngIf="!loadingAlternatives.pointOfView" mat-icon-button  matTooltip="Reload all suggestions" color="accent" (click)="onRefreshClick('point-of-view', reciveSelectedPov, 'refresh_options')">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
  <div class="chat-option-widget" *ngIf="!loadingAlternatives.pointOfView">
    <div *ngFor="let pointOfView of savedPov; let i = index"
    (click)="confirmSuggestionsChange('pointOfView', pointOfView, 'new_option')" class="chat-option-list d-flex align-items-center justify-content-between" (click)="reciveSelectedPov = pointOfView"
      [class.option-selected-background]="pointOfView === reciveSelectedPov">
      <span class="flex-grow-1">{{ pointOfView }}</span>
      <div class="options-icon-container ms-2" (click)="copyText($event, pointOfView)">
        <img class="show-on-hover" [src]="frontendServURL + '/assets/img/icon-copy.svg'" matTooltip="Copy" matTooltipPosition="above" alt="copy Icon">
      </div>
    </div>
  </div>
  <div *ngIf="savedContentGoal.length === 0"  no-suggestions-container>
    <p class="no-suggestions-text">No suggestions available</p>
  </div>
</div>
<!-- point of view panel end -->

<!--business objective panel start -->
<div *ngIf="currentSelectedSectionId === 'business-objective'">
  <div class="alternatives-container">
    <div class="custom-tag">
        <span class="section-name">Business Objective:</span> 
        <span class="alternative-text">Alternatives</span>
    </div>
    <div *ngIf="loadingAlternatives.businessObjective">
      <mat-spinner diameter="20"></mat-spinner>
    </div>
    <button *ngIf="!loadingAlternatives.businessObjective" mat-icon-button  matTooltip="Reload all suggestions" color="accent" (click)="onRefreshClick('business-objective', reciveSelectedBusinessObjective, 'refresh_options')">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
  <div class="chat-option-widget" *ngIf="!loadingAlternatives.businessObjective">
    <div *ngFor="let businessObjective of savedBusinessObjective; let i = index"
    (click)="confirmSuggestionsChange('businessObjective', businessObjective, 'new_option')" class="chat-option-list d-flex align-items-center justify-content-between" (click)="reciveSelectedBusinessObjective = businessObjective"
      [class.option-selected-background]="businessObjective === reciveSelectedBusinessObjective">
      <span class="flex-grow-1">{{ businessObjective }}</span>
      <div class="options-icon-container ms-2" (click)="copyText($event, businessObjective)">
        <img class="show-on-hover" [src]="frontendServURL + '/assets/img/icon-copy.svg'" matTooltip="Copy" matTooltipPosition="above" alt="copy Icon">
      </div>
    </div>
  </div>
  <div *ngIf="savedBusinessObjective.length === 0"  no-suggestions-container>
    <p class="no-suggestions-text">No suggestions available</p>
  </div>
</div>
<!-- business objective panel end -->

  <!-- CTA panel start -->
 <div *ngIf="currentSelectedSectionId === 'cta'">
  <div class="alternatives-container">
    <div class="custom-tag">
        <span class="section-name">CTA:</span> 
        <span class="alternative-text">Alternatives</span>
    </div>
    <div *ngIf="loadingAlternatives.cta">
      <mat-spinner diameter="20"></mat-spinner>
    </div>
    <button *ngIf="!loadingAlternatives.cta" mat-icon-button matTooltip="Reload all suggestions" color="accent" (click)="onRefreshClick('cta', reciveSelectedCta, 'refresh_options')">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
  <div class="chat-option-widget" *ngIf="!loadingAlternatives.cta">
    <div *ngFor="let cta of saveddCta; let i = index"
    (click)="confirmSuggestionsChange('cta', cta, 'new_option')" class="chat-option-list d-flex align-items-center justify-content-between" (click)="reciveSelectedCta = cta"
      [class.option-selected-background]="cta === reciveSelectedCta">
      <span class="flex-grow-1">{{ cta }}</span>
      <div class="options-icon-container ms-2" (click)="copyText($event, cta)">
        <img class="show-on-hover" [src]="frontendServURL + '/assets/img/icon-copy.svg'" matTooltip="Copy" matTooltipPosition="above" alt="copy Icon">
      </div>
    </div>
  </div>
  <div *ngIf="saveddCta.length === 0"  no-suggestions-container>
    <p class="no-suggestions-text">No suggestions available</p>
  </div>
</div>
<!-- CTA panel end -->


<!-- internal links start -->
<div *ngIf="currentSelectedSectionId === 'internal-links'">
  <div class="alternatives-container">
    <div class="custom-tag">
        <span class="section-name">Links to Include:</span> 
        <span class="alternative-text">Alternatives</span>
    </div>
    <div *ngIf="loadingAlternatives.internalLinks">
      <mat-spinner diameter="20"></mat-spinner>
    </div>
    <button *ngIf="!loadingAlternatives.internalLinks" mat-icon-button class="ms-2" matTooltip="Reload all suggestions" color="accent" 
    (click)="onRefreshClick('internalLinks', reciveSelectedInternalLinks, 'refresh_options')">
      <mat-icon>refresh</mat-icon>
  </button>
  </div>
  <div class="chat-option-widget" *ngIf="!loadingAlternatives.internalLinks">
    <div *ngFor="let internalLinks of savedInternalLinks; let i = index; let last = last"
      class="chat-option-list d-flex align-items-center justify-content-between py-1"
      (click)="toggleInternalLinksSelection(internalLinks)" [class.option-selected-background]="isInternalLinksSelected(internalLinks)">
      <span class="flex-grow-1">{{ internalLinks }}</span>
      <div class="options-icon-container ms-2" (click)="copyText($event, internalLinks)">
        <img class="show-on-hover" [src]="frontendServURL + '/assets/img/icon-copy.svg'" matTooltip="Copy" matTooltipPosition="above" alt="copy Icon">
      </div>
    </div>
  </div>

  <div *ngIf="savedInternalLinks.length === 0"  no-suggestions-container>
    <p class="no-suggestions-text">No suggestions available</p>
  </div>


  <div class="panel-footer d-flex justify-content-start align-items-center" *ngIf="!loadingAlternatives.internalLinks">
    <!-- Button for sending selected options -->
    <button *ngIf="savedInternalLinks.length > 0" mat-raised-button class="send-options-button" (click)="sendSelectedInternalLinksOptions('internalLinks', 'new_option')">
      <mat-icon class="m-0">send</mat-icon><span class="hidden-wordings-sgstn ms-1">Send selected options</span>
    </button>
  </div>
</div>
<!-- internal links end -->

<!-- key points start -->
<div *ngIf="currentSelectedSectionId === 'key-points'">
  <div class="alternatives-container">
    <div class="custom-tag">
        <span class="section-name">Key Points:</span> 
        <span class="alternative-text">Alternatives</span>
    </div>
    <div *ngIf="loadingAlternatives.keyPoints">
      <mat-spinner diameter="20"></mat-spinner>
    </div>
    <button *ngIf="!loadingAlternatives.keyPoints" mat-icon-button class="ms-2" matTooltip="Reload all suggestions" color="accent" 
    (click)="onRefreshClick('keyPoints', reciveSelectedInternalLinks, 'refresh_options')">
      <mat-icon>refresh</mat-icon>
  </button>
  </div>
  <div class="chat-option-widget" *ngIf="!loadingAlternatives.keyPoints">
    <div *ngFor="let keyPoints of savedKeyPoints; let i = index; let last = last"
      class="chat-option-list d-flex align-items-center justify-content-between py-1"
      (click)="toggleKeyPointsSelection(keyPoints)" [class.option-selected-background]="isKeyPointsSelected(keyPoints)">
      <span class="flex-grow-1">{{ keyPoints }}</span>
      <div class="options-icon-container ms-2" (click)="copyText($event, keyPoints)">
        <img class="show-on-hover" [src]="frontendServURL + '/assets/img/icon-copy.svg'" matTooltip="Copy" matTooltipPosition="above" alt="copy Icon">
      </div>
    </div>
  </div>

  <div *ngIf="savedKeyPoints.length === 0"  no-suggestions-container>
    <p class="no-suggestions-text">No suggestions available</p>
  </div>


  <div class="panel-footer d-flex justify-content-start align-items-center" *ngIf="!loadingAlternatives.keyPoints">
    <!-- Button for sending selected options -->
    <button  *ngIf="savedKeyPoints.length > 0" mat-raised-button class="send-options-button" (click)="sendSelectedKeyPointsOptions('keyPoints', 'new_option')">
      <mat-icon class="m-0">send</mat-icon><span class="hidden-wordings-sgstn ms-1">Send selected options</span>
    </button>
  </div>
</div>
<!-- key points end -->


<!-- things to avois start -->
<div *ngIf="currentSelectedSectionId === 'things-to-avoid'">
  <div class="alternatives-container">
    <div class="custom-tag">
        <span class="section-name">Things to Avoid:</span> 
        <span class="alternative-text">Alternatives</span>
    </div>
    <div *ngIf="loadingAlternatives.thingsToAvoid">
      <mat-spinner diameter="20"></mat-spinner>
    </div>
    <button *ngIf="!loadingAlternatives.thingsToAvoid" mat-icon-button class="ms-2" matTooltip="Reload all suggestions" color="accent" 
      (click)="onRefreshClick('thingsToAvoid', reciveSelectedInternalLinks, 'refresh_options')">
        <mat-icon>refresh</mat-icon>
    </button>
  </div>
  <div class="chat-option-widget" *ngIf="!loadingAlternatives.thingsToAvoid">
    <div *ngFor="let thingsToAvoid of savedThingsToAvoid; let i = index; let last = last"
      class="chat-option-list d-flex align-items-center justify-content-between py-1"
      (click)="toggleThingsToAvoidSelection(thingsToAvoid)" [class.option-selected-background]="isThingsToAvoidSelected(thingsToAvoid)">
      <span class="flex-grow-1">{{ thingsToAvoid }}</span>
      <div class="options-icon-container ms-2" (click)="copyText($event, thingsToAvoid)">
        <img class="show-on-hover" [src]="frontendServURL + '/assets/img/icon-copy.svg'" matTooltip="Copy" matTooltipPosition="above" alt="copy Icon">
      </div>
    </div>
  </div>

  <div *ngIf="savedThingsToAvoid.length === 0"  no-suggestions-container>
    <p class="no-suggestions-text">No suggestions available</p>
  </div>


  <div class="panel-footer d-flex justify-content-start align-items-center" *ngIf="!loadingAlternatives.thingsToAvoid">
    <!-- Button for sending selected options -->
    <button *ngIf="savedThingsToAvoid.length > 0"  mat-raised-button class="send-options-button" (click)="sendSelectedThingsToAvoidOptions('thingsToAvoid', 'new_option')">
      <mat-icon class="m-0">send</mat-icon><span class="hidden-wordings-sgstn ms-1">Send selected options</span>
    </button>
  </div>
</div>
<!-- things to avois end -->


<!-- audience panel start -->
<div *ngIf="currentSelectedSectionId === 'audience'">
  <div class="alternatives-container">
    <div class="custom-tag">
        <span class="section-name">Target Audience::</span> 
        <span class="alternative-text">Alternatives</span>
    </div>
    <div *ngIf="loadingAlternatives.targetAudience">
      <mat-spinner diameter="20"></mat-spinner>
    </div>
    <button *ngIf="!loadingAlternatives.targetAudience" mat-icon-button matTooltip="Reload all suggestions" matTooltipPosition="before" color="accent" (click)="onRefreshClick('audience', reciveSelectedAudience, 'refresh_options')">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
  <div class="chat-option-widget" *ngIf="!loadingAlternatives.targetAudience">
    <div *ngFor="let audience of savedAudienceInsights; let i = index"
      class="chat-option-list d-flex align-items-center justify-content-between" (click)="reciveSelectedAudience = audience" 
      (click)="confirmSuggestionsChange('audience', audience, 'new_option')" [class.option-selected-background]="reciveSelectedAudience === audience">
      <span class="flex-grow-1">{{ audience }}</span>
      <div class="options-icon-container ms-2" (click)="copyText($event, audience)">
        <img class="show-on-hover" [src]="frontendServURL + '/assets/img/icon-copy.svg'" matTooltip="Copy" matTooltipPosition="above" alt="copy Icon">
      </div>
    </div>
  </div>
  <div *ngIf="savedAudienceInsights.length === 0"  no-suggestions-container>
    <p class="no-suggestions-text">No suggestions available</p>
  </div>
</div>
<!-- audience panel end -->


<!-- special instructions start -->
<div *ngIf="currentSelectedSectionId === 'special-instructions'">
  <div class="alternatives-container">
    <div class="custom-tag">
        <span class="section-name">Suggested Instructions:</span> 
        <span class="alternative-text">Alternatives</span>
    </div>
    <button mat-icon-button class="ms-2" matTooltip="Reload all suggestions" color="accent" (click)="onRefreshClick('specialInstructions', reciveSelectedSpecialInstructions, 'refresh_options')">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
  <div class="chat-option-widget">
    <div *ngFor="let specialInstructions of savedSpecialInstructions; let i = index; let last = last"
      class="chat-option-list d-flex align-items-center justify-content-between py-1"
      (click)="toggleSpecialInstructionsSelection(specialInstructions)" [class.option-selected-background]="isSpecialInstructionsSelected(specialInstructions)">
      <span class="flex-grow-1">{{ specialInstructions }}</span>
      <div class="options-icon-container ms-2" (click)="copyText($event, specialInstructions)">
        <img class="show-on-hover" [src]="frontendServURL + '/assets/img/icon-copy.svg'" matTooltip="Copy" matTooltipPosition="above" alt="copy Icon">
      </div>
    </div>
  </div>

  <div *ngIf="savedSpecialInstructions.length === 0"  no-suggestions-container>
    <p class="no-suggestions-text">No suggestions available</p>
  </div>


  <div class="panel-footer d-flex justify-content-start align-items-center">
    <!-- Button for sending selected options -->
    <button *ngIf="savedSpecialInstructions.length > 0" mat-raised-button class="send-options-button" (click)="sendSelectedSpecialInstructionsOptions('specialInstructions', 'new_option')">
      <mat-icon class="m-0">send</mat-icon><span class="hidden-wordings-sgstn ms-1">Send selected options</span>
    </button>
  </div>
</div>
<!-- special instructions end -->

<!-- serp analysis start -->
<div *ngIf="currentSelectedSectionId === 'serp-analysis'">
  <div class="alternatives-container">
    <div class="custom-tag">
        <span class="section-name">SERP Analysis:</span> 
        <span class="alternative-text">Alternatives</span>
    </div>
    <button mat-icon-button matTooltip="Reload all suggestions" color="accent" (click)="onRefreshClick('serpAnalysis', reciveSelectedSerpAnalysis, 'refresh_options')">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
  <div class="chat-option-widget">
    <div *ngFor="let serpAnalysis of savedSerpAnalysis; let i = index"
    (click)="confirmSuggestionsChange('serpAnalysis', serpAnalysis, 'new_option')" class="chat-option-list d-flex align-items-center justify-content-between" (click)="reciveSelectedSerpAnalysis = serpAnalysis"
      [class.option-selected-background]="serpAnalysis === reciveSelectedSerpAnalysis">
      <span class="flex-grow-1">{{ serpAnalysis }}</span>
      <div class="options-icon-container ms-2" (click)="copyText($event, serpAnalysis)">
        <img class="show-on-hover" [src]="frontendServURL + '/assets/img/icon-copy.svg'" matTooltip="Copy" matTooltipPosition="above" alt="copy Icon">
      </div>
    </div>
  </div>
  <div *ngIf="savedInternalLinks.length === 0"  no-suggestions-container>
    <p class="no-suggestions-text">No suggestions available</p>
  </div>
</div>
<!-- serp analysis end -->

<!-- differentation oppotunities start -->
<div *ngIf="currentSelectedSectionId === 'differentiation-opportunities'">
  <div class="alternatives-container">
    <div class="custom-tag">
        <span class="section-name">Opportunities:</span> 
        <span class="alternative-text">Alternatives</span>
    </div>
    <div *ngIf="loadingAlternatives.differentiationOpportunities">
      <mat-spinner diameter="20"></mat-spinner>
    </div>
    <button *ngIf="!loadingAlternatives.differentiationOpportunities"  mat-icon-button class="ms-2" matTooltip="Reload all suggestions" color="accent" (click)="onRefreshClick('differentiationOpportunities', reciveSelectedOpportunities, 'refresh_options')">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
  <div class="chat-option-widget" *ngIf="!loadingAlternatives.differentiationOpportunities">
    <div *ngFor="let differentationOppotunities of savedOpportunities; let i = index; let last = last"
      class="chat-option-list d-flex align-items-center justify-content-between py-1"
      (click)="toggleDifferentationOppotunitiesSelection(differentationOppotunities)" [class.option-selected-background]="isDifferentationOppotunitiesSelected(differentationOppotunities)">
      <span class="flex-grow-1">{{ differentationOppotunities }}</span>
      <div class="options-icon-container ms-2" (click)="copyText($event, differentationOppotunities)">
        <img class="show-on-hover" [src]="frontendServURL + '/assets/img/icon-copy.svg'" matTooltip="Copy" matTooltipPosition="above" alt="copy Icon">
      </div>
    </div>
  </div>

  <div *ngIf="savedOpportunities.length === 0"  no-suggestions-container>
    <p class="no-suggestions-text">No suggestions available</p>
  </div>


  <div class="panel-footer d-flex justify-content-start align-items-center" *ngIf="!loadingAlternatives.differentiationOpportunities">
    <!-- Button for sending selected options -->
    <button *ngIf="savedOpportunities.length > 0" mat-raised-button class="send-options-button" (click)="sendSelecteddifferentationOppotunitiesOptions('differentiationOpportunities', 'new_option')">
      <mat-icon class="m-0">send</mat-icon><span class="hidden-wordings-sgstn ms-1">Send selected options</span>
    </button>
  </div>
</div>
<!-- differentation oppotunities end -->
</div>

<div *ngIf="!isPanelOpen">
  <div class="outline-container">
    <div *ngIf="outlineItems.length > 0" class="outline-title">Outline</div>
    <ul class="outline-list">
      <li *ngFor="let item of outlineItems; let i = index"
          [class.selected]="item.selected"
          (mouseenter)="item.hover=true" 
          (mouseleave)="item.hover=false"
          (click)="toggleSelection(item)">
        <span *ngIf="item.selected">– </span>{{ item.text }}
        <span *ngIf="item.content">{{ item.content }}</span>
        <span class="remove-icon" *ngIf="item.hover" (click)="removeFromOutline(i)" matTooltip="Remove from outline">×</span>
      </li>
    </ul>
  </div>
</div>
