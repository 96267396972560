import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Constants } from '../../_shared/constants';

import { CreateComment, CreateCommentResponse } from '../models/create-comment-model';
import { UpdateComment } from '../models/update-comment-model';
import { ListCommentsResponse } from '../models/list-comments';
import { ThreadDetsRes } from '../models/api_responses/thread-dets-res-model';

@Injectable({
  providedIn: 'root'
})
export class CommentsService
{
  URL:string
  headers: any

  constructor(public http: HttpClient)
  {
    this.URL = environment.inApiGateway

    this.URL += `/${Constants.urls.accountsService.service}/${Constants.urls.accountsService.comments}`;

    this.headers = new HttpHeaders({'spinner': 'true'});
  }

  getThreadDets(context: string, rel_ext_type: string, rel_ext_id: string):Observable<ThreadDetsRes>
  {
    console.log('exec service', this.URL)
    let params =
    {
      "context" : context,
      "rel_ext_type" : rel_ext_type,
      "rel_ext_id" : rel_ext_id
    }
    let res = this.http.get<ThreadDetsRes>(this.URL+"/thread",{params:params});
    //console.log('exec ', res)
    return res;
  }

  createComment(commentData: CreateComment): Observable<CreateCommentResponse>
  {
    return this.http.post<CreateCommentResponse>(this.URL, commentData);
  }

  getComments(context: string, rel_ext_type: string, rel_ext_id: string, limit: string, skip: string): Observable<ListCommentsResponse>
  {
    let params =
    {
      "context" : context,
      "rel_ext_type" : rel_ext_type,
      "rel_ext_id" : rel_ext_id,
      "limit" : limit,
      "skip" : skip
    }
    return this.http.get<ListCommentsResponse>(this.URL,{params:params});
  }

  updateComment(updateComment: UpdateComment)
  {
    return this.http.put(this.URL, updateComment);
  }

  deleteComment(commentId: string, token: string): Observable<any>
  {
    const url = `${this.URL}/${commentId}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    return this.http.delete(url, { headers: headers });
  }

  likeReaction(relType: string, commentId: string, token: string): Observable<any> {
    const url = `${this.URL}/reactions`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    const body = {
      rel_type: relType,
      rel_id: commentId,
      type: 'like'
    };
    return this.http.post(url, body, { headers: headers });
  }


  getCommentReplies(commentId: string, limit: number, skip: number): Observable<ListCommentsResponse> {
    const params = new HttpParams()
      .set('comment_id', commentId)
      .set('limit', limit.toString())
      .set('skip', skip.toString());

    return this.http.get<ListCommentsResponse>(`${this.URL}/replies`, { params: params });
  }

}
