import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgCircleProgressModule, CircleProgressOptions } from 'ng-circle-progress';



@NgModule({
  imports: [
    CommonModule,
    NgCircleProgressModule.forRoot({
      backgroundOpacity: 1,
      backgroundStrokeWidth: 4,
      backgroundStroke: "#e0e0e0",
      outerStrokeLinecap: "square",
      backgroundPadding: -2,
      radius: 25,
      space: -4, 
      toFixed: 0,
      maxPercent: 100, 
      outerStrokeWidth: 4,
      outerStrokeColor: "#6bc06f",
      outerStrokeGradientStopColor: "#65e697",
      showTitle: false,
      showSubtitle: false,
      showUnits: false,
      showInnerStroke: false, 
      clockwise: true,
      showZeroOuterStroke: false,
      animation: true,
      outerStrokeGradient: true,

      animationDuration: 300,
    })
  ],
  exports: [
    NgCircleProgressModule
  ]
})
export class CircleProgressModule {
  static forRoot(options?: CircleProgressOptions): ModuleWithProviders<CircleProgressModule> {
    return {
      ngModule: CircleProgressModule,
      providers: [
        { provide: 'circleProgressOptions', useValue: options }
      ]
    };
  }
}
