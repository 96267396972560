import {Component, OnInit, OnDestroy, PLATFORM_ID, afterNextRender, inject, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter, Input, AfterViewChecked} from '@angular/core';
import {CommonModule, isPlatformBrowser, isPlatformServer} from '@angular/common';
import {FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { marked } from 'marked';
import { Title } from '@angular/platform-browser';
import { SpinnerHandlerService } from 'src/app/_shared/services/spinner-handler.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup } from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import {MatRadioModule} from '@angular/material/radio';
import { Constants } from 'src/app/_shared/constants';
import { MatDialog } from '@angular/material/dialog';
import { BoldMarkDownPipe } from '../bold-mark-down.pipe';



import { AssistantManagerService } from './services/assistant-manager-service.service';
import { SharedDataChatService } from './services/shared-data-chat.service';
import { AssistantForm, AssistantResponse, AssistantResponseDetails } from './models/brief-assistant-model';

import { ChatMessage } from './models/chat-message-model';
import { ChatPanelComponent } from "./chat-panel/chat-panel.component";
import { SafeHtmlPipe } from './safe-html.pipe';
import { Subscription, interval } from 'rxjs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatChipsModule} from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { SuggestionUpdate } from './models/suggestions-update';
import { AssistantInfoModalComponent } from '../assistant-info-modal/assistant-info-modal.component';
import { AssistantPreSelection } from './models/assistant-pre-selection-model';
import { environment } from 'src/environments/environment';
import { BriefPreSelectionComponent } from '../brief-pre-selection/brief-pre-selection.component';



@Component({
    selector: 'app-chat-bot',
    templateUrl: './chat-bot.component.html',
    styleUrls: ['./chat-bot.component.sass'],
    standalone: true,
    providers: [AssistantManagerService, SharedDataChatService],
    imports: [ReactiveFormsModule, CommonModule, FormsModule, MatProgressSpinnerModule, ChatPanelComponent, NgxSpinnerModule, SafeHtmlPipe, MatButtonModule, MatIconModule, MatTooltipModule, MatChipsModule, MatListModule, MatCheckboxModule, MatSnackBarModule, MatRadioModule, BoldMarkDownPipe, BriefPreSelectionComponent]
})


export class ChatBotComponent implements OnInit, OnDestroy{

  frontendServURL = environment.frontendServURL;
  spinnerTemplate = `<img src="${environment.frontendServURL}/assets/img/cc-logo.svg"/>`;
  @ViewChild('messageContainer') private messageContainer: ElementRef;
  @Input() nextStepsOpen: boolean = false;
  @Input() authToken: string;
  @Output() isPreviousBrief = new EventEmitter<boolean>();
  @Output() formDetailsUpdated: EventEmitter<AssistantResponseDetails> = new EventEmitter();
  @Output() preSelectionMade: EventEmitter<AssistantPreSelection> = new EventEmitter<AssistantPreSelection>();
  @Output() sessionIdUpdated: EventEmitter<string> = new EventEmitter<string>();
  @Output() loadingTextEvent: EventEmitter<string> = new EventEmitter<string>();
  platformId = inject(PLATFORM_ID);
  chatService = inject(AssistantManagerService);
  sharedDataService = inject(SharedDataChatService);
  isAssistantSelected: boolean = false;
  selectedAssistantId: string | null = null;
  selectedAssistantName: string = '';
  loadingText: string = '';
  isMessageProcessing: boolean = false;
  isFirstResponse: boolean = true;
  showScrollDownButton = false;
  chatMessageSubscription!: Subscription;
  chatMessages: ChatMessage[] = [];
  chat_newMessage: string = '';
  type: 'user' | 'assistant';
  content: string;
  selectedMessage: any;
  currentThreadId: string;
  currentSessionId: string | null = null;
  optionsForm: FormGroup;
  selectedOptions: string[] = [];
  sentOptions: Set<string> = new Set();
  responseTimeout: any = null;
  private suggestNewOptions: boolean = false;

  modalConfirmation: boolean = false;
  private awaitingTitlesResponse = false;
  isGeneratingResponse: boolean = false;
  hoveredOption: string | null = null;


  constructor(private titleService: Title, public spinnerHandler: SpinnerHandlerService, private sanitizer: DomSanitizer, private fb: FormBuilder, private snackBar: MatSnackBar, private dialog: MatDialog  ) {
    afterNextRender(() => {
    });
    this.optionsForm = this.fb.group({});
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.titleService.setTitle('CChatBot9000');
      //this.handleAssistantSelection('bb3');
     }

     console.log("ngOnInit");
     this.chatMessageSubscription = this.chatService.messages$.subscribe({
      next: (message: any) => {
        console.log("Chat Bot- Next Msg",message);
        if (message.status === "error") {
          console.log(' ngOnInit, Error message received:', message);
          this.handleErrorMessage(message);
        } else if (message.type === 'maxRetryError') {
          console.log("Chat Bot- maxRetryError",message);
          this.removeLoadingMessage();
          this.chatMessages.push({
            type: 'assistant',
            content: 'Sorry, an unexpected error occurred. Please send your response again.',
            timestamp: new Date(),
            isError: true,
            isLoading: false,
          });
          this.isGeneratingResponse = false;
          this.isMessageProcessing = false;
        } else {
          console.log("Chat Bot ELSE Msg",message);
          // normal message processing
          this.removeLoadingMessage();
    
          if (message.response && message.response.form) {
            console.log("ChatBot - message.response.form",message.response);
            this.formDetailsUpdated.emit(message.response);
          }

          // update session_id if not already set
          if (!this.currentSessionId && message.response.session_id) {
            this.currentSessionId = message.response.session_id;
            console.log("currentSessionId", this.currentSessionId);
            this.sessionIdUpdated.emit(this.currentSessionId); 
          }

          // Update thread_id if not already set
          if (!this.currentThreadId && message.response.thread_id) {
            this.currentThreadId = message.response.thread_id;
            console.log("currentThreadId", this.currentThreadId);
          }
    
          if (this.awaitingTitlesResponse) {
            this.awaitingTitlesResponse = false; // reset the flag
            return; 
          }
    
          this.chatMessages.push({
            type: 'assistant',
            content: message.response.ai_text_response,
            timestamp: new Date(),
            assistantResponse: message,
            isLoading: false,
          });
    
          this.isGeneratingResponse = false;

          console.log("this.isAssistantSelected && this.isFirstResponse",this.isFirstResponse,this.isAssistantSelected)
          
          if (this.isAssistantSelected && this.isFirstResponse) {
            this.spinnerHandler.handleRequest('minus');
            this.isFirstResponse = false;  // ensures that the spinner is only stopped on the first response
          }
          // re-scroll to bottom if needed
          // setTimeout(() => this.scrollToBottom(), 0);
        }
      },
      error: (error) => {
        console.log('Subscription error:', error);
      }
    });
    

    this.chatService.currentThreadId$.subscribe(threadId => {
      this.currentThreadId = threadId; // store the threadId in a component variable
      console.log("currentThreadId",this.currentThreadId);
    });
   }

   ngAfterViewInit() {
    this.chatService.scrollToBottomRequested.subscribe(() => {
      this.scrollToBottom();
    });

    this.messageContainer.nativeElement.addEventListener('scroll', () => {
      // checks if it is close to the end of the chat with a tolerance of a few pixels
      const tolerance = 10;
      const atBottom = this.messageContainer.nativeElement.scrollHeight - this.messageContainer.nativeElement.scrollTop - this.messageContainer.nativeElement.clientHeight < tolerance;

      this.showScrollDownButton = !atBottom;
    });
  }

  ngOnDestroy(): void {
    this.chatMessageSubscription?.unsubscribe();
  }   

  private handleErrorMessage(message: any): void {
    console.log('handleErrorMessage, Error message received:', message);
    if (message.response && Array.isArray(message.response)) {
      if (message.response.includes("cannot access local variable 'run' where it is not associated with a value") ||
          message.response.includes("local variable 'run' referenced before assignment")) {
        console.log('Resending last message due to specific error');
        this.chatService.resendLastMessage();
      } else if (message.response.includes('An unexpected error occurred, please submit your answer again')) {
        console.log('Else if Error Retry Msg:', message.response);
        this.removeLoadingMessage();
        this.chatMessages.push({
          type: 'assistant',
          content: 'An unexpected error occurred, please try again.',
          timestamp: new Date(),
          isError: true,
          isLoading: false,
        });
        this.isGeneratingResponse = false;
        this.isMessageProcessing = false;
      }
    } else {
      console.log('Error does not match known error types:', message);
    }
  }
  

  chat_sendMessage(sender: 'user' | 'assistant', content: string): void {
    if (content.trim()) {
      // add the user's message normally, without isLoading
      if (sender === 'user') {
        this.addUserMessage(content);
        // send the message to the service
        this.chatService.sendMessage(content);
        // add a "loading" message to wait for the response
        this.addLoadingMessage();
        // comment for know, scroll to botoom
        // setTimeout(() => this.scrollToBottom(), 0);
      }
    }
  }
  
  // function to add user messages
  addUserMessage(content: string): void {
    const newMessage: ChatMessage = {
      type: 'user',
      content: content,
      timestamp: new Date(),
      isLoading: false,
    };
    this.isGeneratingResponse = false;
    this.chatMessages.push(newMessage);
    this.chat_newMessage = ''; // clear input field after submission
    //setTimeout(() => this.scrollToBottom(), 0);
  }

  // function to add a "loading" message
  addLoadingMessage(): void {
    const loadingMessage: ChatMessage = {
      type: 'assistant',
      content: '',
      timestamp: new Date(),
      isLoading: true,
    };
    this.isGeneratingResponse = true;
    this.chatMessages.push(loadingMessage);
  }

  handleGeneratingResponseTimeout(): void {
    this.removeLoadingMessage(); 
    this.isGeneratingResponse = false;
    this.spinnerHandler.handleRequest('minus');
  }
  

  // removes the loading message
  removeLoadingMessage(): void {
    const lastLoadingIndex = this.chatMessages.findIndex(msg => msg.isLoading);
    if (lastLoadingIndex !== -1) {
      this.chatMessages.splice(lastLoadingIndex, 1); 
    }
  }

  handleOptionClick(message: ChatMessage, option: string): void {
    if (!this.isGeneratingResponse) {
      message.selectedOption = option;
      this.sendSelectedSingleOption(message, option);
    }
  }
  
  sendSelectedSingleOption(message: ChatMessage, selectedOption: string): void {
    if (selectedOption) {
      this.chat_sendMessage('user', selectedOption);
    }
  }
  confirmOption(option: string, message: ChatMessage): void {
    this.sendSelectedSingleOption(message, option);
  }
  

  //  quick  actions
  handleQuickAction(action: string, message: ChatMessage, modalConfirmation: boolean = false): void {
    if (modalConfirmation) {
      Swal.fire({
        title: 'Confirm Selection?',
        html: `You have selected: <strong>"${action}"</strong>`,
        showCancelButton: true,
        confirmButtonColor: '#0067C4',
        cancelButtonColor: '#f44336',
        confirmButtonText: 'Yes, send it!',
        cancelButtonText: 'Cancel',
        reverseButtons: true, // reverse buttons to have confirm on the right
        customClass: {
          title: 'swal2-title-custom',
          htmlContainer: 'swal2-html-custom'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.chat_sendMessage('user', action);
          message.selectedOption = action;
          this.chatMessages = [...this.chatMessages];
        }
      });
    } else {
      this.chat_sendMessage('user', action);
      message.selectedOption = action;
      this.chatMessages = [...this.chatMessages];
    }
  }
  

  selectOption(option: string, message: ChatMessage): void {
    message.assistantResponse.response.options = [];
  }
  
  // assistants handler
  handleAssistantSelection(assistantKey: string): void {
    console.log("handleAssistantSelection",assistantKey);
    const dialogRef = this.dialog.open(AssistantInfoModalComponent, {
      width: '500px',
    });
  
    document.body.classList.add('blur-effect');
  
    dialogRef.afterClosed().subscribe(() => {
      document.body.classList.remove('blur-effect');
    });
  

    const assistantMap: { [key: string]: string } = {
      'bb3000': 'asst_bjtES57vUZs4WDHEsMgszmJE',
      'bb3': 'asst_pTAIkoaqyDdMNsjcpsfCfest',
      'gpt4': 'asst_aUdMJ64cn4vCEQN7NxuUaXdW',
      'echo_maestro': 'asst_eqztmzMKdCYFmLLYFJeluZfd'
    };

    const assistantNames: { [key: string]: string } = {
      'bb3000': 'BB 3000',
      'bb3': 'Brief Creator',
      'gpt4': 'NarcilioTest',
      'echo_maestro': 'CC EchoMaestro'
    };

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      this.chatService.closeConnection();
      this.chatMessages = [];
      this.selectedAssistantId = assistantMap[assistantKey];
      this.selectedAssistantName = assistantNames[assistantKey];
      this.isAssistantSelected = true;
      // emit event with the pre selected data from assistant
      this.preSelectionMade.emit(result);
      // show spinner start chat
      console.log( 'Init Spinner Start Chat');
      this.showLoadingSpinner(`Initiating connection with the ${this.selectedAssistantName} assistant`);
      this.chatService.updateWebSocketUrl(assistantMap[assistantKey]);
      this.initiateConnectionWithAssistant(result);
    }
  });
  }

  showLoadingSpinner(loadingText: string) {
    // this.spinnerHandler.handleRequest('plus');
    // emit event with loading text (wcassistant)
    this.loadingTextEvent.emit(loadingText);
  }

  initiateConnectionWithAssistant(preSelectionData: AssistantPreSelection): void {
    
    if (this.selectedAssistantId && isPlatformBrowser(this.platformId)) {
      this.chatService.closeConnection();
      this.chatService.connect();
   
  
      const checkConnection = setInterval(() => {
        if (this.chatService.isConnected()) {
          clearInterval(checkConnection);
          // building the message based on existing values
          let message = 'Start the content brief process';
          if (preSelectionData.brand) {
            message += `, my brand is: ${preSelectionData.brand}`;
          }
          if (preSelectionData.publishUrl) {
            message += `, my publish URL is: ${preSelectionData.publishUrl}`;
          }
          if (preSelectionData.contentType) {
            message += `, my content type is: ${preSelectionData.contentType}`;
          }
          if (preSelectionData.vertical) {
            message += `, my vertical is: ${preSelectionData.vertical}`;
          }
          this.chatService.sendMessage(message);
        }
      }, 100); // checks every 100ms
    }
  }
  

  goBackToAssistantSelection(): void {
    this.isAssistantSelected = false;
    this.chatService.closeConnection();
    this.chatMessages = [];
  }

  // handleTimeout(): void {
  //   this.removeLoadingMessage(); // removes loading message
  //   this.chatMessages.push({
  //     type: 'error',
  //     content: 'The request timed out. Please try again.',
  //     timestamp: new Date(),
  //     isError: true
  //   });
  //   // stop global spinner 
  //   this.spinnerHandler.handleRequest('minus');
  // }

  scrollToBottom(): void {
    this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
    this.showScrollDownButton = false;
  }

  handleError(error: any): void {
  
    console.error('An error occurred:', error);
    
    //display error mensagge on chat
    this.chatMessages.push({
      type: 'error',
      content: 'An error occurred while processing your request. Please try again later.',
      timestamp: new Date(),
      isError: true,
    });
  
    // reset isLoading state, if applicable
    this.isMessageProcessing = false;
  }

  sendGenerateOutlineMessage() {
    // checks if the WebSocket connection is open
    if (this.chatService.isConnected()) {
      // call the function to send the message as user and display the upload
      this.chat_sendMessage('user', "generate a new outline");
  
      // observe for responses
      const subscription = this.chatService.messages$.subscribe({
        next: (message: any) => {
          if (message.response && message.response.ai_text_response) {
            // removes the loading message when you receive the expected response
            this.removeLoadingMessage();
            subscription.unsubscribe(); 
          }
        },
        error: () => {
          // remove loading message in case of error
          this.removeLoadingMessage();
        }
      });
    } else {
      console.error("WebSocket not connected.");
    }
  }  

  toggleOptionSelection(option: string): void {
    const index = this.selectedOptions.indexOf(option);
  
    if (index > -1) {
      this.selectedOptions.splice(index, 1);
    } else {
      this.selectedOptions.push(option);
    }
  }
  
  sendSelectedOptions(message: ChatMessage): void {
    const newOptions = this.selectedOptions.filter(option => !this.sentOptions.has(option));
    const selectedOptionsString = newOptions.join(', ');
  
    if (newOptions.length > 0) {
      this.chat_sendMessage('user', selectedOptionsString);
      newOptions.forEach(option => this.sentOptions.add(option));
      this.chatMessages = [...this.chatMessages];
    }
  }

  formatCamelCaseToSpacedLower(text) {
    return text.replace(/([A-Z])/g, ' $1').toLowerCase().trim();
  }


requestUpdate(update: SuggestionUpdate): void {
  const updateConfig = Constants.auxiliary_texts.updateSuggestions[update.type];
  let message;

  // decide which message to send based on the type of update
  if (update.updateType === "refresh_options") {
    message = updateConfig.defaultValue;
    this.suggestNewOptions = true; 
  } else if (update.updateType === "new_option" && update.value) {
    message = updateConfig.messageTemplate.replace('{}', update.value);
    this.suggestNewOptions = false; 
  } else {
    alert("Sorry, an unexpected error occurred, please try again later"); 
    this.suggestNewOptions = false;
  }

  //this.loadingText = updateConfig.loadingText;
  this.showLoadingSpinner(updateConfig.loadingText);
  this.chatService.sendMessage(message);

  const subscription = this.chatService.messages$.subscribe({
    next: (response: any) => {
      if (response.passed && response.status === 'completed') {
        if (this.suggestNewOptions) {
          // display the snackbar only if the last request used the defaultValue
          this.showSnackbar(`New ${this.formatCamelCaseToSpacedLower(update.type)} options generated`);
        }
        this.chatService.addOrUpdateTask({ ...update, status: 'done' });
        subscription.unsubscribe();
      }
    },
    error: () => {
      //this.spinnerHandler.handleRequest('minus');
      //this.showSnackbar("Failed to update");
    }
  });
}


  showSnackbar(message: string, action: string = 'OK', duration: number = 3000, className: string = '') {
    this.snackBar.open(message, 'Close', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: 'form-snackbar'
    });
  }

  startResponseGeneration(): void {
    this.isGeneratingResponse = true;
  }

  stopResponseGeneration(): void {
    this.chatService.sendMessage('{stop}');
    this.isGeneratingResponse = false;
    this.removeLoadingMessage();
  }

  copyTextOld(event: MouseEvent, text: string): void {
    event.stopPropagation(); // prevent the click event from propagating

    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
        .then(() => {
          // handling when the text is copied successfully
          this.showSnackbar("Text copied successfully", 'OK', 3000, 'success-snackbar');
        })
        .catch(err => {
          // handling when an error occurs when trying to copy
          this.showSnackbar("Unable to copy text"); 
        });
    } else {
      console.error('The copy to clipboard function is not supported in this browser');
    }
  }  

  copyText(event: MouseEvent, text: string): void {
    event.stopPropagation(); // Prevent the click event from propagating
    this.sharedDataService.copyText(text)
      .then(() => {
        // handle success
        this.showSnackbar("Text copied successfully", 'OK', 3000, 'success-snackbar');
      })
      .catch(err => {
        // Handle error
        this.showSnackbar("Unable to copy text"); 
      });
  }

  addAssistantMessage(content: string): void {
    this.chatMessages.push({
      type: 'assistant',
      content: content,
      timestamp: new Date(),
      isLoading: false,
    });
  
    setTimeout(() => this.scrollToBottom(), 0);
  }
  // load chat history

  loadChatHistoryData(historyData: any[], loadForm: boolean, sessionId?: string): void {
    console.log("CHAT BOT SESSION_ID",sessionId);
    this.chatMessages = [];
    let lastBotFormDetails: AssistantResponseDetails | null = null; // store last form "bot" data
    let firstUserMessage = true; 

    historyData.forEach(item => {
      if (item.type === 'user' && firstUserMessage) {
        firstUserMessage = false; // disables the flag after finding the first message of type 'user'
        return; // skip adding this message to the chatMessages array
      }
      const responseDetails: AssistantResponseDetails = {
        ai_text_response: item.message.ai_text_response,
        options: item.message.options || [],
        quick_actions: item.message.quick_actions || [],
        multiple_options: item.message.multiple_options || false,
        current_step: item.message.current_step,
        form: item.message.form,
        thread_id: item.message.thread_id,
        session_id: item.message.session_id,
        user_info: item.message.user_info,
        scoring: item.message.scoring,
        suggestions: item.message.suggestions,
        unused_options: item.message.unused_options
      };

      if (item.type === 'bot') {
        lastBotFormDetails = responseDetails; // updates whenever a new msg bot is processed
      }

      const assistantResponse: AssistantResponse = {
        passed: true,
        status: "completed",
        description: "",
        response: responseDetails
      };

      const chatMessage: ChatMessage = {
        type: item.type === 'user' ? 'user' : 'assistant',
        content: item.type === 'user' ? item.message : item.message.ai_text_response,
        timestamp: new Date(item.sent_at),
        isLoading: false,
        assistantResponse: assistantResponse
      };

      console.log("chatMessage",chatMessage)

      this.chatMessages.push(chatMessage);
    });

    // after finishing the loop, check if there are bot form details to issue
    if (lastBotFormDetails) {
      // select assistant and emit previous brief
      this.isPreviousBrief.emit(true);
      this.isAssistantSelected = true;
      this.chatService.closeConnection();
      // this.scrollToBottom();
      this.chatService.connectWithInitialMessage("asst_iensNJZGX7rKfIaQIO2xfHkr", lastBotFormDetails, sessionId);

      // if loadForm is also true, output form details
      if (loadForm == true) {
        this.formDetailsUpdated.emit(lastBotFormDetails);
      }
    }
  }
}