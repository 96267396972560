<h2 class="mat-dialog-title h2" mat-dialog-title>Before starting, please provide the following information
    <!--button mat-dialog-close class="close-button">
      <mat-icon>close</mat-icon>
    </button-->
</h2>
  
<mat-dialog-content class="mat-typography11">
    <form>
        <mat-form-field appearance="fill">
          <mat-label>Brand</mat-label>
          <input matInput [(ngModel)]="data.brand" name="brand">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Publish URL</mat-label>
          <input matInput [(ngModel)]="data.publishUrl" name="publishUrl">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Content Type</mat-label>
          <input matInput [(ngModel)]="data.contentType" name="contentType">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Vertical</mat-label>
            <input matInput [(ngModel)]="data.vertical" name="vertical">
          </mat-form-field>         
      </form>            
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="data" class="confirm-button">Send</button>
</mat-dialog-actions>