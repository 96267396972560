import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boldMarkDown',
  standalone: true
})
export class BoldMarkDownPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return value;

    // remove numbered lists
    value = value.replace(/\n\d+\.[^\n]+(?=\n\d+\.|\n\n|$)/g, '');

    // apply Markdown formatting
    let transformedValue = value
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') 
      .replace(/\*(.*?)\*/g, '<em>$1</em>') 
      .replace(/\n/g, '<br>');
    return transformedValue;
  }
}
