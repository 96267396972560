export const environment = {
  production: true,
  frontendURL: "https://client.crowdcontent.com",
  frontendServURL: "https://tools-ui.crowdcontent.com",
  apiGateway: "https://services.crowdcontent.com/api",
  webGateway: "https://services.crowdcontent.com",
  inApiGateway: "https://adm-services.crowdcontent.com/api/ext_pro",
  parentIframeURL: 'https://app.crowdcontent.com/',
  loginURL: 'http://signin.crowdcontent.com',
  tinyKey: "uhcw845mhzk56pkb4owmvdpinye6higk9g3vutsuo3t0iy6p",
  guardActive: true,
  aiToolsService: "https://ai-tools-service.crowdcontent.com",
  spinnerIntercepActive: true,
};
