<div class="d-flex flex-column vh-100 dashboard-container brief-wrapper">  

  <ngx-spinner bdColor="rgba(0, 0, 0, 0.7)" size="large" color="#0088ff" type="ball-atom" [fullScreen]="true"
  [template]="spinnerTemplate">
  <div class="spinner-container-assistant"><input type="text" class="input-field mt-2" [value]="loadingText" disabled />
  </div>
</ngx-spinner>
 
    <!-- Pre selection Briefs-->
    <div *ngIf="showPreSelectionsBrief && !nextStepsOpen"> 
     <!-- <app-chat-panel *ngIf="!nextStepsOpen" (chatHistoryLoaded)="onChatHistoryLoaded($event)"></app-chat-panel> --> 
      <app-brief-pre-selection [showAdditionalContent]="displayAdditionalContent" 
        (assistantChosen)="handleAssistantChosen($event)" 
        (sessionIdLoaded)="handleSessionIdLoaded($event)"
        (chatHistoryLoaded)="onChatHistoryLoaded($event)"
        (scoringFeedbacksLoaded)="onScoringFeedbacksLoaded($event)"
        (contentOutlineLoaded)="onContentOutlineLoaded($event)"
        (returnToDashboard)="handleReturnClick()">
      </app-brief-pre-selection>
    </div>

    <!-- Main Content -->
    <main [ngClass]="nextStepsOpen ? 'main-content' : 'main-content hide-overflow'">
      <div class="container-fluid p-0">
        <div class="row g-0">
          <div [ngClass]="{
            'col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 visible-component': nextStepsOpen,
            ' hidden-component': displayAdditionalContent && !nextStepsOpen,
            ' visible-component': !nextStepsOpen && !displayAdditionalContent
          }">
            <button mat-icon-button matTooltip="Return Brief" *ngIf="nextStepsOpen" class="return-button" (click)="handleReturnClick()">
              <mat-icon>arrow_back</mat-icon>
            </button> 
            <!-- <button *ngIf="nextStepsOpen" mat-icon-button matTooltip="Return" (click)="handleReturnClick()">
              <mat-icon class="panel-icons">undo</mat-icon> 
            </button>            -->
            <app-chat-bot (loadingTextEvent)="handleLoadingText($event)" [nextStepsOpen]="nextStepsOpen" (isPreviousBrief)="handleIsPreviousBrief($event)"  (formDetailsUpdated)="handleAssistantResponse($event)" (preSelectionMade)="handlePreSelectionAssistant($event)" (sessionIdUpdated)="handleSessionIdUpdated($event)"></app-chat-bot>
          </div>
  
          <mat-drawer-container [@.disabled]="animDisabled"  class="col-xl-6 col-lg-7 col-md-6 col-sm-6 col-xs-6" [ngClass]="nextStepsOpen ? 'visible-component' : 'hidden-component'" autosize>
            <mat-drawer-content [ngClass]="{'first-drawer': isSuggestionsPanelOpen, 'first': !isSuggestionsPanelOpen}">
              <!--<div  *ngIf="isSuggestionsPanelOpen && currentSelectedSectionId" class="third-drawer">
                <div class="icon-hide-suggestions" [matTooltip]="'Hide suggestions'"
                    matTooltipPosition="above"
                    (click)="hideSuggestionsPanel(currentSelectedSectionId)">
                      <mat-icon class="icon-suggestions-open">{{'chevron_left' }}</mat-icon>
                  </div>
              </div>  -->

              <div class="overflow-auto">
                <div  *ngIf="isSuggestionsPanelOpen && currentSelectedSectionId">
                  <div class="tag-icon"  [matTooltip]="'Hide suggestions'"
                  matTooltipPosition="above"
                  (click)="hideSuggestionsPanel(currentSelectedSectionId)">
                  <mat-icon class="icon-suggestions-open">{{'chevron_right' }}</mat-icon>
                  </div>
                </div>  
                <app-brief-creator (generateOutlineRequested)="sendGenerateOutlineMessage()"
                                   (titleUpdated)="onTitleUpdated($event)"
                                   (sectionSelected)="handleSectionSelected($event)"
                                   (toggleSuggestionsPanel)="toggleSuggestionsPanel()"
                                   [isPanelOpen]="isSuggestionsPanelOpen"
                                   [selectedOutlineId]="selectedSectionIdFromOutline"
                                   [versionData]="versionControlData"
                                   [sessionId]="chatSectionId" 
                                   ></app-brief-creator>
              </div>
              
             
            </mat-drawer-content>
            <mat-drawer [hidden]="!isSuggestionsPanelOpen" #suggestionsDrawer class="second-drawer ms-2" mode="side" position="end">
              <app-suggestions-panel [title]="titleForSuggestions"
                                    [currentStepSuggestions]="currentStepSuggestions" 
                                    [currentStep]="currentStep" 
                                    [currentSelectedSectionId]="currentSelectedSectionId"
                                    [isPanelOpen]="isSuggestionsPanelOpen"
                                    (updateRequested)="onUpdateRequested($event)">
              </app-suggestions-panel>
            </mat-drawer>
          </mat-drawer-container>


          <div [ngClass]="{'border-padding-class': !isSuggestionsPanelOpen && currentSelectedSectionId, 'visible-component': nextStepsOpen, 'hidden-component': !nextStepsOpen}"
              class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-xs-3">
              <div *ngIf="!isSuggestionsPanelOpen && currentSelectedSectionId" class="icon-container third-drawer" [matTooltip]="'Open suggestions'" matTooltipPosition="above" (click)="openAlternativesPanel(currentSelectedSectionId)">
                <mat-icon class="icon-suggestions-open">chevron_left</mat-icon>
              </div>
            <app-outline (OutlineSelected)="handleOutlineSelection($event)"
                        (refreshRequested)="onRefreshScoringRequested()"
                        [loadingSuggestions]="isLoadingSuggestions">
            </app-outline>
          </div>

  
        </div>
      </div>
    </main>
  </div>