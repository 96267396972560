<div class="container main-container mt-2 mb-5"
  [ngClass]="{'first-container-class': nextStepsOpen, 'second-container-class': !nextStepsOpen}">
  <div class="justify-content-md-center">
    <!-- chat Bot Component -->
    <div class="card chat-window mb-4">
      <div class="card-body" #messageContainer>
        <!-- <div *ngIf="isAssistantSelected"> -->
        <ng-container *ngFor="let message of chatMessages; let i = index">
          <div *ngIf="message.content !== 'undefined'" class="message-block" [ngClass]="{'first-message': i === 0 && nextStepsOpen}">
            <div class="d-flex align-items-start">
              <!-- Avatar -->
              <img class="avatar"
                [src]="frontendServURL + '/assets/img/' + (message.type === 'assistant' ? 'AI-assist.svg' : 'person.svg')" />
              <!-- Message card -->
              <div class="card message-card"
                [ngClass]="{'bot-message': message.type === 'assistant', 'user-message': message.type === 'user'}">
                <div class="ms-2 mt-1">
                  <ng-container *ngIf="!message.isLoading; else loadingOrErrorContent">
                    <div>
                      <span class="lh-2" [innerHTML]="message.content | boldMarkDown | safeHtml"></span>
                    </div>
                  </ng-container>
                  <ng-template #loadingOrErrorContent>
                    <div *ngIf="message.isError; else loadingSpinner">{{ message.content }}</div>
                    <ng-template #loadingSpinner>
                      <div class="spinner-container mt-2">
                        <mat-progress-spinner mode="indeterminate" [diameter]="30" class="me-2"></mat-progress-spinner>
                        <span>Generating response...</span>
                      </div>
                    </ng-template>
                  </ng-template>
                  <!-- options responses-->
                  <div *ngIf="message.assistantResponse?.response?.options?.length > 0" class="mt-4 mb-4">
                    <ng-container *ngIf="message.assistantResponse?.response?.multiple_options; else singleChoice">
                      <div class="chat-option-widget">
                        <div
                          *ngFor="let option of message.assistantResponse.response.options; let i = index; let last = last"
                          class="chat-option-list d-flex align-items-center justify-content-between py-1"
                          [class.option-selected-background]="selectedOptions.includes(option)"
                          (click)="!isGeneratingResponse && toggleOptionSelection(option)">
                          <span class="flex-grow-1">{{ option }}</span>
                          <div class="options-icon-container ms-2" (click)="copyText($event, option)">
                            <!--img *ngIf="isOptionSelected(option)" src="assets/img/icon-copy.svg" matTooltip="copy" matTooltipPosition="right" alt="copy Icon"-->
                            <img class="show-on-hover" [src]="frontendServURL + '/assets/img/icon-copy.svg'"
                              matTooltip="Copy" matTooltipPosition="above" alt="copy Icon">
                          </div>
                        </div>
                      </div>
                      <!-- commenting button send for now -->
                      <div class="send-button-container mt-4">
                        <button mat-raised-button class="send-options-button" (click)="sendSelectedOptions(message)">
                          <mat-icon class="m-0">send</mat-icon><span class="hidden-wordings-chat ms-1">Send selected
                            options</span>
                        </button>
                      </div>
                    </ng-container>


                    <ng-template #singleChoice>
                      <div class="chat-option-widget">
                        <div *ngFor="let option of message.assistantResponse.response.options; let i = index"
                          class="chat-option-list d-flex align-items-center justify-content-between py-1"
                          (click)="message.selectedOption = option"
                          [class.option-selected-background]="option === message.selectedOption"
                          (click)="!isGeneratingResponse && (message.selectedOption = option)">
                          <span class="flex-grow-1"
                            (click)="!isGeneratingResponse && sendSelectedSingleOption(message, option)">{{ option
                            }}</span>
                          <div class="options-icon-container ms-2" (click)="copyText($event, option)">
                            <!--img *ngIf="option === message.selectedOption" src="assets/img/icon-copy.svg" matTooltip="copy" matTooltipPosition="right" alt="copy Icon"-->
                            <img class="show-on-hover" [src]="frontendServURL + '/assets/img/icon-copy.svg'"
                              matTooltip="Copy" matTooltipPosition="above" alt="copy Icon">
                          </div>


                        </div>
                      </div>
                    </ng-template>
                  </div>

                  <!-- quick actions -->
                  <mat-chip-listbox *ngIf="message.assistantResponse?.response?.quick_actions?.length > 0"
                    aria-label="Quick actions" class="mt-2 overflow-hidden">
                    <mat-chip-option *ngFor="let action of message.assistantResponse.response.quick_actions"
                      (click)="handleQuickAction(action, message)">
                      {{ action}}
                    </mat-chip-option>
                  </mat-chip-listbox>
                  <!-- message footer --><!--Julia: let's hide for now-->
                  <!--div class="message-footer mt-2">
                  {{ message.type === 'assistant' ? 'CChatBot9000' : 'User' }} {{ message.timestamp | date: 'shortTime'
                  }}
                </div-->
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div *ngIf="showScrollDownButton" class="scroll-down-button" (click)="scrollToBottom()">
          <mat-icon>arrow_downward</mat-icon>
        </div>
      </div>

      <div *ngIf="isAssistantSelected" class="chat-input-container mb-4">
        <div class="input-group chat-input-group">
          <input type="text" class="form-control chat-input" [(ngModel)]="chat_newMessage"
            (keyup.enter)="!isGeneratingResponse && chat_sendMessage('user', chat_newMessage)"
            placeholder="Ask or search anything">
          <button class="btn btn-icon send-confirm-user-msg"
            [matTooltip]="isGeneratingResponse ? 'Stop response' : 'Send message'"
            (click)="isGeneratingResponse ? stopResponseGeneration() : chat_sendMessage('user', chat_newMessage)">
            <mat-icon class="send-icon-confirm-msg ">{{isGeneratingResponse ? 'stop' : 'send'}}</mat-icon>
          </button>
          <mat-divider></mat-divider>
        </div>
      </div>
    </div>
  </div>
</div>