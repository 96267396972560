import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent,} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { AssistantPreSelection } from '../chat-bot/models/assistant-pre-selection-model';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-assistant-info-modal',
  standalone: true,
  imports: [
    CommonModule, 
    FormsModule, 
    MatDialogContent,
    MatDialogTitle,
    MatDialogClose, 
    MatDialogActions,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule, 
    MatButtonModule  
  ],
  templateUrl: './assistant-info-modal.component.html',
  styleUrl: './assistant-info-modal.component.sass'
})
export class AssistantInfoModalComponent {
  data: AssistantPreSelection = new AssistantPreSelection(); 

}
